import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Context as AuthContext } from 'contexts/AuthContext';
import { ScheduleItemResponse, ScheduleListResponse } from 'dto/ScheduleDto';
import { ArtistService } from 'services/ArtistService';
import DateUtils from 'utils/DateUtils';
import { SCHEDULE_TYPE, SCHEDULE_TYPE_LABELS } from 'constants/ScheduleType';
import HeaderNavBar from 'components/common/HeaderNavBar';
import MoreView from 'components/common/MoreView';
import Colors from 'styles/color-system';

type ScheduleConvertList = {
  [key: string]: {
    [key: string]: string | number | boolean;
  }[];
};

interface StyledProps {
  lastChild: boolean;
}

const LIST_PAGE_LIMIT = 10;
function ScheduleListPage() {
  const {
    state: { artist },
  } = useContext(AuthContext);

  const [scheduleList, setScheduleList] = useState<ScheduleItemResponse[]>([]);

  const [totalCount, setTotalCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const today = new Date().getTime() / 1000;

  useEffect(() => {
    ArtistService.scheduleList(artist, currentPage, LIST_PAGE_LIMIT).then(
      (response) => {
        const result = response.data as ScheduleListResponse;
        setScheduleList([...scheduleList, ...result.items]);
        setTotalCount(result.total);
      },
    );
  }, [currentPage]);

  const moreData = () => {
    const totalPage = Math.ceil(totalCount / LIST_PAGE_LIMIT);
    if (currentPage === totalPage) {
      return;
    }

    setCurrentPage(currentPage + 1);
  };

  const convertDate = (date: number) => {
    return DateUtils.convertDateToMMDDE(DateUtils.convertTimeToDate(date));
  };

  const convertSchedule = () => {
    const newData = [...scheduleList];
    newData.sort((a, b) => a.time - b.time);
    const convertData = newData.reduce((list: ScheduleConvertList, current) => {
      list[convertDate(current.time)] = list[convertDate(current.time)] || [];
      list[convertDate(current.time)].push({
        id: current.id,
        type: current.type,
        title: current.title,
        all_day: current.all_day,
        time: current.time,
      });
      return list;
    }, {});

    return convertData;
  };

  return (
    <Container>
      <HeaderNavBar leftItem="LOGO" label="스케줄" rightItem="CART" />
      <ContentBody>
        {Object.keys(convertSchedule()).map((key) => (
          <List key={key}>
            <DateLabel>
              {key}
              {key === convertDate(today) && <ToDay>&nbsp;오늘</ToDay>}
            </DateLabel>
            {convertSchedule()[key].map((item, idx) => (
              <Item
                key={item.id as number}
                lastChild={convertSchedule()[key].length === idx + 1}
              >
                <TopSection>
                  <Tag>{SCHEDULE_TYPE_LABELS[item.type as SCHEDULE_TYPE]}</Tag>
                  <Time>
                    {item.all_day
                      ? '하루 종일'
                      : DateUtils.convertDateToaaHHmm(
                          DateUtils.convertTimeToDate(item.time as number),
                        )}
                  </Time>
                </TopSection>
                <Title>{item.title}</Title>
              </Item>
            ))}
          </List>
        ))}
        <MoreView onClick={moreData} show={totalCount > scheduleList.length} />
      </ContentBody>
    </Container>
  );
}

export default ScheduleListPage;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  background: ${Colors.Background[500]};
`;

const ContentBody = styled.div`
  flex: 1;
  overflow: hidden auto;
`;

const List = styled.ul`
  margin-top: 35px;
  padding-inline: 24px;
  box-sizing: border-box;
`;

const DateLabel = styled.span`
  display: block;
  color: ${Colors.Gray[200]};
  font-size: 18px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.02em;
  margin-bottom: 13px;
`;

const ToDay = styled.span`
  color: ${Colors.Primary[500]};
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.02em;
`;

const Item = styled.li<StyledProps>`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  padding-inline: 22px;
  padding-block: 22px;
  box-sizing: border-box;
  border-radius: 20px;
  margin-bottom: ${({ lastChild }) => !lastChild && '5px;'};
  background: ${Colors.Gray[700]};
`;

const TopSection = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const Tag = styled.span`
  white-space: nowrap;
  padding-inline: 6px;
  padding-block: 3px;
  background: ${Colors.Primary[500]};
  border-radius: 13px;
  box-sizing: border-box;
  color: ${Colors.White[500]};
  font-size: 12px;
  font-weight: 700;
  line-height: normal;
`;

const Title = styled.span`
  width: 100%;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${Colors.Gray[100]};
  font-size: 20px;
  font-weight: 500;
  line-height: normal;
`;

const Time = styled.span`
  color: #aaaaad;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
`;
