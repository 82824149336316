import companyInfo from './company-info';

export const productInfo = [
  {
    title: '1. 품명 및 모델명상품상세참조',
    content: ['상품상세설명 참조'],
  },
  {
    title:
      '2. 법에 의한 인증·허가 등을 받았음을 확인할 수 있는 경우 그에 대한 사항상세정보',
    content: ['해당사항 없음'],
  },
  {
    title: '3. 제조국 또는 원산지상세정보',
    content: ['원산지 국산'],
  },
  {
    title: '4. 제조자, 수입품의 경우 수입자를 함께 표기상품상세참조',
    content: [companyInfo.COMPANY],
  },
  {
    title: '5. A/S 책임자와 전화번호 또는 소비자상담 관련 전화번호',
    content: [`A/S 전화번호 ${companyInfo.CS_TEL}`],
  },
];

export const transactionInfo = [
  {
    title: '상품 등의 배송방법에 관한 정보',
    content: [
      '배송상품(택배), 배송비용(상품기재)',
      '상품 설명에 기재된 내용 참조',
    ],
  },
  {
    title: '제품하자가 아닌 소비자의 단순 변심에 따른 청약철회 시',
    content: [
      '소비자가 부담하는 반품비용 등에 관한 정보',
      '취소/환불 정보 참조',
    ],
  },
  {
    title:
      '제품하자가 아닌 소비자의 단순 변심에 따른 구매계약 철회가 불가능한 경우 그 구체적 사유와 근거',
    content: [
      '전자상거래 등에서의 소비자보호에 관한 법률 등에 의한 청약철회 제한 사유에 해당하는 경우 및 기타 객관적으로 이에 준하는 것으로 인정되는 경우 구매계약 철회가 제한될 수 있음',
    ],
  },
  {
    title: '상품 등의 교환·반품·보증 조건 및 품질보증기준',
    content: ['소비자분쟁해결기준(공정거래위원회 고시) 및 관계법령에 따름'],
  },
  {
    title:
      '대금 환불을 위한 방법과 환불이 지연될 경우 지연배상금을 지급받을 수 있다는 지연배상금 지급의 조건·절차',
    content: [
      '주문취소 및 대금의 환불은 고객센터를 통해 신청할 수 있으며, 소비자의 구매계약 철회 후 판매자가 재화 등을 반환 받은 대금의 환급을 정당한 사유 없이 지연하는 때에는 구매자는 지연기간에 대해서 지연배상금 청구 가능함',
      '전자상거래 등에서의 소비자보호에 관한 법률에 규정에 따름',
    ],
  },
  {
    title:
      '소비자피해보상의 처리, 상품 등에 대한 불만 처리 및 소비자와 사업자 사이의 분쟁처리에 관한 사항',
    content: [
      '소비자분쟁해결기준(공정거래위원회 고시) 및 관계법령에 따름',
      '고객센터 문의를 통해 확인 가능',
    ],
  },
  {
    title: '상품 등의 A/S 관련 전화번호',
    content: [`고객센터 메뉴를 통해 확인 가능`],
  },
  {
    title: '거래 약관의 내용 또는 확인 방법',
    content: ['이용약관 링크를 통해 확인 가능'],
  },
];

export const refundInfo = [
  {
    title: '취소/환불 가능 기간',
    content: [
      '환불 요청은 배송 완료 시점으로부터 7일 이내에 가능합니다. \n(단, 수령한 상품이 표기 광고의 내용과 다르거나 계약 내용과 다르게 이행된 경우에는 수령한 날로부터 1개월 이내에는 환불 신청이 가능합니다.)',
    ],
  },
  {
    title: '취소/환불 방법',
    content: [
      '고객센터에서 연락을 통한 환불 및 거래 취소의 처리 요청 \n주문 취소를 통한 거래 취소의 처리 요청',
    ],
  },
  {
    title: '취소/환불 안내',
    content: [
      '환불에 관한 일반적인 사항은 판매자가 제시한 사항보다 관계법령이 우선합니다. \n다만, 판매자의 제시사항이 관계법령보다 소비자에게 유리한 경우에는 판매자 제시사항이 적용됩니다.',
    ],
  },
  {
    title: '취소/환불로 인한 배송비',
    content: [
      '고객 단순 변심의 경우에 발생되며, 최초 배송비 무료인 경우 왕복배송비 차감 후 환불됩니다. \n도서산간 지역의 배송 상품은 추가 비용이 발생할 수 있습니다. \n부분 취소 시 남은 금액이 무료배송 조건을 유지하면 편도비용이 부과됩니다.',
    ],
  },
  {
    title: '배송 상태에 취소/환불 기능 안내',
    content: [
      '1. 결제완료',
      '판매자 주문 확인 전 즉시 환불 처리 가능, 결제 방법에 따라 환불 시점은 차이가 발생할 수 있습니다.',
      '2. 상품 준비중',
      '판매자 주문 확인 및 상품 준비 중 판매자 확인 후 승인 또는 철회, 결제 방법에 따라 환불 시점은 차이가 발생할 수 있습니다.',
      '3. 배송 준비중',
      '상품 포장 및 송장 등록 완료 시 환불요청 가능, 상품 수령 전 판매자가 확인 후 승인처리 됩니다.',
      '4. 배송 중',
      '상품 배송 중 환불요청 가능, 상품 수령 전 판매자가 확인 후 승인처리 됩니다.',
      '5. 배송 완료',
      '상품 배송 완료 시 환불요청 가능, 상품 수령 후 취소 요청에 대한 상품 검수 후 승인처리 됩니다.',
      '6. 주문완료',
      '상품 모든 주문 처리 완료 시 환불 불가, 상품 수령 후 상품 취소 처리 불가 합니다.',
    ],
  },
  {
    title: '취소/환불 제한사항',
    content: [
      '환불 요청 가능 기간이 초과한 경우(배송 완료 시점으로부터 7일 초과 시)',
      '주문/제작 상품의 경우, 상품의 제작이 이미 진행된 경우',
      '분실/파손/고장/오염이 발생한 경우',
      '모니터 해상도의 차이로 인해 색상이나 이미지가 실제와 달라, 고객인 단순 변심으로 처리를 요청하는 경우',
      '재고가 부족한 경우(단순 변심으로 요청했으나 재고가 부족한 경우 환불 처리될 수 있음)',
      '상품의 훼손 및 구성품 누락으로 상품의 가치가 현저히 감소된 경우',
      '설치 또는 사용하여 재판매가 어려운 경우',
      '상품의 시리얼 넘버 유출로 내장된 소프트웨어의 가치가 감소한 경우',
      '상품을 개봉하여 장착한 이후 단순 변심인 경우',
      '복제가 가능한 상품의 포장 등을 훼손한 경우',
    ],
  },
];
