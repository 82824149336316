import companyInfo from 'assets/info/company-info';
import 'styles/policy.css';

function PrivacyPolicy() {
  return (
    <div className="container policy">
      <h3 className="h-1 mg-19">개인정보 처리방침 동의(필수)</h3>
      <p className="txt-1 mg-24">
        팬어스(FANUS)를 서비스하는 ㈜팀블루(이하 팬어스 또는 ‘회사’)는
        개인정보보호법 등 국내의 개인정보 보호 법령을 철저히 준수합니다. 회사는
        개인정보가 어떠한 용도와 방식으로 이용되고 있으며 개인정보 보호를 위해
        어떠한 조치가 취해지고 있는지 알려 드리기 위해 다음과 같은 개인정보
        처리방침을 두고 있습니다.
      </p>
      <h4 className="h-2 mg-9">1. 개인정보의 수집 및 이용 목적</h4>
      <p className="txt-1 mg-9">
        회사는 개인정보를 다음의 목적을 위해 처리합니다. 회원이 제공한 모든
        정보는 다음의 목적 이외의 용도로는 사용되지 않으며 이용 목적이 변경될
        시에는 사전동의를 구합니다.
      </p>
      <p className="txt-1 mg-5">
        1) 서비스 제공에 관한 계약 이행 및 서비스 제공에 따른 요금 정산
      </p>
      <p className="txt-1 mg-5">
        - 콘텐츠 제공, 물품 배송 또는 청구서 등 발송, 금융거래 본인 인증 금융
        서비스, 구매 및 요금 결제, 요금 추심
      </p>
      <p className="txt-1 mg-5">2) 회원 관리</p>
      <p className="txt-1 mg-5">
        - 회원제 서비스 이용에 따른 개인식별, 불량 회원의 부정 이용 방지와
        비인가 사용 방지, 가입 의사 확인, 가입 제한, 추후 법정 대리인 본인 확인,
        분쟁 조정을 위한 기록 보존, 불만 처리 등 민원처리, 고지사항 전달,
        회원탈퇴 의사 확인
      </p>
      <p className="txt-1 mg-5">3) 마케팅 및 광고에 활용</p>
      <p className="txt-1 mg-5">
        - 신규 서비스(제품) 개발 및 특화, 인구통계학적 특성에 따른 서비스 제공
        및 광고 게재, 접속 빈도 파악, 회원의 서비스 이용에 대한 통계, 이벤트 등
        광고성 정보 전달
        <br />
        (회원님의 개인정보는 광고를 의뢰한 개인이나 단체에는 제공되지 않습니다.)
      </p>
      <p className="txt-1 mg-24">
        - 마케팅 및 광고에 활용하는 경우는 별도의 동의를 받고 있으며, 동의를
        거부하더라도 서비스의 이용에 제한이 없습니다.
      </p>
      <h4 className="h-2 mg-9">2. 수집하는 개인정보의 항목 및 수집방법</h4>
      <p className="txt-1 mg-5">1) 수집하는 개인정보 항목</p>
      <p className="txt-1 mg-5">
        1-1) 최초 회원가입 시 회원 식별 및 최적화된 서비스 제공을 위해 아래와
        같은 정보를 수집합니다.
      </p>
      <p className="txt-1 mg-5">
        - 필수항목: 이메일, 이름, 닉네임(또는 별명), 프로필
      </p>
      <p className="txt-1 mg-5">
        1-2) 회사는 다른 소셜 서비스 계정을 이용해 회원 가입하는 경우 아래와
        같은 개인정보를 필수항목으로 추가 수집하고 있습니다.
      </p>
      <p className="txt-1 mg-5">
        - 구글: 이메일, 프로필, 이름, 닉네임, 제공되는 필요한 선택 항목 등
      </p>
      <p className="txt-1 mg-5">
        - 네이버: 이메일, 프로필, 이름, 닉네임, 제공되는 필요한 선택 항목 등
      </p>
      <p className="txt-1 mg-5">
        - 카카오톡: 이메일, 프로필, 이름, 닉네임, 제공되는 필요한 선택 항목 등
      </p>
      <p className="txt-1 mg-5">
        1-3) 서비스 이용 과정에서 아래와 같은 정보들이 자동으로 생성되어 수집될
        수 있습니다.
      </p>
      <p className="txt-1 mg-5">
        - IP Address, 방문일시, 서비스 이용 기록, 불량이용 기록, 접속 기기 정보
      </p>
      <p className="txt-1 mg-5">1-4) 결제 시 아래의 정보가 수집됩니다.</p>
      <p className="txt-1 mg-5">
        - 카드사명, 카드번호, 카드유효기간, 휴대폰 번호(연락처), 이메일, 통신사,
        결제승인번호, 은행명, 계좌번호, 배송주소, 주문자명
      </p>
      <p className="txt-1 mg-5">
        1-5) 결제 취소 또는 환불 시 아래의 정보가 수집됩니다.
      </p>
      <p className="txt-1 mg-5">- 계좌 정보, 관련 증빙 서류</p>
      <p className="txt-1 mg-5">
        1-6) 고객상담 문의 등록 시 아래의 정보가 수집됩니다.
      </p>
      <p className="txt-1 mg-5">- 이름, 이메일 주소, 휴대폰 번호(연락처)</p>
      <p className="txt-1 mg-24">
        2) 개인정보 수집방법 <br />
        회사는 회원가입, 서비스 이용, 상품 구매, 생성정보 수집 틀을 통한 수집,
        회원정보 수정, 팩스, 전화, 고객센터 문의, 이벤트 응모, 제휴사의 제공으로
        개인정보를 수집합니다.
      </p>
      <h4 className="h-2 mg-9">3. 개인정보의 보유 및 이용 기간</h4>
      <p className="txt-1 mg-5">
        회사는 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체 없이
        파기합니다. 또한 회원이 1년간 서비스 이용 기록이 없는 경우 정보통신망
        이용촉진 및 정보보호 등에 관한 법률 제29조에 의거하여 회원에게
        사전통지하고 별도로 분리하여 저장합니다.
      </p>
      <p className="txt-1 mg-9">1) 회원 내부 방침에 의한 정보보유 사유</p>
      <table className="table mg-13">
        <colgroup>
          <col width="43.589%" />
          <col width="35.2564%" />
          <col width="21.474%" />
        </colgroup>
        <thead className="h-3">
          <tr>
            <th>내용</th>
            <th>보존 이유</th>
            <th>기간</th>
          </tr>
        </thead>
        <tbody className="txt-2">
          <tr>
            <td>부정 이용 기록</td>
            <td>부정 이용 방지</td>
            <td>1년</td>
          </tr>
          <tr>
            <td>불량 사용자 재가입 방지</td>
            <td>부정 이용 방지</td>
            <td>1년</td>
          </tr>
          <tr>
            <td>명예훼손 등 권리침해 분쟁 및 수사협조</td>
            <td>개인정보 민원처리</td>
            <td>1년</td>
          </tr>
          <tr className="btm-line-none">
            <td>회원 탈퇴 후 개인정보</td>
            <td>개인정보 민원처리</td>
            <td>1년</td>
          </tr>
        </tbody>
      </table>
      <p className="txt-1 mg-5">2) 관련법령에 의한 정보보유 사유</p>
      <p className="txt-1 mg-9">
        상법, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계법령의 규정에
        의하여 보존할 필요가 있는 경우 회사는 관계법령에서 정한 일정한 기간 동안
        회원정보를 보관합니다. 이 경우 보관하는 정보를 그 보관의 목적으로만
        이용하며 보존 기간은 아래와 같습니다.
      </p>
      <table className="table mg-24">
        <colgroup>
          <col width="45%" />
          <col width="38%" />
          <col width="17%" />
        </colgroup>
        <thead className="h-3">
          <tr>
            <th>내용</th>
            <th>보존 이유</th>
            <th>기간</th>
          </tr>
        </thead>
        <tbody className="txt-2">
          <tr>
            <td>계약 또는 청약철회 등에 관한 기록</td>
            <td>전자상거래 등에서의 소비자보호에 관한 법률</td>
            <td>5년</td>
          </tr>
          <tr>
            <td>대금결제 및 재화 등의 공급에 관한 기록</td>
            <td>전자상거래 등에서의 소비자보호에 관한 법률</td>
            <td>5년</td>
          </tr>
          <tr>
            <td>소비자의 불만 또는 분쟁처리에 관한 기록</td>
            <td>전자상거래 등에서의 소비자보호에 관한 법률</td>
            <td>3년</td>
          </tr>
          <tr>
            <td>전자 금융 거래에 관한 기록</td>
            <td>전자금융거래법</td>
            <td>10년</td>
          </tr>
          <tr>
            <td>본인 확인에 관한 기록</td>
            <td>정보통신망 이용촉진 및 정보보호 등에 관한 법률</td>
            <td>6개월</td>
          </tr>
          <tr>
            <td>표시, 광고에 관한 기록</td>
            <td>전자상거래 등에서의 소비자보호에 관한 법률</td>
            <td>6개월</td>
          </tr>
          <tr className="btm-line-none">
            <td>접속 로그, 접속 IP 정보, 서비스 이용 기록</td>
            <td>통신비밀보호법</td>
            <td>3개월</td>
          </tr>
        </tbody>
      </table>
      <h4 className="h-2 mg-9">4. 개인정보의 제공 및 공유</h4>
      <p className="txt-1 mg-9">
        회사는 사용자의 개인정보를 개인정보의 수집 및 이용목적에서 고지한 범위
        내에서 사용하며, 사용자의 사전 동의 없이는 원칙적으로 개인정보를 외부에
        공개하지 않습니다. 다만, 아래의 경우는 예외로 합니다.
      </p>
      <p className="txt-1 mg-9">1) 회원이 사전에 동의한 경우</p>
      <p className="txt-1 mg-5">
        정보수집 또는 정보제공 이전 내용에 대한 설명과 함께 동의를 구하는 절차를
        거치게 되며, 동의 하지 않은 경우는 추가적인 정보를 수집하거나 공유하지
        않습니다.
      </p>
      <p className="txt-1 mg-5">
        2) 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에
        따라 수사기관의 요구가 있는 경우
      </p>
      <p className="txt-1 mg-5">
        3) 학술 연구, 통계자료 및 시장조사를 위하여 개인 식별할 수 없는 형태의
        경우
      </p>
      <p className="txt-1 mg-5">
        4) 회원이 이용약관 및 정책, 관련 법령을 위반한 경우
      </p>
      <p className="txt-1 mg-9">
        5) 영업의 양수 사유가 발생하여 회원 개인정보의 이전이 필요한 경우
      </p>
      <p className="txt-1 mg-24">
        회사는 정보통신망 이용촉진 및 정보보호에 관한 법률 등 관계 법령에서
        규정한 절차와 방법에 따라 개인정보 이전에 관한 사실 등을 사전에
        고지하며, 회원에게 개인정보 이전에 관한 동의 철회권을 부여합니다.
      </p>
      <h4 className="h-2 mg-9">5. 개인정보의 처리 위탁</h4>
      <p className="txt-1 mg-9">
        회사는 서비스 이행을 위해 아래와 같이 외부 전문업체에 개인정보를
        위탁하여 운영하고 위탁계약 시 관계법령에 따라 개인정보가 안전하게 관리될
        수 있도록 필요한 사항을 규정하며 철저하게 관리하고 있습니다.
      </p>
      <p className="txt-1 mg-9">
        회사의 개인정보 위탁처리 기관 및 위탁업무는 다음과 같습니다.
      </p>
      <table className="table mg-24">
        <colgroup>
          <col width="44%" />
          <col width="26%" />
          <col width="30%" />
        </colgroup>
        <thead className="h-3">
          <tr>
            <th>위탁업무 내용</th>
            <th>위탁 업체</th>
            <th>
              개인정보의 보유 <br />및 이용 기간
            </th>
          </tr>
        </thead>
        <tbody className="txt-2">
          <tr>
            <td className="mg-l">
              서버 호스팅 CDN 업무, 클라우드, 서버 네트워크 관리
            </td>
            <td>Amazon</td>
            <td rowSpan={5} className="left-line">
              회원 탈퇴 시 혹은 위탁 계약 종료 시까지
            </td>
          </tr>
          <tr>
            <td>서비스 제공을 위한 시스템 개발 및 운영, 테스트</td>
            <td>(주)와키컴퍼니</td>
          </tr>
          <tr>
            <td>서비스 결제 처리</td>
            <td>(주)토스페이먼츠</td>
          </tr>
          <tr>
            <td>서비스 확인 문자 제공</td>
            <td>(주)누리고</td>
          </tr>
          <tr className="btm-line-none">
            <td>서비스 관리 위한 센터 운영</td>
            <td>(주)씨에스서브</td>
          </tr>
        </tbody>
      </table>
      <h4 className="h-2 mg-9">6. 개인정보의 파기절차 및 방법</h4>
      <p className="txt-1 mg-9">
        회사는 회원의 개인정보를 원칙적으로 개인정보의 수집 및 이용목적이
        달성되면 지체 없이 파기합니다. 회사의 개인정보 파기절차 및 방법은 다음과
        같습니다.
      </p>
      <p className="txt-1 mg-5">
        1) 파기절차
        <br />
        회원이 회원가입 등을 위해 입력한 정보는 목적이 달성된 후 내부 방침 및
        기타 관련 법령에 의한 정보보호 사유에 따라 일정 기간 저장된 후
        파기됩니다. 개인정보는 법령에 의한 보유 목적 이외의 다른 목적으로
        이용되지 않습니다.
      </p>
      <p className="txt-1 mg-24">
        2) 파기방법
        <br />
        전자적 파일 형태로 저장된 개인정보는 복원이 불가능한 방법으로 영구
        삭제하며, 문서로 출력된 개인정보는 파쇄기로 파쇄, 소각을 통하여
        파기합니다.
      </p>
      <h4 className="h-2 mg-9">7. 사용자 및 법정대리인의 권리와 그 행사방법</h4>
      <p className="txt-1 mg-9">
        회사는 회원의 개인정보를 원칙적으로 개인정보의 수집 및 이용목적이
        달성되면 지체 없이 파기 합니다. 회사의 개인정보 파기절차 및 방법은
        다음과 같습니다.
      </p>
      <p className="txt-1 mg-5">
        1) 사용자 및 법정대리인은 언제든지 등록되어 있는 자신 혹은 당해 만 14세
        미만 아동의 개인정보를 조회하거나 수정할 수 있으며 탈퇴를 요청할 수
        있습니다. 사용자 또는 만 14세 미만 아동의 개인정보 조회, 수정을 위해서는
        개인 정보 변경을 통해 가능하며, 회원 탈퇴 시 탈퇴 신청 메뉴를 이용하여
        직접 열람, 정정 또는 탈퇴가 가능합니다. 또한 개인정보 관리 책임자에게
        서면, 전화 또는 이메일로 연락하시면 바로 조치를 하겠습니다.
      </p>
      <p className="txt-1 mg-5">
        2) 회원이 개인정보의 오류에 대한 정정을 요청할 경우 정정을 완료하기
        전까지 당해 개인정보를 이용 또는 제공하지 않습니다. 또한 잘못된
        개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 바로
        통지하여 정정이 이루어지도록 하겠습니다.
      </p>
      <p className="txt-1 mg-24">
        3) 회사는 사용자 혹은 법정대리인의 요청에 의해 해지 또는 삭제된
        개인정보는 수집하는 개인정보의 보유 및 이용 기간에 명시된 조항에 따라
        처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.
      </p>
      <h4 className="h-2 mg-9">8. 개인정보의 안전성 확보 조치</h4>
      <p className="txt-1 mg-9">
        회사는 개인정보보호법 제29조에 따라 다음과 같이 안전성 확보에 필요한
        기술적, 관리적 조치를 하고 있습니다.
      </p>
      <p className="txt-1 mg-5">1) 개인정보 취급 직원의 최소화 및 교육</p>
      <p className="txt-1 mg-5">
        개인정보를 취급하는 직원을 지정하고 담당자에 한정시켜 최소화하여
        개인정보를 관리하는 대책을 시행하고 있습니다.
      </p>
      <p className="txt-1 mg-5">2) 내부관리계획의 수립 및 시행</p>
      <p className="txt-1 mg-5">
        개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고 시행하고
        있습니다.
      </p>
      <p className="txt-1 mg-5">3) 개인정보의 암호화</p>
      <p className="txt-1 mg-5">
        사용자의 비밀번호는 암호화되어 저장 및 관리되고 있어, 본인만이 알 수
        있으며 중요한 데이터는 파일 및 전송 데이터를 암호화하거나 파일 잠금
        기능을 사용하는 등의 별도 보안 기능을 사용하고 있습니다.
      </p>
      <p className="txt-1 mg-5">4) 해킹 등에 대비한 기술적 대책</p>
      <p className="txt-1 mg-24">
        회사는 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기
        위하여 보안 프로그램을 설치하고 주기적인 갱신, 점검하며 기술적,
        물리적으로 감시 및 차단하고 있습니다.
      </p>
      <h4 className="h-2 mg-9">9. 개인정보관리 책임자 및 민원서비스</h4>
      <p className="txt-1 mg-9">
        회사는 회원의 개인정보를 보호하고 개인정보와 관련한 민원을
        개인정보관리책임자 또는 담당 부서로 문의하실 수 있습니다. 회원의
        개인정보와 관련한 문의사항 있으시면 정확하고 신속한 처리를 위해 최대한
        노력하겠습니다.
      </p>
      <h4 className="h-2 mg-9">*개인정보관리 책임자</h4>
      <p className="txt-1 mg-5">이름 {companyInfo.PRIVACY_OFFICER_NAME}</p>
      <p className="txt-1 mg-5">이메일 {companyInfo.PRIVACY_OFFICER_EMAIL}</p>
      <p className="txt-1 mg-5">전화 {companyInfo.PRIVACY_OFFICER_TEL}</p>
      <h4 className="h-2 mg-9">*고객 서비스 담당</h4>
      <p className="txt-1 mg-5">고객센터 {companyInfo.CS_TEL}</p>
      <p className="txt-1 mg-9">이메일 {companyInfo.CS_EMAIL}</p>
      <h4 className="h-2 mg-9">
        개인정보침해에 관한 상담 및 신고가 필요한 경우는 다음과 같은 기관에
        문의하시기 바랍니다.
      </h4>
      <p className="txt-1 mg-5">
        개인정보침해신고센터 (국번없이 118 /privacy.kisa.or.kr)
      </p>
      <p className="txt-1 mg-5">
        대검찰청 사이버수사과 (국번없이 1301 / spo.go.kr)
      </p>
      <p className="txt-1 mg-24">
        경찰청 사이버수사국 (국번없이 182 / police.go.kr)
      </p>
      <h4 className="h-2 mg-9">10. 개인정보 처리방침의 적용 범위</h4>
      <p className="txt-1 mg-5">
        본 개인정보 처리방침은 회사의 ‘팬어스’ 및 관련 제반 서비스(모바일 웹/앱
        포함)를 적용합니다. 팬어스 서비스에 링크되어 있는 다른 회사의 사이트에서
        개인정보를 수집하는 경우, 사용자 동의 하에 개인정보가 제공된 이후에는 본
        개인정보처리방침이 적용되지 않습니다.
      </p>
      <p className="txt-1 mg-24">
        본 개인정보 처리방침에서 규정되지 않은 사항은 관계 법령의 범위 내에서
        당사 이용약관을 우선 적용합니다.
      </p>
      <h4 className="h-2 mg-9">11. 고지의 의무</h4>
      <p className="txt-1">
        개인정보 처리방침 내용 추가, 삭제 및 수정이 있을 시에는 개정 최소 7일
        전부터 공지사항을 통해 고지할 것입니다.
      </p>
    </div>
  );
}

export default PrivacyPolicy;
