export enum RESULT_CODE {
  SUCCESS = 200,
  SIGNIN = 210, //  # 이미 가입된 번호라 signin 처리
  SIGNUP = 220, // # 가입이 안된 번호라 가입 필요
  EXPIRED = 300,
  DIFFERENCE = 301, //  # 일치 하지 않을 때
  EXISTS = 302, // # 중복
  NOT_FIND_PHONE = 400,
  NOT_FIND_ADDRESS = 401,
  FAIL = -1,
}
