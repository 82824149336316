import styled from 'styled-components';
import { FeedItemResponse } from 'dto/FeedDto';
import { FEED_TYPE } from 'constants/FeedType';
import HomeFeedCardYoutube from './HomeFeedCardYoutube';
import HomeFeedCardBasic from './HomeFeedCardBasic';
import Colors from 'styles/color-system';

interface Props {
  data: FeedItemResponse[];
  name: string;
  onFeedLike: (
    d: number,
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => void;
  onFeedDetail: (
    id: number,
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => void;
}

function HomeFeeds({ data, name, onFeedDetail, onFeedLike }: Props) {
  const headingText = name.slice(1, name.length);

  return (
    <Container>
      <Heading>{`${headingText}과 함께`}</Heading>
      <Content>
        {data.map((feed) => (
          <CardWrapper key={feed.id} onClick={(e) => onFeedDetail(feed.id, e)}>
            {feed.type === FEED_TYPE.YOUTUBE && (
              <HomeFeedCardYoutube data={feed} onFeedLike={onFeedLike} />
            )}
            {feed.type === FEED_TYPE.BASIC && (
              <HomeFeedCardBasic data={feed} onFeedLike={onFeedLike} />
            )}
          </CardWrapper>
        ))}
      </Content>
    </Container>
  );
}

export default HomeFeeds;

const Container = styled.div`
  width: 100%;
  margin-top: 20px;
`;

const Heading = styled.h3`
  width: 100%;
  color: ${Colors.Gray[200]};
  font-size: 18px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.02em;
  margin-bottom: 17px;
  padding-left: 20px;
  box-sizing: border-box;
`;

const Content = styled.div`
  width: 100%;
  padding-inline: 12px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const CardWrapper = styled.div`
  cursor: pointer;
  user-select: none;
`;
