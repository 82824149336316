import { BrowserRouter as Router } from 'react-router-dom';
import styled from 'styled-components';
import { GlobalStyle } from 'styles/common';
import RoutesContainer from 'RoutesContainer';
import CustomAxiosLoader from 'services/CustomAxiosLoader';
import { Provider as OrderProvider } from 'contexts/OrderContext';
import useInAppBypassing from 'hook/useInAppBypassing';

function App() {
  // inApp Browser bypassing 라우팅 개선 완료후 활성화 예정
  // useInAppBypassing();
  return (
    <Container>
      <CustomAxiosLoader />
      <GlobalStyle />
      <OrderProvider>
        <RoutesContainer />
      </OrderProvider>
    </Container>
  );
}

export default App;

const Container = styled(Router)`
  position: relative;
  width: 100%;
  height: 100%;
`;
