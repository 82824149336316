import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Utils } from 'utils/Utils';
import { ProductSimpleDto } from 'dto/ProductDto';
import ShopItemBadge, { Status } from './ShopItemBadge';
import Image from 'components/common/Image';
import Colors from 'styles/color-system';

interface Props {
  item: ProductSimpleDto;
  onClick: (item: ProductSimpleDto) => void;
  lastChild: boolean;
}

interface StyledProps {
  discount?: boolean;
  soldOut?: boolean;
  btmline?: boolean;
}

function ShopListItem({ item, onClick, lastChild }: Props) {
  const [status, setStatus] = useState<string>('NONE');

  useEffect(() => {
    const itemStatus = Utils.calcShopItemState(item.start, item.end) || 'NONE';

    if (item.stock - item.condition <= 0) {
      setStatus('SOLD_OUT');
      return;
    }

    if (item.stock > 0) {
      setStatus(itemStatus);
      return;
    }
  }, []);

  const renderBadge = () => {
    if (status === 'ONGOING') {
      return <ShopItemBadge type="LIMITED" />;
    }

    return <ShopItemBadge type={status as Status} />;
  };

  return (
    <Item key={item.id} onClick={() => onClick(item)} btmline={lastChild}>
      <Thumbnail>
        {renderBadge()}
        <ImageWrapper soldOut={status === 'SOLD_OUT'}>
          <Image src={item.thumbnail} />
        </ImageWrapper>
      </Thumbnail>
      <Title>{item.title}</Title>
      <PriceSection discount={item.amount.is_discount}>
        {item.amount.is_discount && (
          <DiscountRate>{item.amount.percent}%</DiscountRate>
        )}
        <Price>{Utils.convertCurrency(item.amount.amount)}원</Price>
        {item.amount.is_discount && (
          <Cost>{Utils.convertCurrency(item.amount.origin)}원</Cost>
        )}
      </PriceSection>
    </Item>
  );
}

export default ShopListItem;

const Item = styled.div<StyledProps>`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding-top: 34px;
  padding-bottom: 28px;
  box-sizing: border-box;
  cursor: pointer;
  user-select: none;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 12px;
    width: calc(100% - 24px);
    border-bottom: 1px solid transparent;
    border-color: ${({ btmline }) => btmline && `${Colors.Gray[700]}`};
  }
`;

const Thumbnail = styled.div`
  position: relative;
  height: 49.44444vw;
  max-height: 247px;
`;

const ImageWrapper = styled.div<StyledProps>`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;
  opacity: ${({ soldOut }) => (soldOut ? '0.3' : '1')};

  & img {
    width: auto;
    background: transparent;
  }
`;

const Title = styled.span`
  text-align: center;
  width: calc(100% - 24px);
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${Colors.Gray[100]};
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
`;

const PriceSection = styled.div<StyledProps>`
  text-align: center;
  ${({ discount }) =>
    discount &&
    css`
      display: grid;
      grid-template-areas:
        'discount price'
        'cost cost';
      gap: 2px;
    `}
  font-weight: 700;
  line-height: normal;
`;

const Cost = styled.span`
  grid-area: cost;
  color: ${Colors.Gray[300]};
  font-size: 13px;
  text-decoration-line: line-through;
  text-decoration-thickness: 1px;
`;

const Price = styled.span`
  grid-area: price;
  color: ${Colors.Gray[100]};
  font-size: 16px;
`;

const DiscountRate = styled.span`
  grid-area: discount;
  color: ${Colors.Error[500]};
  font-size: 16px;
`;
