import React from 'react';
import styled from 'styled-components';
import { Utils } from 'utils/Utils';
import Colors from 'styles/color-system';

interface Props {
  method: string;
  order: number;
  delivery: number;
}

function OrderPaymentInfoCard({ method, order, delivery }: Props) {
  return (
    <Container>
      <Title>결제정보</Title>
      <Card>
        <PaymentInfo>
          <PaymentInline>
            <PayLabel>결제수단</PayLabel>
            <PayValue>{method}</PayValue>
          </PaymentInline>
          <PaymentInline>
            <PayLabel>주문 금액</PayLabel>
            <PayValue>{Utils.convertCurrency(order)}원</PayValue>
          </PaymentInline>
          <PaymentInline>
            <PayLabel>배송비</PayLabel>
            <PayValue>
              {delivery === 0 ? '무료' : `${Utils.convertCurrency(delivery)}원`}
            </PayValue>
          </PaymentInline>
        </PaymentInfo>
        <TotalPaymentInfo>
          <PayLabel>총 결제 금액</PayLabel>
          <PayValue>{Utils.convertCurrency(order + delivery)}원</PayValue>
        </TotalPaymentInfo>
      </Card>
    </Container>
  );
}

export default OrderPaymentInfoCard;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 30px;
`;

const Title = styled.h3`
  color: ${Colors.Gray[200]};
  font-size: 18px;
  font-weight: 700;
  line-height: 1.222em;
  letter-spacing: -0.02em;
`;

const Card = styled.div`
  border-radius: 6px;
  background: ${Colors.Gray[700]};
`;

const PayLabel = styled.span`
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const PayValue = styled.span`
  text-align: right;
  font-weight: 700;
  line-height: normal;
`;

const PaymentInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
  padding: 18px 18px 17px;
  border-bottom: 1px solid ${Colors.Gray[600]};
  box-sizing: border-box;
  color: ${Colors.Gray[100]};
`;

const PaymentInline = styled.div`
  display: flex;
  justify-content: space-between;

  & ${PayValue} {
    font-size: 15px;
  }
`;

const TotalPaymentInfo = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 11px;
  margin-bottom: 14px;
  padding-inline: 18px;
  box-sizing: border-box;
  color: ${Colors.Primary[500]};

  & ${PayValue} {
    font-size: 20px;
  }
`;
