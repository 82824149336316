import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import { NoticeDetailResponse } from 'dto/NoticeDto';
import { ArtistService } from 'services/ArtistService';
import DateUtils from 'utils/DateUtils';
import HeaderNavBar from 'components/common/HeaderNavBar';
import Colors from 'styles/color-system';

function NoticeDetailPage() {
  const navigate = useNavigate();
  const { noticeId } = useParams();
  const [noticeDetail, setNoticeDetail] = useState<NoticeDetailResponse>();
  const createdDate = DateUtils.convertDateToYYMMDD(
    DateUtils.convertTimeToDate(noticeDetail?.created),
  );

  useEffect(() => {
    if (noticeId) {
      ArtistService.noticeDetail(parseInt(noticeId)).then((response) => {
        const result = response.data as NoticeDetailResponse;
        setNoticeDetail(result);
        console.log(result);
      });
    } else {
      navigate(-1);
    }
  }, [noticeId]);

  return (
    <Container>
      <HeaderNavBar leftItem="BACK" />
      <ContentBody>
        <TopSction>
          <Title>{noticeDetail?.title}</Title>
          <Date>{createdDate}</Date>
        </TopSction>
        <Content dangerouslySetInnerHTML={{ __html: noticeDetail?.content! }} />
      </ContentBody>
    </Container>
  );
}

export default NoticeDetailPage;

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  height: 100%;
  background: ${Colors.Background[500]};
`;

const ContentBody = styled.div`
  flex: 1;
  display: block;
  overflow: hidden auto;
`;

const TopSction = styled.div`
  position: relative;
  margin-top: 18px;
  margin-bottom: 29px;
  padding-inline: 24px;
  padding-bottom: 19px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 6px;

  &::after {
    content: '';
    position: absolute;
    left: 12px;
    bottom: 0;
    width: calc(100% - 24px);
    height: 1px;
    background: ${Colors.Gray[500]};
  }
`;

const Title = styled.p`
  color: ${Colors.Gray[200]};
  font-size: 20px;
  font-weight: 700;
  line-height: 1.3em;
  letter-spacing: -0.02em;
`;

const Date = styled.span`
  text-align: right;
  color: ${Colors.Gray[500]};
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.73333em;
  letter-spacing: -0.02em;
`;

const Content = styled.div`
  padding-inline: 24px;
  margin-bottom: 133px;
  box-sizing: border-box;

  & p {
    color: ${Colors.Gray[200]};
    font-size: 20px;
    font-weight: 400;
    line-height: 1.3em;
    letter-spacing: -0.02em;
  }

  & img {
    display: block;
    width: 100%;
    object-fit: cover;
    pointer-events: none;
    background: var(--skeleton-color);
  }

  & a {
    display: block;
    width: 100%;
    text-overflow: clip;
    overflow: hidden;
    color: ${Colors.Primary[500]};
    width: 100%;
  }
`;

const ShareBtn = styled.button`
  display: block;
  width: 180px;
  height: 48px;
  border-radius: 6px;
  overflow: hidden;
  background: ${Colors.Gray[600]};
  margin-inline: auto;
  margin-bottom: 90px;
  color: ${Colors.White[500]};
  font-size: 20px;
  font-weight: 700;
  line-height: 1.3em;
  letter-spacing: -0.02em;
  cursor: pointer;
  user-select: none;
`;
