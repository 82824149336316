import React from 'react';
import styled from 'styled-components';
import { ProductDetailDto } from 'dto/ProductDto';
import Colors from 'styles/color-system';

interface Props {
  data: ProductDetailDto;
}

function ShopDetailMiddleSection({ data }: Props) {
  return (
    <Container>
      <Content
        dangerouslySetInnerHTML={{ __html: data.description?.description }}
      />
    </Container>
  );
}

export default ShopDetailMiddleSection;

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Content = styled.div`
  width: 100%;
  color: ${Colors.Gray[200]};
  font-size: 20px;
  font-weight: 400;
  line-height: 1.3em;
  letter-spacing: -0.02em;

  & img {
    display: block;
    width: 100%;
    object-fit: cover;
    pointer-events: none;
    background: var(--skeleton-color);
  }
`;
