import styled from 'styled-components';
import DaumPostcodeEmbed from 'react-daum-postcode';
import { ReactComponent as Close } from 'assets/icon/close.svg';
import Colors from 'styles/color-system';

interface Props {
  onClose: () => void;
  onChange: (post: string, address: string) => void;
}

interface Value {
  zonecode: string;
  address: string;
}

const themeObj = {
  bgColor: Colors.Background[500], //바탕 배경색
  searchBgColor: Colors.Gray[700], //검색창 배경색
  contentBgColor: Colors.Background[500], //본문 배경색(검색결과,결과없음,첫화면,검색서제스트)
  pageBgColor: Colors.Background[500], //페이지 배경색
  textColor: Colors.Gray[200], //기본 글자색
  queryTextColor: Colors.Gray[200], //검색창 글자색
  postcodeTextColor: '#FA4256', //우편번호 글자색
  emphTextColor: '#008BD3', //강조 글자색
  outlineColor: Colors.Gray[600], //테두리
};

function Postcode({ onClose, onChange }: Props) {
  const handlePostCode = ({ zonecode, address }: Value) => {
    onClose();
    onChange(zonecode, address);
  };
  return (
    <Container>
      <Header>
        <Label>주소검색</Label>
        <CloseButton onClick={onClose}>
          <Close stroke={Colors.White[500]} />
        </CloseButton>
      </Header>
      <Body>
        <DaumPostcodeEmbed
          onComplete={handlePostCode}
          autoClose={false}
          hideMapBtn
          hideEngBtn
          useBannerLink={false}
          style={{ flex: 1, height: 'initial' }}
          theme={themeObj}
        />
      </Body>
    </Container>
  );
}

export default Postcode;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  background: ${Colors.Background[500]};
  pointer-events: auto;
`;

const Header = styled.div`
  position: relative;
  width: 100%;
  height: 68px;
  background: ${Colors.Background[500]};
  border-bottom: 1px solid ${Colors.Gray[600]};
`;

const Label = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  color: ${Colors.White[500]};
  font-size: 20px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.02em;
`;

const CloseButton = styled.div`
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translate3d(0, -50%, 0);
`;

const Body = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
`;
