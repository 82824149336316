import styled from 'styled-components';
import { ScheduleItemResponse } from 'dto/ScheduleDto';
import { SCHEDULE_TYPE_LABELS } from 'constants/ScheduleType';
import DateUtils from 'utils/DateUtils';
import Colors from 'styles/color-system';

interface Props {
  data: ScheduleItemResponse[] | undefined;
}

function HomeSchedules({ data }: Props) {
  const convertScheduleTime = (schedule: ScheduleItemResponse) => {
    const time = DateUtils.convertTimeToDate(schedule.time!);
    if (schedule.all_day) return DateUtils.convertDateToMMDDEEEE(time);

    return DateUtils.convertDateToMMDDEEEEHHmm(time);
  };

  return (
    <Container>
      <Heading>스케줄</Heading>
      <Content>
        {data?.map((schedule) => (
          <Card key={schedule.id}>
            <TopSection>
              <Type>{SCHEDULE_TYPE_LABELS[schedule.type]}</Type>
              <Title>{schedule.title}</Title>
            </TopSection>
            <Time>{convertScheduleTime(schedule)}</Time>
          </Card>
        ))}
      </Content>
    </Container>
  );
}
export default HomeSchedules;

const Container = styled.div`
  width: 100%;
  height: auto;
  margin-top: 32px;
`;

const Heading = styled.h3`
  color: ${Colors.Gray[200]};
  font-size: 18px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.02em;
  margin-left: 20px;
  margin-bottom: 17px;
`;

const Content = styled.div`
  display: flex;
  gap: 5px;
  width: 100%;
  height: auto;
  overflow: auto hidden;
  padding-bottom: 20px;

  & > div:first-child {
    margin-left: 12px;
  }

  & > div:last-child {
    margin-right: 12px;
  }
`;

const Card = styled.div`
  position: relative;
  width: 312px;
  min-width: 312px;
  height: 88px;
  padding: 20px 20px 20px 18px;
  border-radius: 20px;
  box-sizing: border-box;
  border: 1px solid ${Colors.Gray[700]};
  background: ${Colors.Gray[700]};
  display: flex;
  flex-direction: column;
  gap: 5px;
  cursor: pointer;
  user-select: none;
`;

const TopSection = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 6px;
`;

const Title = styled.span`
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: ${Colors.Gray[100]};
  font-size: 20px;
  font-weight: 500;
  line-height: normal;
`;

const Type = styled.span`
  white-space: nowrap;
  padding: 3px 6px;
  border-radius: 13px;
  box-sizing: border-box;
  background: ${Colors.Primary[500]};
  color: ${Colors.White[500]};
  font-size: 12px;
  font-weight: 700;
  line-height: normal;
`;

const Time = styled.span`
  margin-left: 2px;
  color: #aaaaad;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
`;
