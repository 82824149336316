import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as Plus } from 'assets/icon/plus.svg';
import { ReactComponent as Minus } from 'assets/icon/minus.svg';
import Colors from 'styles/color-system';

interface Props {
  value: number;
  onChange: (val: number) => void;
}

function Counter({ value, onChange }: Props) {
  const incrementHandler = () => {
    onChange(value + 1);
  };

  const decrementHandler = () => {
    if (value === 1) {
      return;
    }
    onChange(value - 1);
  };

  return (
    <Container>
      <Button onClick={decrementHandler}>
        <Minus stroke={Colors.Gray[300]} />
      </Button>
      <Number>{value}</Number>
      <Button onClick={incrementHandler}>
        <Plus stroke={Colors.Gray[300]} />
      </Button>
    </Container>
  );
}

export default Counter;

const Container = styled.div`
  display: flex;
  width: 99px;
  height: 32px;
  padding-inline: 8px;
  padding-block: 5px;
  border-radius: 6px;
  box-sizing: border-box;
  background: ${Colors.Background[500]};
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
`;

const Number = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 22px;
  color: ${Colors.White[500]};
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  user-select: none;
`;
