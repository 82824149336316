import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import HeaderNavBar from 'components/common/HeaderNavBar';
import Colors from 'styles/color-system';
import CollectionListItem from 'components/collection/CollectionListItem';
import { UserService } from 'services/UserService';
import { CollectionListResponse, UserCollectionDto } from 'dto/CollectionDto';
import PATH from 'Path';
import useProtection from 'hook/useProtection';

function CollectionListPage() {
  const navigate = useNavigate();
  useProtection();

  const [name, setName] = useState<string>('');
  const [items, setItems] = useState<(UserCollectionDto | {})[]>([]);
  const [total, setTotal] = useState<number>(0);

  useEffect(() => {
    UserService.collections().then((response) => {
      const result = response.data as CollectionListResponse;
      setName(result.name);
      setItems(() => {
        return [...result.items, {}];
      });
      setTotal(result.total);
      console.log(result);
    });
  }, []);

  const handleCollectionDetail = (id: number) => {
    navigate(PATH.Collections + `/${id}`);
  };

  return (
    <Container>
      <HeaderNavBar leftItem="BACK" label="굿즈 콜렉션" />
      <ScrollSection>
        <TitleSection>
          <Title>{name} 님의 굿즈 콜렉션</Title>
          <Possession>
            보유 굿즈 <span className="number">{total}개</span>
          </Possession>
        </TitleSection>
        <ListSection>
          {items.map((data) => (
            <CollectionListItem
              key={Math.random().toString()}
              data={data}
              onClick={handleCollectionDetail}
            />
          ))}
        </ListSection>
      </ScrollSection>
    </Container>
  );
}

export default CollectionListPage;

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  height: 100%;
  background: ${Colors.Background[500]};
  overflow: hidden;
`;

const ScrollSection = styled.div`
  flex: 1;
  overflow: hidden auto;
`;

const TitleSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 9px;
  width: 100%;
  padding-inline: 24px;
  box-sizing: border-box;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 36px;
  color: ${Colors.White[500]};
`;

const Title = styled.h3`
  font-size: 24px;
  font-weight: 700;
  line-height: normal;
`;

const Possession = styled.span`
  font-size: 20px;
  font-weight: 500;
  line-height: normal;

  & .number {
    color: ${Colors.Primary[500]};
    font-weight: 700;
  }
`;

const ListSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 13px;
  padding-inline: 24px;
  box-sizing: border-box;
`;
