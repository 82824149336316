import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Colors from 'styles/color-system';
import HeaderNavBar from 'components/common/HeaderNavBar';
import AuthInput from 'components/common/AuthInput';
import BottomButton from 'components/common/BottomButton';

function PhoneAuthPage() {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [authNumber, setAuthNumber] = useState('');

  const handlePhoneNumber = (val: string) => {
    setPhoneNumber(val);
  };

  const handleAuthNumber = (val: string) => {
    setAuthNumber(val);
  };

  const handleSend = () => {};

  const handleResend = () => {};

  return (
    <Container>
      <HeaderNavBar
        leftItem="BACK"
        label="휴대폰 인증"
        btmLine
        bgColor={Colors.Background[500]}
      />
      <ContentSection>
        <AuthSection>
          <AuthInput
            ActionLabel="인증"
            placeholder="휴대폰번호"
            actionActive
            state="ERROR"
            value={phoneNumber}
            onChange={handlePhoneNumber}
            onAction={handleSend}
          />
          <AuthInput
            ActionLabel="재전송"
            placeholder="휴대폰번호"
            actionActive={false}
            value={authNumber}
            onChange={handleAuthNumber}
            onAction={handleResend}
          />
          <span className="time">인증 유효 시간 04:00</span>
        </AuthSection>
        <SignInSection>
          <span className="text">FANUS 계정이 있다면?</span>
          <Link className="signIn" to="">
            로그인
          </Link>
        </SignInSection>
      </ContentSection>
      <BottomButton label="계속하기" onClick={() => console.log()} />
    </Container>
  );
}

export default PhoneAuthPage;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 100%;
`;

const ContentSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 80px;
  padding-top: 35px;
  padding-inline: 24px;
  box-sizing: border-box;
`;

const AuthSection = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;

  & .time {
    position: absolute;
    left: 0;
    bottom: -10px;
    transform: translate3d(0, 100%, 0);
    color: ${Colors.Error[500]};
    font-size: 15px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.3px;
  }
`;

const SignInSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;

  & .text {
    color: ${Colors.Gray[100]};
    font-size: 17px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.34px;
  }

  & .signIn {
    color: ${Colors.Gray[200]};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.32px;
    text-underline-offset: 0.2em;
  }
`;
