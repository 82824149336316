import { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import useOverlay from 'hook/useOverlay';
import Input from './Input';
import Postcode from 'components/overlays/Postcode';
import Colors from 'styles/color-system';

interface Props {
  label?: string;
  essential: boolean;
  value: Value;
  state: 'DEFAULT' | 'ERROR';
  errorMessage: string;
  onBlur: (value: Value) => void;
}

type Value = {
  post_code?: string;
  address0?: string;
  address1?: string;
};

interface StyledProps {
  state?: 'DEFAULT' | 'ERROR';
}

function AddressInput({
  label,
  essential,
  value,
  state,
  errorMessage,
  onBlur,
}: Props) {
  const { showHandler: showOverlay, hideHandler: hideOverlay } = useOverlay();
  const [inputValue, setInputValue] = useState<Value>({
    post_code: '',
    address0: '',
    address1: '',
  });

  useEffect(() => {
    setInputValue(() => {
      return {
        post_code: value.post_code,
        address0: value.address0,
        address1: value.address1,
      };
    });
  }, [value]);

  const handleClickPostCode = () => {
    const handleClose = () => {
      hideOverlay(id);
    };

    const handleSetValue = (post: string, address: string) => {
      onBlur({ post_code: post, address0: address });
    };
    const id = showOverlay(
      <Postcode onClose={handleClose} onChange={handleSetValue} />,
      'POPUP',
    );
  };

  const handleDetailAddressBlur = (value?: string) => {
    onBlur({ address1: value });
  };

  const inputLabel = (
    <Label>
      {label}
      {essential && <Essential>*</Essential>}
    </Label>
  );

  return (
    <Container>
      {label && inputLabel}
      <AddressInputWrapper>
        <PostCode>
          <InputFiled
            readOnly
            value={inputValue.post_code}
            placeholder="우편주소"
          />
          <Action onClick={handleClickPostCode}>주소검색</Action>
        </PostCode>
        <Input
          readOnly
          value={inputValue.address0}
          placeholder="시(도)/구/도로명주소"
        />
        <Input
          placeholder="상세 주소 입력"
          value={inputValue.address1}
          onBlur={handleDetailAddressBlur}
          state={state}
          errorMessage={errorMessage}
        />
      </AddressInputWrapper>
    </Container>
  );
}

export default AddressInput;

AddressInput.defaultProps = {
  essential: false,
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 9px;
`;

const Label = styled.span`
  color: ${Colors.Gray[200]};
  font-size: 15px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.02em;
`;

const Essential = styled.span`
  color: ${Colors.Primary[500]};
`;

const AddressInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const PostCode = styled.div`
  position: relative;
  width: 100%;
  height: 48px;
`;

const Action = styled.button`
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translate3d(0, -50%, 0);
  padding-block: 5px;
  color: ${Colors.Primary[500]};
  font-size: 18px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.02em;
  cursor: pointer;
  user-select: none;
`;

const InputFiled = styled.input<StyledProps>`
  width: 100%;
  height: 100%;
  padding: 13px 85px 13px 13px;
  border: 1px solid transparent;
  border-radius: 6px;
  outline: initial;
  box-sizing: border-box;
  background: ${Colors.Gray[700]};
  transition: border 150ms ease-in-out, color 100ms ease-in-out;

  color: ${Colors.Gray[100]};
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.02em;

  &::placeholder {
    color: ${Colors.Gray[500]};
  }

  ${({ state }) =>
    state === 'ERROR' &&
    css`
      border-color: ${Colors.Error[500]};
      color: ${Colors.Error[500]};
    `}
`;
