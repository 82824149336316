import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { Context as AuthContext } from 'contexts/AuthContext';
import { ArtistService } from 'services/ArtistService';
import { Utils } from 'utils/Utils';
import PATH from 'Path';
import { ShopListResponse } from 'dto/ShopDto';
import HeaderNavBar from 'components/common/HeaderNavBar';
import ItemNotFound from 'components/common/ItemNotFound';
import { ProductSimpleDto } from 'dto/ProductDto';
import MoreView from 'components/common/MoreView';
import ShopListItem from 'components/shop/ShopListItem';
import Colors from 'styles/color-system';
import { KEY_PRODUCT_ID } from 'constants/ProductType';

const LIST_PAGE_LIMIT = 10;
function ShopListPage() {
  const navigate = useNavigate();

  const {
    state: { artist },
  } = useContext(AuthContext);

  const [items, setItems] = useState<ProductSimpleDto[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);

  useEffect(() => {
    if (!artist) {
      return;
    }

    const productId = Utils.loadFromSession<string>(KEY_PRODUCT_ID);
    if (productId) {
      Utils.saveToSession<string>(KEY_PRODUCT_ID, '');
      navigate(Utils.subNavigationPath('/' + PATH.Shop, '' + productId));
      return;
    }

    ArtistService.shopList(artist, currentPage, LIST_PAGE_LIMIT).then(
      (response) => {
        const result = response.data as ShopListResponse;
        console.log(result);
        setItems(result.items);
        setTotalCount(result.total);
      },
    );
  }, [artist]);

  useEffect(() => {
    ArtistService.shopList(artist, currentPage, LIST_PAGE_LIMIT).then(
      (response) => {
        const result = response.data as ShopListResponse;
        setItems([...items, ...result.items]);
      }
    )
  }, [currentPage]);

  const moreData = () => {
    const totalPage = Math.ceil(totalCount / LIST_PAGE_LIMIT);
    if (currentPage === totalPage) {
      return;
    }

    setCurrentPage(currentPage + 1);
  };

  const handleClick = (item: ProductSimpleDto) => {
    navigate('' + item.id);
  };

  // const convertList = () => {
  //   const newList = [...items];
  //   // 임시
  //   // 서버에서 처리하기로함
  //   // 수정되면 삭제
  //   const calcTime = (start: number, end: number) => {
  //     const state = Utils.calcShopItemState(start, end);

  //     if (state === 'ONGOING' || state === 'BEFORE') {
  //       return start;
  //     }

  //     if (state === 'AFTER') {
  //       return end + Math.floor(Date.now() / 100);
  //     }
  //   };

  //   // newList.sort((a, b) => {
  //   //   const itemA = calcTime(a.start, a.end)!;
  //   //   const itemB = calcTime(b.start, b.end)!;
  //   //
  //   //   return itemA - itemB;
  //   // });

  //   return newList;
  // };

  const renderList = () => {
    if (items.length === 0) {
      return <ItemNotFound message="등록된 상품이 없습니다." />;
    }

    return (
      <>
        {items.map((item, index) => (
          <ShopListItem
            key={item.id}
            item={item}
            onClick={handleClick}
            lastChild={items.length !== index + 1}
          />
        ))}
      </>
    );
  };

  return (
    <Container>
      <HeaderNavBar leftItem="LOGO" label="쇼핑" rightItem="CART" />
      <ListContainer>
        {renderList()}
        <MoreView onClick={moreData} show={totalCount > items.length} />
      </ListContainer>
    </Container>
  );
}

export default ShopListPage;

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  background: ${Colors.Background[500]};
`;

const ListContainer = styled.div`
  flex: 1;
  overflow: hidden auto;
`;
