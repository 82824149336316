import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import triangle from 'assets/icon/triangle.svg';
import Colors from 'styles/color-system';

export type Option = {
  title: string;
  soldOut: boolean;
};

interface Props {
  options: string[] | Option[];
  label?: string;
  placeholder?: string;
  essential?: boolean;
  onChange: (value: string) => void;
}

interface StyledProps {
  active?: boolean;
  select?: boolean;
  notFirst?: boolean;
  essential?: boolean;
  disabled?: boolean;
}

function Select({ label, placeholder, essential, options, onChange }: Props) {
  const [isOption, setIsOption] = useState(false);
  const [value, setValue] = useState('');

  useEffect(() => {
    onChange(value);
  }, [value]);

  const changeHandler = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setValue(e.currentTarget.innerText);
    hideHandler();
  };

  const showHandler = () => {
    setIsOption(true);
  };

  const hideHandler = () => {
    setIsOption(false);
  };

  return (
    <Container onMouseLeave={hideHandler}>
      {label && <Label>{label}</Label>}
      <OuterWrapper>
        <InnerWrapper active={isOption}>
          <Input active={isOption} onClick={showHandler}>
            <InputField
              id="input"
              readOnly
              value={value || ''}
              placeholder={placeholder || ''}
              active={isOption}
              essential={essential}
            />
          </Input>

          {isOption && (
            <OptionsList>
              {options.map((option, index) =>
                typeof option === 'string' ? (
                  <OptionItem
                    key={option}
                    onClick={changeHandler}
                    notFirst={index !== 0}
                    select={value === option}
                    disabled={false}
                  >
                    {option}
                  </OptionItem>
                ) : (
                  <OptionItem
                    key={option.title}
                    onClick={changeHandler}
                    notFirst={index !== 0}
                    select={value === option.title}
                    disabled={option.soldOut}
                  >
                    {option.title}
                  </OptionItem>
                ),
              )}
            </OptionsList>
          )}
        </InnerWrapper>
      </OuterWrapper>
    </Container>
  );
}

export default Select;

Select.defaultProps = {
  essential: false,
};

const Container = styled.div<StyledProps>`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 9px;
`;

const Label = styled.span`
  color: ${Colors.Gray[200]};
  font-size: 15px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.02em;
`;

const OuterWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 48px;
`;

const InnerWrapper = styled.div<StyledProps>`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  border: 1px solid transparent;
  border-color: ${({ active }) => active && `${Colors.Primary[500]}`};
  border-radius: 6px;
  background: ${Colors.Gray[700]};
  overflow: hidden;
  box-sizing: border-box;
  transition: border-color 150ms ease-in-out;
  z-index: 100;
`;

const Input = styled.div<StyledProps>`
  position: relative;
  width: 100%;
  height: 46px;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 19px;
    width: 15px;
    height: 20px;
    background-image: ${`url(${triangle})`};
    transform: translate3d(0, -50%, 0)
      ${({ active }) => `rotate(${active ? 180 : 0}deg)`};
    transition: transform 200ms ease-in-out;
  }
`;

const InputField = styled.input<StyledProps>`
  all: unset;
  width: 100%;
  height: 100%;
  padding: 13px 73px 14px 14px;
  border-bottom: 1px solid transparent;
  border-color: ${({ active }) => active && `${Colors.Gray[500]}`};
  box-sizing: border-box;

  color: ${Colors.Gray[100]};
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.02em;

  &::placeholder {
    color: ${({ essential }) =>
      essential ? `${Colors.Gray[100]}` : `${Colors.Gray[500]}`};
  }
`;

const OptionsList = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 256px;
  overflow: hidden auto;
`;

const OptionItem = styled.div<StyledProps>`
  color: ${Colors.Gray[100]};
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.02em;
  padding: 17px 20px 17px 14px;
  border-top: ${({ notFirst }) => notFirst && `1px solid ${Colors.Gray[600]}`};
  box-sizing: border-box;
  background: ${({ select }) => select && 'rgba(116, 59, 239, 0.2)'};
  cursor: pointer;
  user-select: none;

  ${({ disabled }) =>
    disabled &&
    css`
      color: ${Colors.Gray[500]};
      pointer-events: none;
      cursor: none;
    `};
`;
