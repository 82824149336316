import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { OrderListItemDto, OrderListRes } from 'dto/OrderDto';
import { OrderService } from 'services/OrderService';
import { Utils } from 'utils/Utils';
import HeaderNavBar from 'components/common/HeaderNavBar';
import { ORDER_STATUS_LABELS } from 'constants/OrderStatus';
import MoreView from 'components/common/MoreView';
import Colors from 'styles/color-system';

const LIST_PAGE_LIMIT = 5;

function OrderListPage() {
  const navigate = useNavigate();
  const [orders, setOrders] = useState<OrderListItemDto[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);

  useEffect(() => {
    OrderService.orders(currentPage, LIST_PAGE_LIMIT).then(
      (response) => {
      const result = response.data as OrderListRes;
      console.log(result)
      setOrders(result.items);
      setTotalCount(result.total);
    });
  }, []);

  useEffect(() => {
    if (currentPage > 1) {
      OrderService.moreOrders(currentPage, LIST_PAGE_LIMIT).then(
        (response) => {
          const items = response.data as OrderListItemDto[];
          setOrders([...orders, ...items]);
        },
      );
    }
  }, [currentPage]);

  const handlePage = () => {
    const totalPage = Math.ceil(totalCount / LIST_PAGE_LIMIT);
    if (currentPage === totalPage) {
      return;
    }

    setCurrentPage(currentPage + 1);
  };

  const onClickOrder = (id: number) => {
    navigate('' + id);
  };

  return (
    <Container>
      <HeaderNavBar
        label="주문내역"
        leftItem="BACK"
        bgColor={Colors.Background[500]}
        btmLine
      />
      {totalCount === 0 && <ListNotFound>주문 내역이 없습니다</ListNotFound>}
      <List>
        {orders.map((order, index) => (
          <Item key={order.id}>
            <TopSection>
              <OrderNumber>{order.uuid}</OrderNumber>
              <GoToDetail onClick={() => onClickOrder(order.id)}>
                주문상세
              </GoToDetail>
            </TopSection>
            <MiddleSection>
              <ProductName>{order.title}</ProductName>
              <ProductPrice>
                {Utils.convertCurrency(
                  order.order_amount +
                    order.delivery_amount +
                    order.isolated_price,
                )}
                원
              </ProductPrice>
            </MiddleSection>
            <BottomSection>
              <OrderStatus>{ORDER_STATUS_LABELS[order.status]}</OrderStatus>
            </BottomSection>
          </Item>
        ))}
        <MoreView onClick={handlePage} show={totalCount > orders.length} />
      </List>
    </Container>
  );
}

export default OrderListPage;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 100%;
`;

const List = styled.div`
  flex: 1;
  padding-inline: 24px;
  box-sizing: border-box;
  overflow: hidden auto;
`;

const ListNotFound = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: ${Colors.Gray[600]};
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.02em;
`;

const Item = styled.div`
  margin-top: 24px;
  width: 100%;
  padding-top: 14px;
  padding-bottom: 18px;
  border-radius: 6px;
  overflow: hidden;
  box-sizing: border-box;
  background: ${Colors.Gray[700]};
`;

const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
  padding-inline: 17px;
  box-sizing: border-box;
  margin-bottom: 18px;
`;

const OrderNumber = styled.span`
  color: ${Colors.Gray[300]};
  font-size: 15px;
  font-weight: 700;
  line-height: 1.467em;
  letter-spacing: -0.02em;
`;

const GoToDetail = styled.button`
  color: ${Colors.Gray[300]};
  text-align: right;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
  text-underline-offset: 0.13em;
  cursor: pointer;
  user-select: none;
`;

const MiddleSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
  padding-inline: 17px;
  padding-bottom: 15px;
  border-bottom: 1px solid ${Colors.Gray[600]};
  box-sizing: border-box;
  margin-bottom: 17px;
`;

const ProductName = styled.span`
  color: ${Colors.Gray[100]};
  font-size: 18px;
  font-weight: 500;
  line-height: normal;
`;

const ProductPrice = styled.span`
  color: ${Colors.White[500]};
  font-size: 20px;
  font-weight: 700;
  line-height: normal;
`;

const BottomSection = styled.div`
  padding-inline: 17px;
  box-sizing: border-box;
`;

const OrderStatus = styled.span`
  color: ${Colors.Primary[500]};
  font-size: 18px;
  font-weight: 700;
  line-height: normal;
`;
