import ReactDOM from 'react-dom/client';
import TagManager from 'react-gtm-module';
import App from 'App';

import { Provider as AuthProvider } from 'contexts/AuthContext';
import UIInteractionProvider from 'contexts/UIInteractionContext';
import OverlaysProvider from 'contexts/OverlaysContext';

const tagManagerArgs = {
  gtmId: 'GTM-MBT6X9Z',
};

TagManager.initialize(tagManagerArgs);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <AuthProvider>
    <UIInteractionProvider>
      <OverlaysProvider>
        <App />
      </OverlaysProvider>
    </UIInteractionProvider>
  </AuthProvider>,
);
