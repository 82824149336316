import { ReactNode, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import SignButton from 'components/auth/SignButton';
import { ReactComponent as Logo } from 'assets/img/logo.svg';
import { SIGN_TYPE } from 'constants/SignType';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { AxiosError } from 'axios';
import PATH from 'Path';
import { MeResponse, TokenResponse } from 'dto/AuthDto';
import { Context as AuthContext } from 'contexts/AuthContext';
import { UserService } from 'services/UserService';
import { customAxios } from 'services/CustomAxiosLoader';
import { RESULT_CODE } from 'constants/ResultCode';
import useOverlay from 'hook/useOverlay';
import Colors from 'styles/color-system';
import { Utils } from 'utils/Utils';

const SignIn = () => {
  const navigate = useNavigate();

  const { showToast } = useOverlay();

  const [popup, setPopup] = useState<Window | null>();
  const [authorizationUrl, setAuthorizationUrl] = useState<string>();

  const {
    state: { accessToken, artist },
    setArtist,
    updateToken,
  } = useContext(AuthContext);

  const [inApp, setInApp] = useState<boolean>(false);

  useEffect(() => {
    const onSignInKakao = async (event: any) => {
      UserService.signInKakao(event.detail.data).then(({ data }) => {
        const result = data as TokenResponse;
        handleSignIn(result);
        console.log(result);
      });
    };

    const onSignInNaver = async (event: any) => {
      UserService.signInNaver(event.detail.data).then(({ data }) => {
        const result = data as TokenResponse;
        handleSignIn(result);
        console.log(result);
      });
    };

    window.addEventListener('eventSignInKakao', onSignInKakao);
    window.addEventListener('eventSignInNaver', onSignInNaver);

    return () => {
      window.removeEventListener('eventSignInKakao', onSignInKakao);
      window.removeEventListener('eventSignInNaver', onSignInNaver);
    };
  }, []);

  useEffect(() => {
    const inApp = Utils.isInApp();
    setInApp(inApp);

    if (accessToken && artist) {
      if (inApp) {
        navigate(PATH.Home, { replace: true });
        return;
      }

      navigate(-1);
    }
  }, [accessToken, artist]);

  useEffect(() => {
    if (!popup || !authorizationUrl) {
      return;
    }

    popup.location.href = authorizationUrl!;

    const onListener = (e: any) => {
      // if (e.origin !== window.location.origin) {
      //   return;
      // }

      if (e.data === RESULT_CODE.FAIL) {
        showToast('로그인에 실패했습니다.');
        closeRedirectPopup();
      } else if (e.data.access_token) {
        handleSignIn(e.data);
        closeRedirectPopup();
      }
    };

    window.addEventListener('message', onListener, false);

    return () => {
      window.removeEventListener('message', onListener);
    };
  }, [popup, authorizationUrl]);

  const openRedirectPopup = () => {
    const width = 500;
    const height = 400;
    const left = window.screenX + (window.outerWidth - width) / 2;
    const top = window.screenY + (window.outerHeight - height) / 2;
    const popup = window.open(
      undefined,
      '로그인 중...',
      `width=${width},height=${height},left=${left},top=${top}`,
    );
    setPopup(popup);
  };

  const closeRedirectPopup = () => {
    setAuthorizationUrl('');
    popup?.close();
    setPopup(null);
  };

  const handleOAuth = (link: string) => {
    openRedirectPopup();
    setAuthorizationUrl('');

    customAxios
      .get(link)
      .then((response) => {
        const { authorization_url } = response.data;
        setAuthorizationUrl(authorization_url);
      })
      .catch((e: AxiosError) => {
        closeRedirectPopup();
        console.log('log', e);
      });
  };

  const handleSignIn = (token: TokenResponse) => {
    UserService.me(token.access_token).then(({ data }) => {
      const result = data as MeResponse;
      console.log(result);
      if (result.artist) {
        updateToken(token);
        setArtist(result.artist);
      } else {
        navigate(PATH.SignUp, {
          state: {
            token: token,
          },
        });
      }
    });
  };

  const onClickKakao = () => {
    if (inApp) {
      console.log('onClickKakao inapp');
      window.APP_REQUEST.signInKakao();
    } else {
      handleOAuth(
        '/auth/kakao/authorize' + '?callback_url=' + window.location + '/kakao',
      );
    }
  };

  const onClickNaver = () => {
    if (inApp) {
      console.log('onClickNaver inapp');
      window.APP_REQUEST.signInNaver();
    } else {
      handleOAuth(
        '/auth/naver/authorize' + '?callback_url=' + window.location + '/naver',
      );
    }
  };

  // const onClickGoogle = () => {
  //   if (navigator.userAgent.indexOf('KAKAO') >= 0) {
  //     showToast(
  //       '카카오톡에서는 구글 로그인을 할 수 없습니다. \n다른 로그인 방법을 이용해 주세요.',
  //     );
  //     return;
  //   }
  //   handleOAuth('/auth/google/authorize');
  // };

  return (
    <Container>
      <LogoSection>
        <Logo />
        <Text>
          팬과 아티스트가 함께
          <br />
          <Bold>우리</Bold>로 만나는 곳
        </Text>
      </LogoSection>
      <SignInSection>
        <SignButton type={SIGN_TYPE.KAKAO} onClick={onClickKakao} />
        <SignButton type={SIGN_TYPE.NAVER} onClick={onClickNaver} />
        {/* <SignButton type={SIGN_TYPE.GOOGLE} onClick={onClickGoogle} /> */}
      </SignInSection>
    </Container>
  );
};

interface Props {
  api_callback_url: string;
  children: ReactNode;
}

const CallbackOAuth = ({ api_callback_url, children }: Props) => {
  const location = useLocation();

  useEffect(() => {
    customAxios
      .get(api_callback_url + location.search)
      .then(({ data }) => {
        const result = data as TokenResponse;
        console.log(result);
        window.opener.postMessage(result, window.location.origin);
      })
      .catch(({ response }) => {
        console.log(response);
        window.opener.postMessage(RESULT_CODE.FAIL, window.location.origin);
      });
  }, []);

  return <div>{children}</div>;
};

const CallbackKakao = () => {
  return (
    <CallbackOAuth api_callback_url="/auth/kakao/callback">
      Waiting for Kakao Sign-in to complete...
    </CallbackOAuth>
  );
};

const CallbackNaver = () => {
  return (
    <CallbackOAuth api_callback_url="/auth/naver/callback">
      Waiting for Naver Sign-in to complete...
    </CallbackOAuth>
  );
};

const CallbackGoogle = () => {
  return (
    <CallbackOAuth api_callback_url="/auth/google/callback">
      Waiting for Google Sign-in to complete...
    </CallbackOAuth>
  );
};

export default {
  SignIn,
  Redirects: {
    Google: CallbackGoogle,
    Naver: CallbackNaver,
    Kakao: CallbackKakao,
  },
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: linear-gradient(137deg, #4122ff 0%, #932eff 100%);
`;

const LogoSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 26px;
  margin-top: 19.125%;
  margin-bottom: 18.75%;
`;

const Text = styled.span`
  text-align: center;
  color: ${Colors.White[500]};
  font-size: 28px;
  font-weight: 300;
  line-height: normal;
`;

const Bold = styled.span`
  font-weight: 700;
`;

const SignInSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 13px;
`;
