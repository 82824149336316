import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PolicyAgree from 'components/auth/PolicyAgree';
import ArtistChoice from 'components/auth/ArtistChoice';
import SignComplete from 'components/auth/SignComplete';
import { UserService } from 'services/UserService';
import { CommonResponse } from 'dto/CommonDto';
import { RESULT_CODE } from 'constants/ResultCode';
import { Context as AuthContext } from 'contexts/AuthContext';
import SignHeaderNavBar from 'components/auth/SignHeaderNavBar';
import BottomButton from 'components/common/BottomButton';
import styled from 'styled-components';
import { TokenResponse } from 'dto/AuthDto';

type LocationState = {
  token: TokenResponse;
};

function SignUpPage() {
  const navigate = useNavigate();

  const { state: locationState } = useLocation();

  const { setArtist, updateToken } = useContext(AuthContext);

  const [step, setStep] = useState<number>(0);
  const [terms, setTerms] = useState<boolean>(false);
  const [privacy, setPrivacy] = useState<boolean>(false);
  const [marketing, setMarketing] = useState<boolean>(false);
  const [selectedArtist, setSelectedArtist] = useState<number>(0);
  const [ready, setReady] = useState<boolean>(false);

  const onNext = () => {
    setReady(false);
    if (step === 0) {
      setStep(1);
      return;
    }

    if (step === 1) {
      const { token } = locationState as LocationState;

      UserService.completeSignUp(token.access_token, {
        artist: selectedArtist,
        agree: { terms: terms, privacy: privacy, marketing: marketing },
      }).then(({ data }) => {
        const result = data as CommonResponse;
        if (result.result === RESULT_CODE.SUCCESS) {
          setArtist(selectedArtist);
          setStep(2);
          updateToken(token);
        }
      });
      return;
    }

    if (step === 2) {
      navigate(-1);
      return;
    }
  };

  const handleBack = () => {
    if (step > 0) {
      setStep(step - 1);
      return;
    }

    navigate(-1);
  };

  const renderBody = () => {
    if (step === 0) {
      return (
        <PolicyAgree
          onChangeAgreeTerms={setTerms}
          onChangeAgreePrivacy={setPrivacy}
          onChangeAgreeMarketing={setMarketing}
          onReady={setReady}
        />
      );
    }
    if (step === 1) {
      return (
        <ArtistChoice onChangeArtist={setSelectedArtist} onReady={setReady} />
      );
    }
    if (step === 2) {
      return <SignComplete />;
    }
  };

  return (
    <Container>
      <SignHeaderNavBar
        label="회원가입"
        onClick={handleBack}
        inactive={step === 2}
      />
      {renderBody()}
      <BottomButton
        label={step === 2 ? '시작하기' : '계속하기'}
        onClick={onNext}
        state={!ready && step !== 2 ? 'DISABLE' : 'DEFAULT'}
      />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  height: 100%;
`;

export default React.memo(SignUpPage);
