import { useEffect, useState } from 'react';
import styled from 'styled-components';
import ArtistsList from './ArtistsList';
import { ArtistThumbnailDto, RootResponse } from 'dto/CommonDto';
import { ArtistService } from 'services/ArtistService';
import Colors from 'styles/color-system';
import { Utils } from 'utils/Utils';

interface Props {
  onChangeArtist: (artist: number) => void;
  onReady: (value: boolean) => void;
}

function ArtistChoice({ onChangeArtist, onReady }: Props) {
  const [artists, setArtists] = useState<ArtistThumbnailDto[]>([]);

  useEffect(() => {
    ArtistService.root().then((response) => {
      const result = response.data as RootResponse;
      Utils.shuffle(result.artists);
      setArtists(result.artists);
    });
  }, []);

  const onSelectArtist = (id: number) => {
    onChangeArtist(id);
    onReady(true);
  };

  return (
    <ContentBody>
      <Section>
        <Title>팬이 될 아티스트 선택을 해주세요</Title>
        <Description>* 1명의 아티스트 선택을 해주세요</Description>
      </Section>
      <ArtistsList list={artists} onSelectArtist={onSelectArtist} />
    </ContentBody>
  );
}

export default ArtistChoice;

const ContentBody = styled.div`
  flex: 1;
  box-sizing: border-box;
  overflow: hidden auto;
`;

const Section = styled.div`
  width: 100%;
  padding-inline: 24px;
  margin-block: 35px;
`;

const Title = styled.h3`
  color: ${Colors.Gray[100]};
  font-size: 24px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.02em;
  margin-bottom: 12px;
`;

const Description = styled.span`
  color: ${Colors.Primary[500]};
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.02em;
`;
