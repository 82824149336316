import React from 'react';
import styled from 'styled-components';
import HeaderNavBar from 'components/common/HeaderNavBar';
import companyInfo from 'assets/info/company-info';
import Colors from 'styles/color-system';

function CustomerService() {
  return (
    <Container>
      <HeaderNavBar
        label="고객센터"
        leftItem="BACK"
        bgColor={Colors.Background[500]}
        btmLine
      />
      <ContentWrapper>
        <TitleWrapper>
          <Title>
            FANUS 이용에 궁금하거나 불편사항은 고객센터로 연락해 주세요.
          </Title>
          <SmallTitle>고객 응대를 위해 최선을 다하겠습니다.</SmallTitle>
        </TitleWrapper>
        <InfoWrapper>
          <InfoText>
            <Label>전화번호</Label>
            <Value>{companyInfo.CS_TEL}</Value>
          </InfoText>
          <InfoText>
            <Label>이용시간</Label>
            <Value>
              평일 10:00~17:00 <br />
              점심 12:00~13:00
            </Value>
          </InfoText>
        </InfoWrapper>
        <CallButton href={`tel:${companyInfo.CS_TEL}`}>
          전화 상담하기
        </CallButton>
      </ContentWrapper>
    </Container>
  );
}

export default CustomerService;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 100%;
`;

const ContentWrapper = styled.div`
  flex: 1;
  padding-inline: 24px;
  box-sizing: border-box;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 35px;
  margin-bottom: 40px;
`;

const Title = styled.h3`
  color: ${Colors.Gray[100]};
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.02em;
`;

const SmallTitle = styled.span`
  color: ${Colors.Primary[500]};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.02em;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 130px;

  & > div:first-child {
    border-bottom: 1px solid ${Colors.Gray[700]};
  }
`;

const InfoText = styled.div`
  display: flex;
  gap: 10px;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.02em;
  padding-block: 8px;
  box-sizing: border-box;
`;

const Label = styled.span`
  color: ${Colors.Gray[500]};
`;

const Value = styled.span`
  color: ${Colors.Gray[200]};
`;

const CallButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration-line: none;
  width: 100%;
  height: 58px;
  border-radius: 6px;
  background: ${Colors.Primary[500]};
  color: ${Colors.White[500]};
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;
