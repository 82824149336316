import { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import { ArtistService } from 'services/ArtistService';
import PATH from 'Path';
import { Context as AuthContext } from 'contexts/AuthContext';
import { Utils } from 'utils/Utils';
import { KEY_PRODUCT_ID } from 'constants/ProductType';
import useUIInteractionAction from 'hook/useUIInteractionAction';
import { RedirectArtistResponse } from 'dto/AuthDto';

export const RedirectProductPage = () => {
  const { productId } = useParams();
  const navigate = useNavigate();
  const { setArtist } = useContext(AuthContext);

  const { handleTopNavStyles } = useUIInteractionAction();

  useEffect(() => {
    if (productId) {
      if (productId) {
        ArtistService.redirectProduct(productId).then(({ data }) => {
          const result = data as RedirectArtistResponse;
          if (result.id > 0) {
            Utils.saveToSession<string>(KEY_PRODUCT_ID, productId);

            setArtist(result.id);
            handleTopNavStyles(result.color, result.is_white);
            navigate(PATH.Home + '/' + PATH.Shop, { replace: true });
            return;
          }

          navigate(PATH.Root, { replace: true });
        });
      }
    }
    navigate(PATH.Root, { replace: true });
  }, []);

  return <Container></Container>;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: linear-gradient(137deg, #4122ff 0%, #932eff 100%);
`;
