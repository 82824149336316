import { ReactNode, useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import styled, { css } from 'styled-components';
import useOverlay from 'hook/useOverlay';
import BottomButton from 'components/common/BottomButton';
import { ReactComponent as Close } from 'assets/icon/close.svg';
import Colors from 'styles/color-system';

interface Props {
  id?: string;
  display?: string;
  speed?: number;
  content: ReactNode;
  onConfirm: (state: boolean) => void;
}

interface StyledProps {
  duration: number;
}

function PolicyPopup({ id, display, speed, content, onConfirm }: Props) {
  const { hideHandler } = useOverlay();
  const [show, setShow] = useState(false);

  const closeHandler = () => {
    hideHandler(id!);
  };

  const confirmHandler = () => {
    onConfirm(true);
    hideHandler(id!);
  };

  useEffect(() => {
    if (display === 'ON') {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [display]);

  return (
    <CSSTransition in={show} timeout={speed!}>
      <Container duration={speed!}>
        <CLoseBtn onClick={closeHandler} type="button">
          <Close stroke="white" />
        </CLoseBtn>
        <ContentSection>
          <ContentBody>{content}</ContentBody>
        </ContentSection>
        <BottomButton label="동의하기" onClick={confirmHandler} />
      </Container>
    </CSSTransition>
  );
}

export default PolicyPopup;

const Transition = (duration: number) => css`
  transition: ${duration}ms ease-in-out;
  transform: translateY(100%);

  &[class*='enter-'] {
    transform: translateY(0);
  }

  &[class*='exit-'] {
    transform: translateY(100%);
  }
`;

const Container = styled.div<StyledProps>`
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 77.875%;
  border-radius: 20px 20px 0 0;
  background: ${Colors.Gray[700]};
  ${(props) => Transition(props.duration)}
  pointer-events: auto;
`;

const CLoseBtn = styled.button`
  position: absolute;
  top: 11px;
  right: 12px;
  width: 32px;
  height: 32px;
  cursor: pointer;
  user-select: none;
`;

const ContentSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 19px;
  height: calc(100% - 58px);
  padding-top: 55px;
  box-sizing: border-box;
  color: ${Colors.White[500]};
`;

const ContentBody = styled.div`
  flex: 1;
  overflow: hidden;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.467em;
  letter-spacing: -0.02em;
`;
