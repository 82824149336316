import styled, { keyframes } from 'styled-components';
import { ReactComponent as Complete } from 'assets/img/complete.svg';
import Colors from 'styles/color-system';

const Random = () => {
  let num = 0;
  const isNegative = Math.floor(Math.random() * 2);

  if (isNegative === 0) {
    num = Math.floor(Math.random() * -2);
  } else {
    num = Math.floor(Math.random() * 2);
  }
  return num;
};

function SignComplete() {
  return (
    <ContentBody>
      <Title>
        <Bold>회원가입 완료!</Bold>
        <Default>
          이제 팬어스에서 <br />
          아티스트를 우리 함께 만나요
        </Default>
      </Title>
      <Complete />
    </ContentBody>
  );
}

export default SignComplete;

const animation = (x: number, y: number) => keyframes`
  0% {
    transform: translate3d(0, 0, 0);
  }
  50% {
    transform: ${`translate3d(${x}%, ${y}%, 0)`} ;
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
`;

const ContentBody = styled.div`
  flex: 1;
  overflow: hidden auto;

  & svg {
    width: 100%;
    margin-inline: auto;
  }

  & svg .main-item {
    animation: ${animation(Random(), Random())} 5000ms ease-in-out infinite;
  }

  & svg .item-01 {
    animation: ${animation(Random(), Random())} 3600ms ease-in-out infinite;
  }
  & svg .item-02 {
    animation: ${animation(Random(), Random())} 3600ms ease-in-out infinite;
  }
  & svg .item-03 {
    animation: ${animation(Random(), Random())} 3600ms ease-in-out infinite;
  }
  & svg .item-04 {
    animation: ${animation(Random(), Random())} 36z\00ms ease-in-out infinite;
  }
`;

const Title = styled.h3`
  color: ${Colors.Gray[100]};
  font-size: 24px;
  line-height: normal;
  letter-spacing: -0.024em;
  margin-top: 35px;
  margin-bottom: 44px;
  padding-inline: 24px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const Default = styled.span`
  font-weight: 400;
`;

const Bold = styled.span`
  font-weight: 700;
`;
