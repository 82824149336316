import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Context as AuthContext } from 'contexts/AuthContext';
import useUIInteractionAction from 'hook/useUIInteractionAction';
import { useNavigate, useSearchParams } from 'react-router-dom';
import PATH from 'Path';
import { ArtistService } from 'services/ArtistService';
import { HomeResponse } from 'dto/HomeDto';
import { Utils } from 'utils/Utils';
import HeaderNavBar from 'components/common/HeaderNavBar';
import HomeProfile from 'components/home/HomeProfile';
import HomeSchedules from 'components/home/HomeSchedules';
import HomeFeeds from 'components/home/HomeFeeds';
import { ReactComponent as ChevronRight } from 'assets/icon/chevron-right.svg';
import MoreView from 'components/common/MoreView';
import { FeedItemResponse } from 'dto/FeedDto';
import { UserService } from 'services/UserService';
import { CommonResponse } from 'dto/CommonDto';
import { RESULT_CODE } from 'constants/ResultCode';
import BasicPopup from 'components/overlays/BasicPopup';
import useOverlay from 'hook/useOverlay';
import Colors from 'styles/color-system';
import { RedirectArtistResponse } from 'dto/AuthDto';

const LIST_PAGE_LIMIT = 5;
function HomePage() {
  const navigate = useNavigate();
  const {
    state: { artist, accessToken },
    setArtist,
  } = useContext(AuthContext);

  const { showHandler: showOverlay, hideHandler: hideOverlay } = useOverlay();

  const [searchParams] = useSearchParams();

  const { showToast } = useOverlay();

  const [data, setData] = useState<HomeResponse>();
  const [feeds, setFeeds] = useState<FeedItemResponse[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);

  useEffect(() => {
    const artist_key = searchParams.get('artist');
    if (artist_key) {
      ArtistService.redirectArtist(artist_key).then(({ data }) => {
        const result = data as RedirectArtistResponse;
        if (result.id > 0) {
          setArtist(result.id);
          return;
        }

        navigate(PATH.Root, { replace: true });
      });
      return;
    }

    if (!artist) {
      navigate(PATH.Root, { replace: true });
      showToast('아티스트를 선택해야 합니다.');
      return;
    }
  }, []);

  useEffect(() => {
    if (artist) {
      ArtistService.home(artist).then((response) => {
        const result = response.data as HomeResponse;
        setData(result);
        setFeeds(result.feeds);
        setTotalCount(result.total);
      });
    }
  }, [artist]);

  useEffect(() => {
    if (currentPage > 1) {
      ArtistService.more(artist, currentPage, LIST_PAGE_LIMIT).then(
        (response) => {
          const items = response.data as FeedItemResponse[];
          setFeeds([...feeds, ...items]);
        },
      );
    }
  }, [currentPage]);

  const handlePage = () => {
    const totalPage = Math.ceil(totalCount / LIST_PAGE_LIMIT);
    if (currentPage === totalPage) {
      return;
    }

    setCurrentPage(currentPage + 1);
  };

  const handleFeedLike = (
    feedId: number,
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    e.preventDefault();

    if (!accessToken) {
      const popupId = showOverlay(
        <BasicPopup
          message={'로그인이 필요합니다.\n로그인 하시겠습니까?'}
          onLeftClick={() => {
            hideOverlay(popupId);
          }}
          onRightClick={() => {
            hideOverlay(popupId);
            navigate(PATH.SignIn);
          }}
        />,
        'POPUP',
      );
      return;
    }

    const index = feeds.findIndex((feed) => feed.id === feedId);
    const newFeeds = [...feeds];
    const newFeed = newFeeds[index];
    newFeed.me = !newFeed.me;
    newFeed.me ? newFeed.like++ : newFeed.like--;

    UserService.likeFeed(newFeed.id, newFeed.me).then(({ data }) => {
      const result = data as CommonResponse;
      if (result.result === RESULT_CODE.SUCCESS) {
        setFeeds(newFeeds);
      }
    });
  };

  const handleFeedDetail = (
    feedId: number,
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    if (e.defaultPrevented) {
      // 이벤트 버블링 차단
      return;
    }

    navigate(Utils.subNavigationPath(PATH.Feeds, '' + feedId));
  };

  const handleLinkToCafe = (url: string) => {
    const inApp = Utils.isInApp();
    if (inApp) {
      window.location.href = url;
    } else {
      window.open(url, '_blank');
    }
  };

  return (
    <Container>
      <HeaderNavBar leftItem="LOGO" rightItem="CART" />
      <ContentBody>
        <HomeProfile
          image={data?.photo!}
          nameKO={data?.name!}
          nameEN={data?.eng!}
        />
        <CafesWrapper>
          {data?.cafes.map((cafe) => (
            <CafeLink
              key={cafe.url}
              onClick={() => handleLinkToCafe(cafe.url)}
              type="button"
            >
              {cafe.title}
              <ChevronRight stroke={Colors.Gray[100]} />
            </CafeLink>
          ))}
        </CafesWrapper>
        {data?.schedules && <HomeSchedules data={data?.schedules} />}
        {data?.name && (
          <HomeFeeds
            data={feeds}
            name={data?.name!}
            onFeedDetail={handleFeedDetail}
            onFeedLike={handleFeedLike}
          />
        )}
        <MoreView onClick={handlePage} show={totalCount > feeds.length} />
      </ContentBody>
    </Container>
  );
}

export default HomePage;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  background: ${Colors.Background[500]};
`;

const ContentBody = styled.div`
  flex: 1;
  overflow: hidden auto;
  z-index: 10;
`;

const CafesWrapper = styled.div`
  display: flex;
  gap: 6px;
  width: 100%;
  height: 40px;
  margin-block: 20px;
  padding-inline: 12px;
  box-sizing: border-box;
`;

const CafeLink = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding-left: 20px;
  padding-right: 8px;
  box-sizing: border-box;
  border-radius: 20px;
  overflow: hidden;
  background: ${Colors.Gray[700]};
  color: ${Colors.Gray[100]};
  text-decoration: none;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;
