import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import Colors from 'styles/color-system';
import HeaderNavBar from 'components/common/HeaderNavBar';
import Input from 'components/common/Input';
import Select from 'components/common/Select';
import BottomButton from 'components/common/BottomButton';
import { OrderDetailResponse } from 'dto/OrderDto';
import { useLocation } from 'react-router-dom';
import { Utils } from 'utils/Utils';
import { OrderService } from "services/OrderService";
import { BANK, BANK_LABELS } from 'constants/BankCodes';
import { CommonResponse } from "dto/CommonDto";
import { RESULT_CODE } from "constants/ResultCode";
import { useNavigate } from "react-router-dom";

interface propState {
  order: OrderDetailResponse;
}

function OrderCancelPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const [refundReason, setRefundReason] = useState<string>("");

  const [accountBank, setAccountBank] = useState<string>("");
  const [accountName, setAccountName] = useState<string>("");
  const [accountNumber, setAccountNumber] = useState<string>("");
  const [bankList, setBankList] = useState<string[]>([]);

  let { order } = location.state as propState;

  useEffect(() => {
    let selects = [];

    selects = [];
    for (const key in BANK_LABELS) {
      selects.push(BANK_LABELS[key as BANK]);
    }

    setBankList(selects);
  }, []);

  const handleCancel = () => {
    if (order.payment?.method === "가상계좌") {
      if (!accountName || !accountNumber) {
        return;
      }
    }

    OrderService.refund(order.id, {
      reason: refundReason,
      amount: order.order_amount,
      account_bank: accountBank,
      account_name: accountName,
      account_number: accountNumber,
    }).then((response) => {
      const result = response.data as CommonResponse;
      if (result.result === RESULT_CODE.SUCCESS) {
        navigate(-1);
      } else {
        if (result.msg) {
          alert(result.msg);
        } else {
          alert("환불에 실패했습니다.");
        }
      }
      console.log(result);
    });
  };

  return (
    <Container>
      <HeaderNavBar
        label="결제 취소"
        leftItem="BACK"
        bgColor={Colors.Background[500]}
        btmLine
      />
      <ContentSection>
        <CancelList>
          {order &&
            order.items.map((item, index) => (
              <Item key={index}>
                <span className="title">{item.product.title}</span>
                <span className="option">
                  옵션 : {item.option ? item.option.title : ''}
                </span>
                <span className="account-number">수량 : {item.count}개</span>
              </Item>
            ))}
        </CancelList>
        {order.payment?.method === "가상계좌" ? (
          <RefundAccount>
            <SectionLabel>환불 계좌</SectionLabel>
            <div className="content">
              <div className="half">
                <Select
                  placeholder="은행"
                  options={bankList}
                  onChange={(event) => setAccountBank(event!)}
                />
                <Input placeholder="이름" onChange={(event) => setAccountName(event!)} />
              </div>
              <Input placeholder="계좌번호" onChange={(event) => setAccountNumber(event!)} />
              <Input placeholder="취소사유" onChange={(event) => setRefundReason(event!)} />
            </div>
          </RefundAccount>
        ) : (
          <Input placeholder="취소사유" />
        )}
        <RefundAmount>
          <SectionLabel>환불 금액</SectionLabel>
          <div className="content">
            <div className="payment-info">
              <div className="inline">
                <span className="label">총 주문금액</span>
                <span className="value">
                  {Utils.convertCurrency(order?.order_amount)}원
                </span>
              </div>
              <div className="inline">
                <span className="label">총 배송비</span>
                <span className="value">
                  {Utils.convertCurrency(
                    order?.delivery_amount! + order?.isolated_price!,
                  )}
                  원
                </span>
              </div>
            </div>
            <div className="total-payment-info">
              <span className="label">총 환불금액</span>
              <span className="value">
                {Utils.convertCurrency(
                  order?.order_amount +
                  order?.delivery_amount! +
                  order?.isolated_price!,
                )}
                원
              </span>
            </div>
          </div>
        </RefundAmount>
      </ContentSection>
      <BottomButton label="취소 신청" onClick={handleCancel} />
    </Container>
  );
}

export default OrderCancelPage;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  height: 100%;
`;

const ContentSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 33px;
  overflow: hidden auto;
  padding: 31px 24px 38px 24px;
  box-sizing: border-box;
`;

const CancelList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Item = styled.div`
  width: 100%;
  height: 130px;
  display: flex;
  flex-direction: column;
  gap: 7px;
  padding: 18px;
  border-radius: 6px;
  box-sizing: border-box;
  background: ${Colors.Gray[700]};

  & .title {
    width: 100%;
    white-space: normal;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${Colors.Gray[100]};
    font-size: 18px;
    font-weight: 500;
    line-height: normal;
  }

  & :is(.option, .account-number) {
    white-space: nowrap;
    color: ${Colors.Gray[200]};
    font-size: 15px;
    font-weight: 400;
    line-height: normal;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const RefundAccount = styled.div`
  & .content {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  & .half {
    display: flex;
    gap: 10px;
  }

  & .half > div {
    width: calc((100% - 10px) / 2);
  }
`;

const RefundAmount = styled.div`
  & .content {
    width: 100%;
    height: 127px;
    border-radius: 6px;
    box-sizing: border-box;
    background: ${Colors.Gray[700]};
  }

  & :is(.payment-info .inline, .total-payment-info) {
    display: flex;
    justify-content: space-between;
  }

  & .label {
    font-size: 15px;
    font-weight: 400;
    line-height: normal;
  }

  & .value {
    text-align: right;
    font-weight: 700;
    line-height: normal;
  }

  & .payment-info {
    display: flex;
    flex-direction: column;
    gap: 7px;
    padding: 18px 18px 17px;
    border-bottom: 1px solid ${Colors.Gray[600]};
    box-sizing: border-box;
    color: ${Colors.Gray[100]};
  }

  & .payment-info .value {
    font-size: 15px;
  }

  & .total-payment-info {
    margin-top: 11px;
    margin-bottom: 14px;
    padding-inline: 18px;
    box-sizing: border-box;
    color: ${Colors.Primary[500]};
  }

  & .total-payment-info .value {
    font-size: 20px;
  }
`;

const SectionLabel = styled.h3`
  color: ${Colors.Gray[100]};
  font-size: 20px;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 15px;
`;
