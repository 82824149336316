import { useState } from 'react';
import styled from 'styled-components';
import { ArtistThumbnailDto } from 'dto/CommonDto';
import Image from 'components/common/Image';
import Colors from 'styles/color-system';

interface Props {
  list: ArtistThumbnailDto[];
  onSelectArtist: (id: number) => void;
}

interface StyledProps {
  selected: boolean;
}

function ArtistsList({ list, onSelectArtist }: Props) {
  const [artist, setArtist] = useState<number>();

  const onClickHandler = (id: number) => {
    setArtist(id);
    onSelectArtist(id);
  };

  return (
    <Container>
      {list?.map((item) => (
        <Artist key={item.id} onClick={() => onClickHandler(item.id)}>
          <Profile selected={item.id === artist}>
            <ImageWrapper>
              <Image src={item.thumbnail} />
            </ImageWrapper>
          </Profile>
          <Name>{item.name}</Name>
        </Artist>
      ))}
    </Container>
  );
}

export default ArtistsList;

const Container = styled.ul`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 20px;
  row-gap: 32px;
  padding-inline: 32px;
  padding-bottom: 90px;
  box-sizing: border-box;
`;

const Artist = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  width: calc(50% - 10px);
  height: auto;
  cursor: pointer;
  user-select: none;
`;

const Profile = styled.div<StyledProps>`
  position: relative;
  width: 100%;

  &::after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 3px solid ${Colors.Primary[500]};
    border-radius: 50%;
    box-sizing: border-box;
    opacity: ${({ selected }) => (selected ? 1 : 0)};
    transition: opacity 200ms ease-in-out;
  }
`;

const ImageWrapper = styled.div`
  width: calc(100% - 18px);
  height: calc(100% - 18px);
  position: absolute;
  top: 9px;
  left: 9px;
  border-radius: 50%;
  overflow: hidden;
`;

const Name = styled.span`
  color: ${Colors.Gray[100]};
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.02em;
`;
