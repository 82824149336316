import companyInfo from 'assets/info/company-info';
import 'styles/policy.css';

function CommercePolicy() {
  return (
    <div className="container policy">
      <h3 className="h-1 mg-19">개인정보 제공 및 위탁 동의</h3>
      <p className="txt-1 mg-9">
        ㈜팀블루(이하 '회사')는 회원의 개인정보를 중요시하며, '정보통신망
        이용촉진 및 정보보호에 관한 법률' 및 '개인정보보호법'과 '전자상거래
        등에서의 소비자 보호에 관한 법률'을 준수하고 있습니다. 회사는 회원의
        개인정보를 '개인정보의 처리방침'에서 고지한 범위 내에서 사용하며, 회원의
        동의 없이는 이용하거나 원칙적으로 회원의 개인정보를 외부에 제공 또는
        위탁하지 않습니다. 다만, 아래의 경우에는 예외로 합니다.
      </p>
      <p className="txt-1 mg-5">
        - 회원이 사전에 동의한 경우(회원 사전에 동의한 경우란, 서비스 이용 등을
        위하여 자발적으로 자신의 개인정보를 제3자에게 제공하는 것에 동의하는
        것을 의미합니다)
      </p>
      <p className="txt-1 mg-9">
        - 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에
        따라 수사기관의 요구가 있는 경우
      </p>
      <p className="txt-1 mg-24">
        이와 같은 모든 과정에 있어서 회사는 회원의 의사에 반하여 추가적인 정보를
        수집하거나, 동의의 범위를 벗어난 정보를 제3자와 공유하지 않습니다.
      </p>
      <h4 className="h-2 mg-9">1. 개인정보의 제공</h4>
      <p className="txt-1 mg-5">1) 제공받는 자</p>
      <p className="txt-1 mg-5">- 결제 대행사 (㈜토스페이먼츠)</p>
      <p className="txt-1 mg-5">
        - 배송 대행사 ({companyInfo.DELIVERY_COMPANY})
      </p>
      <p className="txt-1 mg-5">
        2) 개인정보를 제공받는 자의 개인정보 이용 목적
      </p>
      <p className="txt-1 mg-5">
        - 결제 대행: 거래승인, 요금정산을 위한 거래정보전송, 수납 등 요금 정산
        관련 업무
      </p>
      <p className="txt-1 mg-5">
        - 배송 대행: 배송승인, 배송처리를 위한 배송정보전송, 배송을 위한 배송
        관련 업무
      </p>
      <p className="txt-1 mg-5">3) 제공하는 개인정보의 항목</p>
      <p className="txt-1 mg-5">
        - 결제 대행: 신용카드정보, 회원의 서비스 거래정보 (상품명, 주문금액,
        결제수단 등)
      </p>
      <p className="txt-1 mg-5">
        - 배송 대행: 배송 주문을 위한 회원 입력 서비스 (상품명, 주문금액,
        주문자명, 휴대폰번호, 배송지 주소 등)
      </p>
      <p className="txt-1 mg-5">
        4) 개인정보를 제공받는 자의 개인정보 보유 및 이용 기간
      </p>
      <p className="txt-1 mg-24">
        해당 사업자가 이미 보유하고 있는 개인정보는 별도로 저장하지 않음. 단,
        법령의 규정에 의한 거래 내역 등을 보관.
      </p>
      <h4 className="h-2 mg-9">2. 개인정보의 취급 위탁</h4>
      <p className="txt-1 mg-24">
        회사는 회원으로부터 수집한 개인정보를 제3자에게 위탁하지 않습니다.
      </p>
      <h4 className="h-2 mg-9">3. 회원의 개인정보의 제공 및 위탁 거부</h4>
      <p className="txt-1">
        회원은 회사의 개인정보 제공 및 위탁 동의를 거부할 수 있습니다. 단,
        동의를 거부하는 경우 서비스 결제 및 배송처리가 정상적으로 완료될 수
        없음을 알려 드립니다.
      </p>
    </div>
  );
}

export default CommercePolicy;
