import React from 'react';
import styled from 'styled-components';
import { Utils } from 'utils/Utils';
import Colors from 'styles/color-system';
import DateUtils from 'utils/DateUtils';
import useOverlay from '../../hook/useOverlay';

interface Props {
  bank: string;
  name: string;
  accountNumber: string;
  date: number;
  showTitle: boolean;
  amount?: number;
  margin: {
    top?: number;
    bottom?: number;
  };
}

interface StyledProps {
  marginTop?: number;
  marginBottom?: number;
}

function OrderPaymentInfoAccount({
  bank,
  name,
  accountNumber,
  amount,
  showTitle,
  date,
  margin,
}: Props) {
  const { showToast } = useOverlay();

  const onClickCopy = () => {
    try {
      window.navigator.clipboard.writeText(
        `입금은행 : ${bank}\n예금주 : ${name}\n계좌번호 : ${accountNumber}\n입금액 : ${Utils.convertCurrency(
          amount,
        )}원`,
      );
      showToast('복사했습니다.');
    } catch (error) {
      console.log('error:', error);
    }
  };

  return (
    <Container marginTop={margin.top} marginBottom={margin.bottom}>
      {showTitle && <Title>입금 정보</Title>}
      <PaymentInfo>
        <PaymentInline>
          <PayLabel>입금은행</PayLabel>
          <PayValue>{bank}</PayValue>
        </PaymentInline>
        <PaymentInline>
          <PayLabel>예금주</PayLabel>
          <PayValue>{name}</PayValue>
        </PaymentInline>
        <PaymentInline>
          <PayLabel>계좌번호</PayLabel>
          <CopyArea onClick={onClickCopy}>
            <PayValue>{accountNumber}</PayValue>
            <CopyText>복사</CopyText>
          </CopyArea>
        </PaymentInline>
        <PaymentInline>
          <PayLabel>입금액</PayLabel>
          <PayValue>{Utils.convertCurrency(amount)}원</PayValue>
        </PaymentInline>
        <PaymentInline>
          <PayLabel>입금기한</PayLabel>
          <PayValue>
            {DateUtils.convertDateToYYMMDDHHmm(
              DateUtils.convertTimeToDate(date),
            )}
          </PayValue>
        </PaymentInline>
      </PaymentInfo>
      <Description>* 기한 내 미입금 시 자동으로 주문이 취소됩니다.</Description>
    </Container>
  );
}

export default OrderPaymentInfoAccount;

const Container = styled.div<StyledProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: ${({ marginTop }) => `${marginTop}px`};
  margin-bottom: ${({ marginBottom }) => `${marginBottom}px`};
`;

const Title = styled.h3`
  color: ${Colors.Gray[200]};
  font-size: 18px;
  font-weight: 700;
  line-height: 1.222em;
  letter-spacing: -0.02em;
`;

const PaymentInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
  padding: 19px 14px 19px 18px;
  border-radius: 6px;
  background: ${Colors.Gray[700]};
  color: ${Colors.Gray[100]};
  box-sizing: border-box;
`;

const PaymentInline = styled.div`
  display: flex;
`;

const PayLabel = styled.span`
  width: 84px;
  font-size: 15px;
  font-weight: 400;
  line-height: normal;
`;

const PayValue = styled.span`
  font-size: 15px;
  font-weight: 700;
  line-height: normal;
`;

const Description = styled.span`
  margin-top: -5px;
  padding-inline: 14px;
  color: ${Colors.Primary[500]};
  font-size: 15px;
  font-weight: 400;
  line-height: normal;
`;

const CopyArea = styled.div`
  flex: 1;
  display: flex;

  cursor: pointer;
  user-select: none;
`;

const CopyText = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  width: 30px;
  height: 19px;
  border-radius: 3px;
  background: ${Colors.Gray[500]};
  color: ${Colors.White[500]};
  font-size: 10px;
  font-weight: 700;
  line-height: normal;
`;
