import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as Triangle } from 'assets/icon/triangle.svg';
import {
  productInfo,
  transactionInfo,
  refundInfo,
} from 'assets/info/shop-policy-info';
import Colors from 'styles/color-system';

interface StyledProps {
  active?: boolean;
  maxHeight?: number;
  duration?: number;
}

interface ChildrenProps {
  label: string;
  content: {
    title?: string;
    content: string[];
  }[];
}

function AccordionElment({ label, content }: ChildrenProps) {
  const [active, setActive] = useState(false);
  const [maxHeight, setMaxHeight] = useState(0);
  const contentRef = useRef<HTMLDivElement>(null);

  const clickHandler = () => {
    setActive(!active);
  };

  useEffect(() => {
    if (contentRef.current) {
      setMaxHeight(contentRef.current?.scrollHeight);
    }
  }, []);
  return (
    <Accordion onClick={clickHandler}>
      <Label>
        <span>{label}</span>
        <Icon active={active}>
          <Triangle />
        </Icon>
      </Label>
      <Content
        ref={contentRef}
        maxHeight={maxHeight}
        duration={maxHeight * 1.3}
        active={active}
      >
        {content.map((item, index) => (
          <ContentInner key={item.title}>
            {item.title && <span className="item-title">{item.title}</span>}
            <div className="item-body">
              {item.content.map((p_item, idx) => (
                <p key={idx} className="item-contents">
                  {p_item}
                </p>
              ))}
            </div>
          </ContentInner>
        ))}
      </Content>
    </Accordion>
  );
}

function ShopDetailBottomSection() {
  return (
    <Container>
      <AccordionElment label="상품정보제공고시" content={productInfo} />
      <AccordionElment label="거래 정보" content={transactionInfo} />
      <AccordionElment label="취소/환불 관련 안내" content={refundInfo} />
    </Container>
  );
}

export default ShopDetailBottomSection;

const Container = styled.div`
  width: 100%;
  padding-inline: 24px;
  padding-top: 36px;
  padding-bottom: 15px;
  box-sizing: border-box;
`;

const Accordion = styled.div`
  width: 100%;
  height: auto;
  overflow: hidden;
`;

const Label = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 53px;
  border-top: 1px solid ${Colors.Gray[700]};
  cursor: pointer;
  user-select: none;

  & > span {
    color: ${Colors.Gray[200]};
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.02em;
  }
`;

const Icon = styled.div<StyledProps>`
  transform: ${({ active }) => `rotate(${active ? 180 : 0}deg)`};
  transition: transform 200ms ease-in-out;
  color: white;
`;

const Content = styled.div<StyledProps>`
  width: 100%;
  max-height: ${(props) => (props.active ? `${props.maxHeight}px` : '0px')};
  transition: ${({ duration }) => `max-height ${duration}ms ease-in-out`};
`;

const ContentInner = styled.div`
  display: block;
  padding-top: 2px;
  padding-bottom: 7px;
  box-sizing: border-box;
  color: ${Colors.Gray[300]};
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.02em;

  & > .item-title {
    display: block;
    font-weight: 700;
    margin-bottom: 5px;
  }

  & > .item-body {
    margin-bottom: 10px;
  }

  & .item-contents {
    display: block;
    margin-bottom: 5px;
    white-space: pre-line;
  }
`;
