import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PATH from 'Path';
import styled from 'styled-components';
import {
  ArtistThumbnailDto,
  BannerSimpleDto,
  RootResponse,
} from 'dto/CommonDto';
import { ArtistService } from 'services/ArtistService';
import HeaderNavBar from 'components/common/HeaderNavBar';
import RootMainBanner from 'components/root/RootMainBanner';
import RootMiniBanner from 'components/root/RootMiniBanner';
import RootArtists from 'components/root/RootArtists';
import RootFooter from 'components/root/RootFooter';
import { Context as AuthContext } from 'contexts/AuthContext';
import useUIInteractionAction from 'hook/useUIInteractionAction';
import Colors from 'styles/color-system';
import { Utils } from 'utils/Utils';

function RootPage() {
  const navigate = useNavigate();

  const { resetTopNavStyles } = useUIInteractionAction();
  const { setArtist, resetArtist } = useContext(AuthContext);

  const [artists, setArtists] = useState<ArtistThumbnailDto[]>([]);
  const [mainBanners, setMainBanners] = useState<BannerSimpleDto[]>([]);
  const [miniBanners, setMiniBanners] = useState<BannerSimpleDto[]>([]);

  useEffect(() => {
    resetArtist();
    resetTopNavStyles();

    ArtistService.root().then((response) => {
      const result = response.data as RootResponse;

      Utils.shuffle(result.artists);
      Utils.shuffle(result.main_banners);
      Utils.shuffle(result.mini_banners);

      setArtists(result.artists);
      setMainBanners(result.main_banners);
      setMiniBanners(result.mini_banners);
      console.log(result);
    });
  }, []);

  const onClickArtist = (artistId: number) => {
    setArtist(artistId);
    navigate(PATH.Home);
  };

  return (
    <Container>
      <HeaderNavBar
        leftItem="FULL_LOGO"
        rightItem="CART"
        bgColor={Colors.Background[500]}
      />
      <ContentBody>
        <RootMainBanner banners={mainBanners} />
        <RootMiniBanner banners={miniBanners} />
        <RootArtists artists={artists} onClick={onClickArtist} />
        <RootFooter />
      </ContentBody>
    </Container>
  );
}

export default RootPage;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  height: 100%;
  background: ${Colors.Background[500]};
`;

const ContentBody = styled.div`
  flex: 1;
  overflow: hidden auto;
`;
