import styled from 'styled-components';
import { ReactComponent as Back } from 'assets/icon/back.svg';
import Colors from 'styles/color-system';

interface Props {
  label?: string;
  onClick: () => void;
  inactive?: boolean;
}

function SignHeaderNavBar({ label, onClick, inactive = false }: Props) {
  return (
    <Container>
      {!inactive && (
        <LeftSection onClick={onClick}>
          <Back fill={Colors.White[500]} />
        </LeftSection>
      )}
      {label && <Label>{label}</Label>}
      <BackGround />
    </Container>
  );
}

export default SignHeaderNavBar;

SignHeaderNavBar.defaultProps = {
  btmLine: false,
  leftItem: 'NONE',
  rightItem: 'NONE',
  label: '',
};

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 68px;
  min-height: 68px;

  border-bottom: 1px solid ${Colors.Gray[600]};
`;

const BackGround = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.15) 0%,
      rgba(0, 0, 0, 0) 60.94%
    ),
    ${Colors.Background[500]};
`;

const Label = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  color: ${Colors.White[500]};
  font-size: 20px;
  font-weight: 700;
  letter-spacing: -0.02em;
  z-index: 100;
`;

const LeftSection = styled.div`
  position: absolute;
  top: 50%;
  left: 16px;
  transform: translate3d(0, -50%, 0);
  z-index: 100;
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
  gap: 8px;
`;
