import { useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components';
import Image from 'components/common/Image';
import { useNavigate } from 'react-router-dom';
import { BannerSimpleDto } from 'dto/CommonDto';

interface Props {
  banners: BannerSimpleDto[];
}

const speed = 15000;

interface StyledProps {
  prevent: boolean;
}

function RootMiniBanner({ banners }: Props) {
  const navigate = useNavigate();
  const [frontNum, setFrontNum] = useState<number>(0);
  const [backNum, setBackNum] = useState<number>(0);
  const [toggle, setToggle] = useState<boolean>(false);

  const nextFrontBanner = () => {
    if (banners.length <= 1) {
      return;
    }

    const nextNum = Math.floor(Math.random() * banners.length);
    if (frontNum === nextNum || backNum === nextNum) {
      nextFrontBanner();
      return;
    }
    setFrontNum(nextNum);
  };

  const nextBackBanner = () => {
    if (banners.length <= 1) {
      return;
    }

    const nextNum = Math.floor(Math.random() * banners.length);
    if (backNum === nextNum || frontNum === nextNum) {
      nextBackBanner();
      return;
    }
    setBackNum(nextNum);
  };

  useEffect(() => {
    const transition = setInterval(() => {
      setToggle((prev) => (prev = !prev));
    }, speed);

    return () => {
      clearInterval(transition);
    };
  }, []);

  const handleClick = (sort: string) => {
    if (sort === 'front') {
      navigate(banners[frontNum].path, {
        state: {
          isBack: true,
        },
      });
    }

    if (sort === 'back') {
      navigate(banners[backNum].path, {
        state: {
          isBack: true,
        },
      });
    }
  };

  if (banners.length === 0) {
    return <></>;
  }

  return (
    <Container>
      <CSSTransition
        in={toggle}
        timeout={600}
        classNames="banner"
        unmountOnExit
        onEnter={nextFrontBanner}
      >
        <ImageWrapper prevent={!toggle} onClick={() => handleClick('front')}>
          <Image src={banners[frontNum].thumbnail} />
        </ImageWrapper>
      </CSSTransition>
      <CSSTransition
        in={!toggle}
        timeout={600}
        classNames="banner"
        unmountOnExit
        onEnter={nextBackBanner}
      >
        <ImageWrapper prevent={toggle} onClick={() => handleClick('back')}>
          <Image src={banners[backNum].thumbnail} />
        </ImageWrapper>
      </CSSTransition>
    </Container>
  );
}

export default RootMiniBanner;

const Container = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 40px;
  cursor: pointer;
  user-select: none;

  &::after {
    content: '';
    display: block;
    padding-bottom: 24.16666%;
  }
`;

const ImageWrapper = styled.div<StyledProps>`
  position: absolute;
  top: 0;
  left: 12px;
  width: calc(100% - 24px);
  height: 100%;
  border-radius: 20px;
  overflow: hidden;
  pointer-events: ${({ prevent }) => prevent && 'none'};

  & img {
    border-radius: 20px;
  }

  &.banner-enter {
    opacity: 0;
  }

  &.banner-enter-active {
    opacity: 1;
    transition: opacity 600ms ease-in-out;
  }

  &.banner-exit {
    opacity: 1;
  }

  &.banner-exit-active {
    opacity: 0;
    transition: opacity 600ms ease-in-out;
  }
`;
