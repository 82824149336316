import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import { ArtistService } from 'services/ArtistService';
import { FeedDetailResponse } from 'dto/FeedDto';
import HeaderNavBar from 'components/common/HeaderNavBar';
import BasicContent from 'components/feed/BasicContent';
import YoutubeContent from 'components/feed/YoutubeContent';

function FeedDetailPage() {
  const navigate = useNavigate();
  const { feedId } = useParams();
  const [feedDetail, setFeedDetail] = useState<FeedDetailResponse>();

  useEffect(() => {
    console.log(feedId);
    if (feedId) {
      ArtistService.feedDetail(parseInt(feedId)).then((response) => {
        const result = response.data as FeedDetailResponse;
        setFeedDetail(result);
        console.log(result);
      });
    } else {
      navigate(-1);
    }

    console.log(feedDetail?.content);
  }, [feedId]);

  return (
    <Container>
      <HeaderNavBar leftItem="BACK" />
      {feedDetail?.type === 'BASIC' && <BasicContent feedData={feedDetail} />}
      {feedDetail?.type === 'YOUTUBE' && (
        <YoutubeContent feedData={feedDetail} />
      )}
    </Container>
  );
}

export default FeedDetailPage;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;
