export enum SCHEDULE_TYPE {
  CONCERT = 'CONCERT', // 공연
  BIRTHDAY = 'BIRTHDAY', // 생일
  LIVE = 'LIVE', // 방송
  FESTIVAL = 'FESTIVAL', // 행사
  RADIO = 'RADIO', // 라디오
  ETC = 'ETC', // 기타
}

export enum SCHEDULE_TYPE_LABELS {
  CONCERT = '공연', // 공연
  BIRTHDAY = '생일', // 생일
  LIVE = '방송', // 방송
  FESTIVAL = '행사', // 행사
  RADIO = '라디오', // 라디오
  ETC = '기타', // 기타
}
