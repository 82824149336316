import React from 'react';
import styled, { css } from 'styled-components';
import { Utils } from 'utils/Utils';
import { OrderInfoDto } from 'dto/OrderDto';
import Colors from 'styles/color-system';

interface Props {
  orderInfo: OrderInfoDto;
  totalCount: number;
}

function OrderRequestOrderInfo({ totalCount, orderInfo }: Props) {
  const deliveryAmount = orderInfo.delivery_amount + orderInfo.isolated_price;

  return (
    <Container>
      <Title>결제정보</Title>
      <CountSection>
        <span className="label">결제할 상품</span>
        <span className="value">총 {totalCount}개</span>
      </CountSection>
      <OrderCostSection>
        <span className="label">총 주문금액</span>
        <span className="value">
          {Utils.convertCurrency(orderInfo.order_amount)}원
        </span>
      </OrderCostSection>
      <DeliverySection>
        <span className="label">총 배송비</span>
        <span className="value">
          {deliveryAmount === 0
            ? '무료'
            : `${Utils.convertCurrency(deliveryAmount)}원`}
        </span>
      </DeliverySection>
      <TotalCostSection>
        <span className="label"> 총 결제 금액</span>
        <span className="value">
          {Utils.convertCurrency(
            orderInfo.order_amount +
              orderInfo.delivery_amount +
              orderInfo.isolated_price,
          )}
          원
        </span>
      </TotalCostSection>
    </Container>
  );
}

export default OrderRequestOrderInfo;

const Container = styled.div`
  width: 100%;
  margin-top: 30px;
  padding-bottom: 29px;
  border-bottom: 1px solid ${Colors.Gray[600]};
  box-sizing: border-box;
`;

const Title = styled.h3`
  color: ${Colors.Gray[100]};
  font-size: 20px;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 26px;
`;

const TextAlign = css`
  display: flex;
  justify-content: space-between;
`;

const LabelStyl = css`
  font-size: 15px;
  font-weight: 400;
  line-height: normal;
`;

const ValueStyle = css`
  text-align: right;
  font-size: 15px;
  font-weight: 700;
  line-height: normal;
`;

const CountSection = styled.div`
  ${TextAlign};
  color: ${Colors.Gray[100]};
  margin-bottom: 7px;

  & > .label {
    ${LabelStyl};
  }

  & > .value {
    ${ValueStyle};
  }
`;

const OrderCostSection = styled.div`
  ${TextAlign};
  color: ${Colors.Gray[100]};
  margin-bottom: 7px;

  & > .label {
    ${LabelStyl};
  }

  & > .value {
    ${ValueStyle};
  }
`;

const DeliverySection = styled.div`
  ${TextAlign};
  color: ${Colors.Gray[100]};
  padding-bottom: 20px;
  border-bottom: 1px solid ${Colors.Gray[200]};

  & > .label {
    ${LabelStyl};
  }

  & > .value {
    ${ValueStyle};
  }
`;

const TotalCostSection = styled.div`
  ${TextAlign};
  color: ${Colors.Primary[500]};
  margin-top: 15px;

  & > .label {
    ${LabelStyl};
  }

  & > .value {
    text-align: right;
    font-size: 20px;
    font-weight: 700;
    line-height: normal;
  }
`;
