import React, { ReactNode, useState, useEffect } from 'react';
import styled from 'styled-components';
import CheckBox from 'components/common/CheckBox';
import PolicyPopup from 'components/overlays/PolicyPopup';
import useOverlay from 'hook/useOverlay';
import CommercePolicy from 'components/policy/CommercePolicy';
import Colors from 'styles/color-system';

interface Props {
  setOrderPolicy: React.Dispatch<React.SetStateAction<boolean>>;
}

function OrderRequestPolicy({ setOrderPolicy }: Props) {
  const { showHandler } = useOverlay();
  const [chk01, setChk01] = useState<boolean>(false);
  const [chk02, setChk02] = useState<boolean>(false);

  useEffect(() => {
    if (chk01 && chk02) {
      setOrderPolicy(true);
    } else {
      setOrderPolicy(false);
    }
  }, [chk01, chk02]);

  const onPopupHandler = (content: ReactNode) => {
    showHandler(
      <PolicyPopup content={content} onConfirm={(val) => setChk02(val)} />,
      'POPUP',
    );
  };

  return (
    <Container>
      <CheckBox value={chk01} onChange={(val) => setChk01(val)}>
        <Label>주문정보 확인 및 구매계약 체결 동의</Label>
      </CheckBox>
      <CheckBox value={chk02} onChange={(val) => setChk02(val)} contact={false}>
        <Label
          className="link"
          onClick={() => onPopupHandler(<CommercePolicy />)}
        >
          개인정보 제공 및 위탁 동의
        </Label>
      </CheckBox>
    </Container>
  );
}

export default OrderRequestPolicy;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  margin-block: 30px;
`;

const Label = styled.span`
  color: ${Colors.Gray[100]};
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.36px;

  &.link {
    letter-spacing: -0.02em;
    text-decoration-line: underline;
    text-underline-offset: 0.25em;
  }
`;
