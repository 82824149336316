import { Fragment } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { Outlet } from 'react-router-dom';
import useUIInteractionValue from 'hook/useUIInteractionValue';
import BottomNavBar from 'components/common/BottomNavBar';

interface StyleProps {
  paddingBtm: boolean;
}

function MainRoute() {
  const portalElement = document.getElementById('overlays');
  const { hideBtmNavBar } = useUIInteractionValue();

  return (
    <Fragment>
      <Outer paddingBtm={!hideBtmNavBar}>
        <Outlet />
      </Outer>
      {ReactDOM.createPortal(<BottomNavBar />, portalElement as HTMLElement)}
    </Fragment>
  );
}

export default MainRoute;

const Outer = styled.div<StyleProps>`
  position: relative;
  display: flex;
  padding-bottom: ${({ paddingBtm }) => (paddingBtm ? '70px' : 'initial')};
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;
