import { useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import styled, { css } from 'styled-components';
import Colors from 'styles/color-system';

interface Props {
  message: string;
  onLeftClick: () => void;
  leftLabel?: string;
  onRightClick: () => void;
  rightLabel?: string;
  display?: string;
  speed?: number;
}

interface StyledProps {
  duration: number;
}

function BasicPopup({
  message,
  onLeftClick,
  leftLabel = '아니오',
  onRightClick,
  rightLabel = '예',
  display,
  speed,
}: Props) {
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (display === 'ON') {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [display]);

  return (
    <CSSTransition in={show} timeout={speed!}>
      <Container duration={speed!}>
        <Content>{message}</Content>
        <ButtonWrapper>
          <Reject onClick={onLeftClick}>{leftLabel}</Reject>
          <Confirm onClick={onRightClick}>{rightLabel}</Confirm>
        </ButtonWrapper>
      </Container>
    </CSSTransition>
  );
}

export default BasicPopup;

const Transition = (duration: number) => css`
  transition: opacity ${duration}ms ease-in-out;
  opacity: 0;

  &[class*='enter-'] {
    opacity: 1;
  }

  &[class*='exit-'] {
    opacity: 0;
  }
`;

const Container = styled.div<StyledProps>`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  width: 86.6666%;
  height: fit-content;
  min-height: 230px;
  padding-inline: 12px;
  padding-block: 33px;
  border-radius: 6px;
  box-sizing: border-box;
  background: ${Colors.Background[500]};
  column-gap: 35px;
  display: flex;
  flex-direction: column;
  ${(props) => Transition(props.duration)}
  pointer-events: auto;
`;

const Content = styled.span`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${Colors.White[500]};
  font-size: 24px;
  font-weight: 400;
  line-height: normal;
  white-space: pre-line;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 35px;
  justify-content: center;
`;

const Button = styled.button.attrs({ type: 'button' })`
  width: 120px;
  height: 24px;
  cursor: pointer;
  user-select: none;
  text-align: center;
  font-size: 20px;
  line-height: normal;
`;

const Reject = styled(Button)`
  color: ${Colors.Gray[500]};
  font-weight: 500;
`;

const Confirm = styled(Button)`
  color: ${Colors.Primary[500]};
  font-weight: 700;
`;
