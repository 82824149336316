import { useEffect } from 'react';

function useProtection() {
  useEffect(() => {
    console.log('onProtection');

    const unContextmenu = (e: MouseEvent) => {
      e.preventDefault();
    };

    const unDevtools = (e: KeyboardEvent) => {
      if (e.key === 'F12') {
        e.preventDefault();
      }
    };

    window.addEventListener('contextmenu', unContextmenu);
    window.addEventListener('keydown', unDevtools);
    return () => {
      console.log('unProtection');
      window.removeEventListener('contextmenu', unContextmenu);
      window.removeEventListener('keydown', unDevtools);
    };
  }, []);
}

export default useProtection;
