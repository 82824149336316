import React from 'react';
import styled, { css } from 'styled-components';
import { PAYMENT_TYPE } from 'constants/PaymentType';
import Colors from 'styles/color-system';

interface Props {
  paymentType?: PAYMENT_TYPE;
  onChangePaymentType: (value: PAYMENT_TYPE) => void;
}

interface StyledProps {
  active: boolean;
}

function OrderRequestPaymentMethod({
  paymentType,
  onChangePaymentType,
}: Props) {
  const paymentHandler = (type: PAYMENT_TYPE) => {
    if (type === paymentType) {
      return;
    }

    onChangePaymentType(type);
  };

  return (
    <Container>
      <Title>결제수단</Title>
      <ButtonSection>
        <Button
          onClick={() => paymentHandler(PAYMENT_TYPE.CARD)}
          active={paymentType === PAYMENT_TYPE.CARD}
        >
          신용카드
        </Button>
        {/* <Button
          onClick={() => paymentHandler(PAYMENT_TYPE.TRANSFER)}
          active={paymentType === PAYMENT_TYPE.TRANSFER}
        >
          계좌이체
        </Button> */}
        <Button
          onClick={() => paymentHandler(PAYMENT_TYPE.VIRTUAL)}
          active={paymentType === PAYMENT_TYPE.VIRTUAL}
        >
          가상계좌(무통장입금)
        </Button>
        {/* <Button
          onClick={() => paymentHandler(PAYMENT_TYPE.PHONE)}
          active={paymentType === PAYMENT_TYPE.PHONE}
        >
          휴대폰결제
        </Button> */}
      </ButtonSection>
    </Container>
  );
}

export default OrderRequestPaymentMethod;

const Container = styled.div`
  margin-top: 30px;
  padding-bottom: 29px;
  border-bottom: 1px solid ${Colors.Gray[600]};
  box-sizing: border-box;
`;

const Title = styled.div`
  color: ${Colors.Gray[100]};
  font-size: 20px;
  font-weight: 700;
  line-height: normal;
`;

const ButtonSection = styled.div`
  width: 100%;
  display: flex;
  gap: 8px;
  margin-top: 17px;
`;

const Button = styled.button<StyledProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  /* flex: 1; */
  /* 휴대폰 결제 숨기기, 임시로 width 조정 */
  width: 50%;
  height: 46px;
  border-radius: 6px;
  border: 1px solid transparent;
  background: ${Colors.Gray[700]};
  color: ${Colors.Gray[200]};
  font-size: 15px;
  font-weight: 400;
  line-height: normal;
  transition: 200ms ease-in-out;
  transition-property: color, border-color;
  cursor: pointer;
  user-select: none;

  ${({ active }) =>
    active &&
    css`
      color: ${Colors.Primary[500]};
      border-color: ${Colors.Primary[500]};
    `}
`;
