export enum BANK {
  BANK_01 = "BANK_01",
  BANK_02 = "BANK_02",
  BANK_03 = "BANK_03",
  BANK_04 = "BANK_04",
  BANK_05 = "BANK_05",
  BANK_06 = "BANK_06",
  BANK_07 = "BANK_07",
  BANK_08 = "BANK_08",
  BANK_09 = "BANK_09",
  BANK_10 = "BANK_10",
  BANK_11 = "BANK_11",
  BANK_12 = "BANK_12",
  BANK_13 = "BANK_13",
  BANK_14 = "BANK_14",
  BANK_15 = "BANK_15",
  BANK_16 = "BANK_16",
  BANK_17 = "BANK_17",
  BANK_18 = "BANK_18",
  BANK_19 = "BANK_19",
  BANK_20 = "BANK_20",
  BANK_21 = "BANK_21",
  BANK_22 = "BANK_22",
  BANK_23 = "BANK_23",
  BANK_24 = "BANK_24",
  BANK_25 = "BANK_25",
  BANK_26 = "BANK_26",
}

export enum BANK_CODE {
  BANK_01 = "39",
  BANK_02 = "34",
  BANK_03 = "12",
  BANK_04 = "32",
  BANK_05 = "45",
  BANK_06 = "64",
  BANK_07 = "88",
  BANK_08 = "48",
  BANK_09 = "27",
  BANK_10 = "20",
  BANK_11 = "71",
  BANK_12 = "50",
  BANK_13 = "37",
  BANK_14 = "35",
  BANK_15 = "90",
  BANK_16 = "89",
  BANK_17 = "92",
  BANK_18 = "81",
  BANK_19 = "54",
  BANK_20 = "03",
  BANK_21 = "06",
  BANK_22 = "31",
  BANK_23 = "02",
  BANK_24 = "11",
  BANK_25 = "23",
  BANK_26 = "07",
}

export enum BANK_LABELS {
  BANK_01 = "경남은행",
  BANK_02 = "광주은행",
  BANK_03 = "단위농협(지역농축협)",
  BANK_04 = "부산은행",
  BANK_05 = "새마을금고",
  BANK_06 = "산림조합",
  BANK_07 = "신한은행",
  BANK_08 = "신협",
  BANK_09 = "씨티은행",
  BANK_10 = "우리은행",
  BANK_11 = "우체국예금보험",
  BANK_12 = "저축은행중앙회",
  BANK_13 = "전북은행",
  BANK_14 = "제주은행",
  BANK_15 = "카카오뱅크",
  BANK_16 = "케이뱅크",
  BANK_17 = "토스뱅크",
  BANK_18 = "하나은행",
  BANK_19 = "홍콩상하이은행",
  BANK_20 = "IBK기업은행",
  BANK_21 = "KB국민은행",
  BANK_22 = "DGB대구은행",
  BANK_23 = "KDB산업은행",
  BANK_24 = "NH농협은행",
  BANK_25 = "SC제일은행",
  BANK_26 = "Sh수협은행",
}