import styled, { keyframes } from 'styled-components';
import { FeedItemResponse } from 'dto/FeedDto';
import Image from 'components/common/Image';
import { ReactComponent as LikeIcon } from 'assets/icon/like.svg';
import Colors from 'styles/color-system';

interface Props {
  data: FeedItemResponse;
  onFeedLike: (
    id: number,
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => void;
}

interface StyledProps {
  isLike?: boolean;
}

function HomeFeedCardYoutube({ data, onFeedLike }: Props) {
  return (
    <Container>
      <Thumbnail>
        <Image src={data.thumbnail} />
      </Thumbnail>
      <TextWrapper>
        <Type>유튜브</Type>
        <Title>{data.title}</Title>
        <Like isLike={data.me} onClick={(e) => onFeedLike(data.id, e)}>
          <LikeIcon />
          {data.like.toLocaleString('ko-KR')}
        </Like>
      </TextWrapper>
    </Container>
  );
}

export default HomeFeedCardYoutube;

const Container = styled.div`
  position: relative;
  width: 100%;
  border-radius: 20px;
  overflow: hidden;

  &::after {
    content: '';
    display: block;
    padding-bottom: 96.42857%;
  }
`;

const Thumbnail = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: calc(100% - 130px);
`;

const TextWrapper = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 130px;

  padding: 20px 20px 24px;
  box-sizing: border-box;
  background: ${Colors.Gray[700]};
  display: flex;
  flex-direction: column;
`;

const Type = styled.span`
  color: ${Colors.Primary[500]};
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 8px;
`;

const Title = styled.span`
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: ${Colors.Gray[200]};
  font-size: 20px;
  font-weight: 400;
  line-height: 1.3em;
  letter-spacing: -0.02em;
  margin-bottom: 15px;
`;

const LikeMotion = keyframes`
0%{
  fill: transparent;
  stroke: ${Colors.Gray[200]};
  transform: scale(1);
}
50% {
  transform: scale(1.35);
}
100%{
  transform: scale(1);
  fill: ${Colors.Gray[200]};
  stroke: ${Colors.Gray[200]};
}
`;

const UnlikeMotion = keyframes`
0%{
  transform: scale(1);
  fill: ${Colors.Gray[200]};
  stroke: ${Colors.Gray[200]};
}
50% {
  transform: scale(0.7);
}
100%{
  transform: scale(1);
  fill: transparent;
  stroke: ${Colors.Gray[200]};
}
`;

const Like = styled.div<StyledProps>`
  position: relative;
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 4px;
  & svg {
    animation: ${({ isLike }) => (isLike ? LikeMotion : UnlikeMotion)} 250ms
      ease-in-out forwards;
  }
  color: ${Colors.Gray[200]};
  font-size: 15px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.02em;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: scale(2);
    opacity: 0.4;
  }
`;
