import { useContext } from 'react';
import { UIInteractionValueContext } from 'contexts/UIInteractionContext';

function useUIInteractionValue() {
  const value = useContext(UIInteractionValueContext);

  if (value === undefined) {
    throw new Error(
      'UIInteractionValue should be used within UIInteractionProvider',
    );
  }
  return value;
}

export default useUIInteractionValue;
