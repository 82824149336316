import styled from 'styled-components';
import HeaderNavBar from 'components/common/HeaderNavBar';
import PrivacyPolicy from 'components/policy/PrivacyPolicy';
import Colors from 'styles/color-system';

function PrivacyPage() {
  return (
    <Container>
      <HeaderNavBar
        leftItem="BACK"
        label="개인정보 처리방침"
        bgColor={Colors.Background[500]}
        btmLine
      />
      <PrivacyPolicy />
    </Container>
  );
}

export default PrivacyPage;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  height: 100%;
  background: ${Colors.Background[500]};

  & > .policy {
    padding-top: 35px;
  }
`;
