import { useContext } from 'react';
import { OverlaysAction } from 'contexts/OverlaysContext';

function useOverlay() {
  const value = useContext(OverlaysAction);

  if (value === undefined) {
    throw new Error('useOverlay should be used within OverlaysProvider');
  }
  return value;
}

export default useOverlay;
