import { useEffect } from 'react';
import styled from 'styled-components';
import DateUtils from 'utils/DateUtils';
import { FeedDetailResponse } from 'dto/FeedDto';
import ReactPlayer from 'react-player';
import Colors from 'styles/color-system';

interface Props {
  feedData: FeedDetailResponse;
}

function YoutubeContent({ feedData }: Props) {
  const created = DateUtils.convertDateToYYMMDD(
    DateUtils.convertTimeToDate(feedData?.created!),
  );

  const config = {
    youtube: {
      embedOptions: {
        host: 'https://www.youtube-nocookie.com',
      },
      playerVars: {
        controls: 1,
      },
    },
  };

  useEffect(() => {
    document.cookie = 'safeCookie1=foo; SameSite=Lax';
    document.cookie = 'crossCookie=bar; SameSite=None; Secure';
  }, []);

  return (
    <Container>
      <PlayerWrapper>
        <ReactPlayer
          url={feedData.link}
          width="100%"
          height="100%"
          config={config}
        />
      </PlayerWrapper>
      <ContentWrapper>
        <Title>{feedData.title}</Title>
        <Date>{created}</Date>
        <Content dangerouslySetInnerHTML={{ __html: feedData.content }} />
      </ContentWrapper>
    </Container>
  );
}

export default YoutubeContent;

const Container = styled.div`
  flex: 1;
  overflow: hidden auto;
`;

const PlayerWrapper = styled.div`
  position: relative;
  width: 100%;
  aspect-ratio: 16/9;
  background: var(--skeleton-color);
`;

const ContentWrapper = styled.div`
  margin-top: 24px;
  padding-inline: 24px;
  box-sizing: border-box;
`;

const Title = styled.span`
  display: block;
  color: ${Colors.Gray[200]};
  font-size: 20px;
  font-weight: 400;
  line-height: 1.3em;
  letter-spacing: -0.02em;
  padding-bottom: 27px;
  border-bottom: 1px solid ${Colors.Gray[700]};
`;

const Date = styled.span`
  display: block;
  color: ${Colors.Gray[500]};
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.733em;
  letter-spacing: -0.02em;
  margin-top: 15px;
  margin-bottom: 8px;
`;

const Content = styled.div`
  color: ${Colors.Gray[500]};
  font-size: 20px;
  font-weight: 400;
  line-height: 1.3em;
  letter-spacing: -0.02em;
  padding-bottom: 40px;
  box-sizing: border-box;
`;
