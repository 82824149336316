import { useContext } from 'react';
import { UIInteractionActionContext } from 'contexts/UIInteractionContext';

function useUIInteractionAction() {
  const value = useContext(UIInteractionActionContext);

  if (value === undefined) {
    throw new Error(
      'useUIInteractionAction should be used within useUIInteractionProvider',
    );
  }
  return value;
}

export default useUIInteractionAction;
