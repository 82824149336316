import { Fragment, useState } from 'react';
import styled, { css } from 'styled-components';

interface Props {
  src: string;
}
function Image({ src }: Props) {
  const [error, setError] = useState<boolean>(false);

  const handleError = () => {
    setError(true);
  };

  return (
    <Fragment>
      {!error && src ? (
        <Content src={src} loading="lazy" onError={handleError} />
      ) : (
        <Skeleton />
      )}
    </Fragment>
  );
}

export default Image;

const Layout = css`
  width: 100%;
  height: 100%;
`;

const Content = styled.img`
  display: block;
  object-fit: cover;
  pointer-events: none;
  ${Layout};
`;

const Skeleton = styled.div`
  ${Layout};
  background: var(--skeleton-color);
`;
