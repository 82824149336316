import styled from 'styled-components';
import { CartItemDto } from 'dto/CartDto';
import Colors from 'styles/color-system';

interface Props {
  items: CartItemDto[];
}

function OrderRequestOrderItemList({ items }: Props) {
  const renderTitle = (item: CartItemDto) => {
    if (item.option) {
      return (
        <ItemTitle>{item.product.title + ' ' + item.option.title}</ItemTitle>
      );
    }
    return <ItemTitle>{item.product.title}</ItemTitle>;
  };
  return (
    <Container>
      <Title>주문상품</Title>
      <List>
        {items.map((item) => (
          <Item key={item.id}>{renderTitle(item)}</Item>
        ))}
      </List>
    </Container>
  );
}

export default OrderRequestOrderItemList;

const Container = styled.div`
  width: 100%;
  margin-top: 30px;
  padding-bottom: 29px;
  border-bottom: 1px solid ${Colors.Gray[600]};
  box-sizing: border-box;
`;

const Title = styled.h3`
  color: ${Colors.Gray[100]};
  font-size: 20px;
  font-weight: 700;
  line-height: normal;
`;

const List = styled.ul`
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Item = styled.li`
  padding: 16px 18px;
  background: ${Colors.Gray[700]};
  border-radius: 6px;
  box-sizing: border-box;
`;

const ItemTitle = styled.span`
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  color: ${Colors.Gray[100]};
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;
