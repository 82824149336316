import styled from 'styled-components';
import DateUtils from 'utils/DateUtils';
import { FeedDetailResponse } from 'dto/FeedDto';
import Colors from 'styles/color-system';

interface Props {
  feedData: FeedDetailResponse;
}

function BasicContent({ feedData }: Props) {
  const created = DateUtils.convertDateToYYMMDDHHmm(
    DateUtils.convertTimeToDate(feedData?.created!),
  );
  return (
    <Container>
      <ContentWrapper>
        <Content dangerouslySetInnerHTML={{ __html: feedData.content }} />
        <Date>{created} 작성</Date>
      </ContentWrapper>
    </Container>
  );
}

export default BasicContent;

const Container = styled.div`
  flex: 1;
  overflow: hidden auto;
`;

const ContentWrapper = styled.div`
  margin-top: 18px;
  margin-bottom: 40px;
  padding-inline: 24px;
  box-sizing: border-box;
`;

const Content = styled.div`
  width: 100%;
  color: ${Colors.Gray[200]};
  font-size: 20px;
  font-weight: 400;
  line-height: 1.3em;
  letter-spacing: -0.02em;

  & img {
    display: block;
    width: 100%;
    object-fit: cover;
    pointer-events: none;
    background: var(--skeleton-color);
  }
`;
const Date = styled.span`
  display: block;
  text-align: right;
  color: ${Colors.Gray[500]};
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.733em;
  letter-spacing: -0.02em;
  margin-top: 30px;
`;
