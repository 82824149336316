import styled from 'styled-components';
import { NoticeItemResponse } from 'dto/NoticeDto';
import DateUtils from 'utils/DateUtils';
import Colors from 'styles/color-system';

interface Props {
  data: NoticeItemResponse;
  onClick: (noticeId: number) => void;
}

function BasicCard({ data, onClick }: Props) {
  const createdDate = DateUtils.convertDateToYYMMDD(
    DateUtils.convertTimeToDate(data.created),
  );

  return (
    <Container onClick={() => onClick(data.id)}>
      <Content>
        <Title>{data.title}</Title>
        <Date>{createdDate}</Date>
      </Content>
    </Container>
  );
}

export default BasicCard;

const Container = styled.div`
  position: relative;
  width: 100%;
  padding-inline: 24px;
  padding-block: 18px;
  box-sizing: border-box;
  cursor: pointer;
  user-select: none;

  &::after {
    content: '';
    position: absolute;
    left: 12px;
    bottom: 0;
    width: calc(100% - 24px);
    height: 1px;
    background: ${Colors.Gray[700]};
  }
`;

const Content = styled.div``;

const Title = styled.p`
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${Colors.Gray[200]};
  font-size: 20px;
  font-weight: 400;
  line-height: 1.3em;
  letter-spacing: -0.02em;
  margin-bottom: 6px;
`;

const Date = styled.span`
  display: block;
  text-align: right;
  color: ${Colors.Gray[500]};
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.73333em;
  letter-spacing: -0.02em;
`;
