import {
  ReactNode,
  createContext,
  useState,
  useMemo,
  useCallback,
} from 'react';
import { nanoid } from 'nanoid';
import RenderOverlay from 'components/overlays/RenderOverlay';
import {
  OverlayContextType,
  OverlayItem,
  OverlayElement,
  OverlayType,
} from 'types/overlay-types';
import Progress from 'components/overlays/Progress';
import Toast from 'components/overlays/Toast';

interface Props {
  children: ReactNode;
}

export const OverlaysAction = createContext<OverlayContextType | undefined>(
  undefined,
);

const speed = 300;

function OverlaysContext({ children }: Props) {
  const [overlayList, setOverlayList] = useState<OverlayItem[]>([]);

  const addItemHandler = useCallback(
    (element: OverlayElement, type: OverlayType) => {
      const id: string = nanoid();

      setOverlayList((prev: OverlayItem[]) => [
        ...prev,
        { id, element, type, display: 'ON', speed },
      ]);

      return id;
    },
    [],
  );

  const removeItemHandler = useCallback((targetId: string) => {
    console.log('removeItemHandler id : ' + targetId);
    const remove = () => {
      setOverlayList((prev: OverlayItem[]) =>
        prev.filter(({ id }: OverlayItem) => id !== targetId),
      );
    };

    setOverlayList((prev: OverlayItem[]) =>
      prev.map((prev) =>
        prev.id === targetId ? { ...prev, display: 'OFF' } : prev,
      ),
    );

    setTimeout(() => remove(), speed);
  }, []);

  const removeProgressHandler = useCallback(() => {
    const remove = () => {
      setOverlayList((prev: OverlayItem[]) =>
        prev.filter(({ type }: OverlayItem) => type !== 'PROGRESS'),
      );
    };

    setOverlayList((prev: OverlayItem[]) =>
      prev.map((prev) =>
        prev.type === 'PROGRESS' ? { ...prev, display: 'OFF' } : prev,
      ),
    );

    setTimeout(() => remove(), speed);
  }, []);

  const action = useMemo(() => {
    return {
      showHandler: (element: OverlayElement, type: OverlayType) =>
        addItemHandler(element, type),
      hideHandler: (id: string) => removeItemHandler(id),
      showProgress: () => addItemHandler(<Progress />, 'PROGRESS'),
      hideProgress: () => removeProgressHandler(),
      showToast: (message: string) =>
        addItemHandler(<Toast message={message} />, 'TOAST'),
    };
  }, [addItemHandler, removeItemHandler]);

  return (
    <OverlaysAction.Provider value={action}>
      <RenderOverlay items={overlayList} />
      {children}
    </OverlaysAction.Provider>
  );
}

export default OverlaysContext;
