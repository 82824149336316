import { useEffect, useState } from 'react';
import styled from 'styled-components';
import Colors from 'styles/color-system';

interface Props {
  message: string;
}

interface StyledProps {
  show: boolean;
}

function ItemNotFound({ message }: Props) {
  const [show, setShow] = useState<boolean>(false);
  useEffect(() => {
    setShow(true);

    return () => {
      setShow(false);
    };
  }, []);
  return (
    <Container show={show}>
      <Content>{message}</Content>
    </Container>
  );
}

export default ItemNotFound;

const Container = styled.div<StyledProps>`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 100ms ease-in-out 100ms;
  opacity: ${({ show }) => (show ? '1' : '0')};
`;

const Content = styled.span`
  color: ${Colors.Gray[600]};
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.02em;
`;
