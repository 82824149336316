import styled from 'styled-components';
import Counter from 'components/common/Counter';
import { Utils } from 'utils/Utils';
import { ReactComponent as Close } from 'assets/icon/close.svg';
import { SelectedOptionItemDto } from 'dto/CartDto';
import Colors from 'styles/color-system';

interface Props {
  item: SelectedOptionItemDto;
  onUpdate: (option: SelectedOptionItemDto) => void;
  onCancel: (option: SelectedOptionItemDto) => void;
}

interface StyledProps {
  marginBtm?: string;
}

function BottomDrawerSelectedItem({ item, onUpdate, onCancel }: Props) {
  return (
    <Container>
      <OptionSection marginBtm="18px">
        <OptionTitle>{item.title}</OptionTitle>
        <OptionCancel onClick={() => onCancel(item)}>
          <Close />
        </OptionCancel>
      </OptionSection>
      <OptionSection marginBtm="16px">
        <Counter
          value={item.count}
          onChange={(val) => {
            let temp = { ...item };
            temp.count = val;
            onUpdate(temp);
          }}
        />
        <OptionPrice>
          {Utils.convertCurrency(item.amount * item.count)}원
        </OptionPrice>
      </OptionSection>
    </Container>
  );
}

export default BottomDrawerSelectedItem;

const Container = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  border-radius: 6px;
  padding: 17px;
  box-sizing: border-box;
  background: ${Colors.Gray[700]};
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

const OptionSection = styled.div<StyledProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${({ marginBtm }) => marginBtm};
`;

const OptionTitle = styled.span`
  width: calc(100% - 30px);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: ${Colors.Gray[100]};
  font-size: 15px;
  font-weight: 500;
  line-height: normal;
`;

const OptionCancel = styled.div`
  width: 18px;
  height: 18px;
  cursor: pointer;
  user-select: none;

  & svg {
    stroke: ${Colors.Gray[100]};
    width: 100%;
    height: 100%;
  }
`;

const OptionPrice = styled.span`
  color: ${Colors.White[500]};
  text-align: right;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
`;
