import { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import PageTransition from './components/common/PageTransition';
import PATH from './Path';
import RootPage from 'pages/root/RootPage';
import HomePage from 'pages/home/HomePage';
import SignInPage from 'pages/auth/SignInPage';
import SignUpPage from 'pages/auth/SignUpPage';
import CartPage from 'pages/cart/CartPage';
import FeedDetailPage from 'pages/feed/FeedDetailPage';
import NoticeListPage from 'pages/notice/NoticeListPage';
import NoticeDetailPage from 'pages/notice/NoticeDetailPage';
import OrderListPage from 'pages/order/OrderListPage';
import OrderDetailPage from 'pages/order/OrderDetailPage';
import OrderRequestPage from 'pages/order/OrderRequestPage';
import OrderCompletePage from 'pages/order/OrderCompletePage';
import OrderCancelPage from 'pages/order/OrderCancelPage';
import SettingPage from 'pages/setting/SettingPage';
import CustomerService from 'pages/setting/CustomerService';
import ArtistChangePage from 'pages/setting/ArtistChangePage';
import ScheduleListPage from 'pages/schedule/ScheduleListPage';
import ShopListPage from 'pages/shop/ShopListPage';
import ShopDetailPage from 'pages/shop/ShopDetailPage';
import ErrorPage from 'pages/error/ErrorPage';
import PrivacyPage from 'pages/policy/PrivacyPage';
import TermsPage from 'pages/policy/TermsPage';
import { ProtectedRoute } from './ProtectedRoute';
import MainRoute from 'MainRoute';
import useUIInteractionAction from 'hook/useUIInteractionAction';
import { RedirectArtistPage } from 'pages/home/RedirectArtistPage';
import OrderPaymentPage from 'pages/order/OrderPaymentPage';
import CollectionRegisterPage from 'pages/collection/CollectionRegisterPage';
import CollectionListPage from 'pages/collection/CollectionListPage';
import CollectionDetailPage from 'pages/collection/CollectionDetailPage';
import { RedirectProductPage } from 'pages/home/RedirectProductPage';
import PhoneAuthPage from 'pages/auth/PhoneAuthPage';
import QRCodePage from 'pages/qrcode/QRCodePage';
import TicketSeatPage from 'pages/ticket/TicketSeatPage';

const bottomNavList = [PATH.Home, PATH.Schedules, PATH.Shop, PATH.Settings];
function RoutesContainer() {
  const location = useLocation();
  const { handleHideBtmNav } = useUIInteractionAction();

  useEffect(() => {
    handleHideBtmNav(bottomNavList.indexOf(location.pathname) === -1);
  }, [location.pathname]);

  return (
    <PageTransition location={location}>
      <Routes location={location}>
        <Route path={PATH.Root} element={<RootPage />} />
        <Route path={PATH.RedirectArtist} element={<RedirectArtistPage />} />
        <Route path={PATH.RedirectProduct} element={<RedirectProductPage />} />
        <Route path={PATH.Home} element={<MainRoute />}>
          <Route index element={<HomePage />} />
          <Route path={PATH.Schedules} element={<ScheduleListPage />} />
          <Route path={PATH.Shop}>
            <Route index element={<ShopListPage />} />
            <Route path={PATH.ShopDetail} element={<ShopDetailPage />} />
          </Route>
          <Route path={PATH.Settings} element={<SettingPage />} />
        </Route>
        <Route path={PATH.FeedDetail} element={<FeedDetailPage />} />
        <Route path={PATH.Notices}>
          <Route index element={<NoticeListPage />} />
          <Route path={PATH.NoticeDetail} element={<NoticeDetailPage />} />
        </Route>
        <Route path={PATH.SignIn}>
          <Route index element={<SignInPage.SignIn />} />
          <Route path="kakao" element={<SignInPage.Redirects.Kakao />} />
          <Route path="naver" element={<SignInPage.Redirects.Naver />} />
          <Route path="google" element={<SignInPage.Redirects.Google />} />
        </Route>
        <Route path="/phoneAuth" element={<PhoneAuthPage />} />
        <Route path={PATH.SignUp} element={<SignUpPage />} />

        <Route element={<ProtectedRoute />}>
          <Route
            path={PATH.CollectionRegister}
            element={<CollectionRegisterPage />}
          />
          <Route path={PATH.Collections}>
            <Route index element={<CollectionListPage />} />
            <Route
              path={PATH.CollectionDetail}
              element={<CollectionDetailPage />}
            />
          </Route>

          <Route path={PATH.Orders}>
            <Route index element={<OrderListPage />} />
            <Route path={PATH.OrderDetail} element={<OrderDetailPage />} />
            <Route path={PATH.OrderCancel} element={<OrderCancelPage />} />
          </Route>

          <Route path={PATH.OrderAdminDetail} element={<OrderDetailPage />} />

          <Route path={PATH.Cart}>
            <Route index element={<CartPage />} />
            <Route path={PATH.OrderRequest}>
              <Route index element={<OrderRequestPage />} />
              <Route path={PATH.OrderPayment} element={<OrderPaymentPage />} />
            </Route>
            <Route
              path={PATH.OrderRequest + '/' + PATH.OrderComplete}
              element={<OrderCompletePage />}
            />
          </Route>
          <Route path={PATH.ArtistChange} element={<ArtistChangePage />} />
          <Route path={PATH.TicketSeat} element={<TicketSeatPage />} />
        </Route>

        <Route path={PATH.CustomerService} element={<CustomerService />} />
        <Route path={PATH.Privacy} element={<PrivacyPage />} />
        <Route path={PATH.Terms} element={<TermsPage />} />
        <Route path={PATH.Error} element={<ErrorPage />} />
        <Route path={PATH.OrderAdminError} element={<QRCodePage />} />
      </Routes>
    </PageTransition>
  );
}

export default RoutesContainer;
