import { format, differenceInMilliseconds, formatISO } from 'date-fns';
import { ko } from 'date-fns/locale';

const sameDate = (date1: Date, date2: Date) => {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
};

const convertDateToString = (date: Date) => {
  return format(date, 'yyyy년 MM월 dd일 HH:mm');
};

const convertDateToTime = (date: Date) => {
  return date.getTime() / 1000;
};

const convertTimeToDate = (time?: number) => {
  if (!time) {
    return new Date();
  }

  return new Date(time * 1000);
};

const convertDateToMMDD = (date?: Date) => {
  if (!date) {
    return '-';
  }

  return format(date, 'MM/dd');
};

const convertDateToYYMMDD = (date?: Date) => {
  if (!date) {
    return '-';
  }

  return format(date, 'yyyy.MM.dd');
};

const convertDateToYYMMDDHHmm = (date?: Date) => {
  if (!date) {
    return '-';
  }

  return format(date, 'yyyy년 MM월 dd일 HH:mm');
};

const convertDateToaaHHmm = (date?: Date) => {
  if (!date) {
    return '-';
  }

  return format(date, 'aa hh:mm', { locale: ko });
};

const convertDateToMMDDEEEEHHmm = (date?: Date) => {
  if (!date) {
    return '-';
  }

  return format(date, 'MM월 dd일 EEEE HH:mm', { locale: ko });
};

const convertDateToMMDDEEEE = (date?: Date) => {
  if (!date) {
    return '-';
  }

  return format(date, 'MM월 dd일 EEEE', { locale: ko });
};

const convertDateToMMDDE = (date?: Date) => {
  if (!date) {
    return '-';
  }

  return format(date, 'MM월 dd일(E)', { locale: ko });
};

const Datebetween = (time01: number, time02: number) => {
  const convert = (time: number) => {
    return time.toString().length === 13 ? Math.floor(time / 1000) : time;
  };

  const calcTime = (time: number, unit: string) => {
    let value = 0;

    if (unit === 'SECOUND' || unit === 'MINUTE') value = time % 60;
    if (unit === 'HOUR') value = time % 24;
    if (unit === 'DAY') value = time;

    if (unit === 'DAY') {
      const day = Math.floor(value).toString();
      return day.length > 2
        ? day.padStart(day.length, '0')
        : day.padStart(2, '0');
    }
    return Math.floor(value).toString().padStart(2, '0');
  };

  const between = differenceInMilliseconds(convert(time01), convert(time02));
  const timeOver = between <= 0 ? true : false;

  const second = calcTime(between, 'SECOUND');
  const minute = calcTime(between / 60, 'MINUTE');
  const hour = calcTime(between / 3600, 'HOUR');
  const day = calcTime(between / 86400, 'DAY');

  return { second, minute, hour, day, timeOver };
};

const convertDateToIOS8601 = (date?: Date) => {
  if (!date) {
    return '-';
  }

  return formatISO(date);
};

const DateUtils = {
  sameDate,
  convertDateToString,
  convertDateToTime,
  convertTimeToDate,
  convertDateToMMDD,
  convertDateToYYMMDD,
  convertDateToYYMMDDHHmm,
  convertDateToaaHHmm,
  convertDateToMMDDEEEEHHmm,
  convertDateToMMDDEEEE,
  convertDateToMMDDE,
  Datebetween,
  convertDateToIOS8601,
};

export default DateUtils;
