import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Utils } from 'utils/Utils';
import Input from 'components/common/Input';
import AddressInput from 'components/common/AdressInput';
import Select from 'components/common/Select';
import { Context as OrderContext } from 'contexts/OrderContext';
import Colors from 'styles/color-system';
import { OrderService } from '../../services/OrderService';
import { OrderListItemDto } from '../../dto/OrderDto';
import { DeliveryIsolatedDto } from '../../dto/DeliveryDto';
import useOverlay from '../../hook/useOverlay';

interface Props {
  onReadyDeliveryInfo: (ready: boolean) => void;
}

type InputState = 'DEFAULT' | 'ERROR';

const memoList = [
  '직접 입력',
  '부재 시 문 앞에 놓아 주세요',
  '부재 시 경비실에 맡겨 주세요',
  '부재 시 전화 또는 문자 주세요',
  '택배함에 넣어 주세요',
  '배송 전 연락 주세요',
];

const MAX_NAME_LENGTH = 8;
const EMAIL_REGEX = /([a-z0-9]+@[a-z]+\.[a-z]{2,3})(\]?)$/;
const PHONE_REGEX = /^[0-9]{2,3}[0-9]{3,4}[0-9]{4}$/;
function OrderRequestDeliveryInfo({ onReadyDeliveryInfo }: Props) {
  const {
    state: { deliveryInfo, orderInfo },
    modifyDelivery,
    modifyOrder,
  } = useContext(OrderContext);

  const { showToast } = useOverlay();

  const [isMemoInput, setIsMemoInput] = useState(false);

  const [nameState, setNameState] = useState<InputState>('DEFAULT');
  const [phoneState, setPhoneState] = useState<InputState>('DEFAULT');
  const [emailState, setEmailState] = useState<InputState>('DEFAULT');
  const [addressState, setAddressState] = useState<InputState>('DEFAULT');

  useEffect(() => {
    const resultName = validateName();
    const resultPhone = validatePhone();
    const resultEmail = validateEmail();
    const resultAddress = validateAddress();
    onReadyDeliveryInfo(
      resultName && resultPhone && resultEmail && resultAddress,
    );

    // 20231207 배송비 무료화로 도서산간 지방 배송비 체크 주석 처리
    // if (deliveryInfo.post_code) {
    //   OrderService.checkDelivery(deliveryInfo.post_code).then(({ data }) => {
    //     const result = data as DeliveryIsolatedDto;
    //     if (orderInfo.isolated_price !== result.price) {
    //       let tempInfo = { ...orderInfo };
    //       tempInfo.isolated_price = result.price;
    //       modifyOrder(tempInfo);
    //
    //       if (result.price > 0) {
    //         showToast('도서산간 지역으로 추가 배송비가 발생합니다.');
    //       }
    //     }
    //     console.log(result);
    //   });
    // }
  }, [deliveryInfo]);

  const validateName = (): boolean => {
    if (deliveryInfo.name === '') {
      setNameState('ERROR');
      return false;
    }

    setNameState('DEFAULT');
    return true;
  };

  const validatePhone = (): boolean => {
    if (deliveryInfo.phone === '') {
      return false;
    }

    if (!PHONE_REGEX.test(deliveryInfo.phone)) {
      setPhoneState('ERROR');
      return false;
    }

    setPhoneState('DEFAULT');
    return true;
  };

  const validateEmail = (): boolean => {
    if (deliveryInfo.email === '') {
      return false;
    }

    if (!EMAIL_REGEX.test(deliveryInfo.email)) {
      setEmailState('ERROR');
      return false;
    }

    setEmailState('DEFAULT');
    return true;
  };

  const validateAddress = (): boolean => {
    if (deliveryInfo.post_code === '' || deliveryInfo.address0 === '') {
      return false;
    }

    if (deliveryInfo.address1 === '') {
      setAddressState('ERROR');
      return false;
    }

    setAddressState('DEFAULT');
    return true;
  };

  const handleMemoSelect = (value: string) => {
    if (value === '직접 입력') {
      setIsMemoInput(true);
    } else {
      setIsMemoInput(false);

      if (value !== undefined) {
        let tempInfo = { ...deliveryInfo };
        tempInfo.memo = value;
        modifyDelivery(tempInfo);
      }
    }
  };

  return (
    <Container>
      <Title>배송정보</Title>
      <Input
        label="받으시는 분"
        essential
        placeholder="받으시는 분 입력"
        value={deliveryInfo.name}
        onBlur={(val) => {
          if (val !== undefined) {
            let tempInfo = { ...deliveryInfo };
            tempInfo.name = val;
            modifyDelivery(tempInfo);
          }
        }}
        state={nameState}
        errorMessage="받으시는 분을 입력하세요"
        maxLength={MAX_NAME_LENGTH}
      />
      <Input
        label="휴대폰번호"
        essential
        placeholder="휴대폰번호 입력"
        value={Utils.convertPhoneAddedHyphen(deliveryInfo.phone)}
        onBlur={(val) => {
          if (val !== undefined) {
            if (!val) {
              setPhoneState('ERROR');
            }
            let tempInfo = { ...deliveryInfo };
            tempInfo.phone = Utils.convertPhoneRemovedHyphen(val);
            modifyDelivery(tempInfo);
          }
        }}
        state={phoneState}
        errorMessage="휴대폰번호를 입력하세요"
      />
      <Input
        label="이메일"
        essential
        placeholder="example@gmail.com"
        value={deliveryInfo.email}
        onBlur={(val) => {
          if (val !== undefined) {
            if (!val) {
              setEmailState('ERROR');
            }

            let tempInfo = { ...deliveryInfo };
            tempInfo.email = val;
            modifyDelivery(tempInfo);
          }
        }}
        state={emailState}
        errorMessage="올바른 이메일 형식을 입력하세요"
      />
      <AddressInput
        label="주소"
        essential
        onBlur={(values) => {
          console.log(values);
          let tempInfo = { ...deliveryInfo };
          if (values.post_code !== undefined) {
            tempInfo.post_code = values.post_code;
          }
          if (values.address0 !== undefined) {
            tempInfo.address0 = values.address0;
          }
          if (values.address1 !== undefined) {
            if (!values.address1) {
              setAddressState('ERROR');
            }

            tempInfo.address1 = values.address1;
          }
          modifyDelivery(tempInfo);
        }}
        value={{
          post_code: deliveryInfo.post_code,
          address0: deliveryInfo.address0,
          address1: deliveryInfo.address1,
        }}
        state={addressState}
        errorMessage="올바른 주소를 입력하세요"
      />
      <MemoWrapper>
        <Select
          label="배송 요청사항"
          options={memoList}
          placeholder="배송 요청사항 선택"
          onChange={handleMemoSelect}
        />
        {isMemoInput && (
          <Input
            placeholder="배송 요청사항 직접 입력"
            onBlur={(value) => {
              if (value !== undefined) {
                let tempInfo = { ...deliveryInfo };
                tempInfo.memo = value;
                modifyDelivery(tempInfo);
              }
            }}
          />
        )}
      </MemoWrapper>
    </Container>
  );
}

export default OrderRequestDeliveryInfo;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 21px;
  margin-top: 27px;
  padding-bottom: 29px;
  border-bottom: 1px solid ${Colors.Gray[600]};
  box-sizing: border-box;
`;

const Title = styled.h3`
  color: ${Colors.Gray[100]};
  font-size: 20px;
  font-weight: 700;
  line-height: normal;
`;

const MemoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
