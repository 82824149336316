import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { OrderService } from 'services/OrderService';
import { OrderDetailResponse } from 'dto/OrderDto';
import { PRODUCT_TYPE } from 'constants/ProductType';
import { Utils } from 'utils/Utils';
import useOverlay from 'hook/useOverlay';
import DateUtils from 'utils/DateUtils';
import HeaderNavBar from 'components/common/HeaderNavBar';
import { ORDER_STATUS_LABELS } from 'constants/OrderStatus';
import OrderDeliveryInfoCard from 'components/order/OrderDeliveryInfoCard';
import OrderPaymentInfoCard from 'components/order/OrderPaymentInfoCard';
import Colors from 'styles/color-system';
import OrderPaymentInfoAccount from 'components/order/OrderPaymentInfoAccount';
import TicketPopup from 'components/overlays/TicketPopup';
import { ReactComponent as TicketIcon } from 'assets/icon/ticket.svg';
import { ReactComponent as SeatIcon } from 'assets/icon/seat.svg';
import PATH from 'Path';

interface StyledProps {
  mgBtm: string;
}

function OrderDetailPage() {
  const navigate = useNavigate();
  const { orderId } = useParams();
  const { uuid } = useParams();
  const [orderDetail, setOrderDetail] = useState<OrderDetailResponse>();
  const { showToast, showHandler, hideHandler } = useOverlay();
  const [searchParams] = useSearchParams();
  const ticketId = searchParams.get('ticket') || undefined;
  const [hideId, setHideId] = useState<string>('');

  useEffect(() => {
    if (orderId) {
      OrderService.orderDetail(orderId).then((response) => {
        console.log(response.data);
        const result = response.data as OrderDetailResponse;
        setOrderDetail(result);
      });
    } else if (uuid) {
      OrderService.orderAdminDetail(uuid)
        .then((response) => {
          console.log(response.data);
          const result = response.data as OrderDetailResponse;
          setOrderDetail(result);
        })
        .catch((e) => {
          showToast('접근 권한이 없습니다.');
          navigate(PATH.OrderAdminError, { replace: true });
        });
    }
  }, [orderId, uuid]);

  useEffect(() => {
    console.log(ticketId);
    if (ticketId) {
      handleShowTicket(ticketId);
    }

    if (!ticketId) {
      hideHandler(hideId);
    }
  }, [ticketId]);

  const handleShowTicket = (id: string) => {
    const index = parseInt(id);
    const orderNumber = '' + orderDetail?.uuid;
    let date = '';
    const optionTitle = orderDetail?.items[index].option?.title;
    if (optionTitle && optionTitle.indexOf('1부') >= 0) {
      date = '2023.11.04  오후 2:00';
    } else {
      date = '2023.11.04  오후 6:00';
    }

    const hideId = showHandler(
      <TicketPopup
        thumbnail={orderDetail?.items[index].product.thumbnail!}
        title={orderDetail?.items[index].product.title!}
        fileName={
          orderDetail?.items[index].product.title! +
          orderDetail?.items[index].option?.title!
        }
        location="동자아트홀"
        date={date}
        orderNumber={orderNumber}
        qrcodeLink={'https://fanus.kr/admin/orders/' + orderNumber}
        onClose={() => navigate(-1)}
      />,
      'POPUP',
    );
    setHideId(hideId);
  };

  const handleCancel = () => {
    navigate(Utils.subNavigationPath(PATH.Orders, PATH.OrderCancel), {
      state: { isBack: true, order: orderDetail },
    });
  };

  const renderDigitalTicketButton = (type: PRODUCT_TYPE, index: number) => {
    if (type === 'TICKET') {
      return (
        <>
          <DigitalTicketButton
            onClick={() => {
              navigate(`?ticket=${index}`);
            }}
          >
            <TicketIcon />
            디지털 티켓
          </DigitalTicketButton>
          <SeatNumberButton
            onClick={() =>
              navigate(`/ticket-seat/${orderDetail?.uuid}`, {
                state: {
                  isBack: true,
                },
              })
            }
          >
            <SeatIcon />
            좌석 확인
          </SeatNumberButton>
        </>
      );
    }

    return <></>;
  };

  return (
    <Container>
      <HeaderNavBar
        label="주문상세"
        leftItem="BACK"
        bgColor={Colors.Background[500]}
        btmLine
      />
      <ContentWrapper>
        <OrderInfo>
          <OrderNumber>{orderDetail?.uuid}</OrderNumber>
          <OrderTime>
            {DateUtils.convertDateToYYMMDDHHmm(
              DateUtils.convertTimeToDate(orderDetail?.created),
            )}
          </OrderTime>
        </OrderInfo>
        <InfoSection>
          <Title>주문상품</Title>
          <Card>
            {orderDetail?.items.map((order, index) => (
              <Item key={index}>
                <ProductTitle>{order.product.title}</ProductTitle>
                <ProductOption mgBtm="6px">
                  {order.option ? order.option.title : ''}
                </ProductOption>
                <ProductOption mgBtm="13px">{order.count}개</ProductOption>
                <ProductAmount>
                  {Utils.convertCurrency(order.amount)}원
                </ProductAmount>
                {renderDigitalTicketButton(order.product.type, index)}
              </Item>
            ))}
            <OrderStatus>
              {ORDER_STATUS_LABELS[orderDetail?.status!]}
            </OrderStatus>
          </Card>
        </InfoSection>
        {orderDetail?.payment.account_bank ? (
          <OrderPaymentInfoAccount
            bank={orderDetail.payment.account_bank}
            name={orderDetail.payment.account_name!}
            accountNumber={orderDetail.payment.account_number!}
            amount={
              orderDetail.order_amount +
              orderDetail.delivery_amount +
              orderDetail.isolated_price
            }
            showTitle={true}
            date={orderDetail.payment.account_expire!}
            margin={{ top: 33, bottom: 33 }}
          />
        ) : (
          <></>
        )}
        <OrderDeliveryInfoCard
          name={orderDetail?.delivery.name!}
          phone={orderDetail?.delivery.phone!}
          address0={orderDetail?.delivery.address0!}
          address1={orderDetail?.delivery.address1!}
          memo={orderDetail?.delivery.memo!}
        />
        <OrderPaymentInfoCard
          method={orderDetail?.payment.method!}
          order={orderDetail?.order_amount!}
          delivery={
            orderDetail?.delivery_amount! + orderDetail?.isolated_price!
          }
        />
        {/*{orderDetail?.status! === 'PAYMENT_COMPLETE' && (*/}
        {/*  <CancelButton onClick={handleCancel}>결제 취소 하기</CancelButton>*/}
        {/*)}*/}
      </ContentWrapper>
    </Container>
  );
}

export default OrderDetailPage;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 100%;
`;

const ContentWrapper = styled.div`
  flex: 1;
  overflow: hidden auto;
  padding-inline: 24px;
  padding-bottom: 53px;
  box-sizing: border-box;
`;

const OrderInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 9px;
  color: ${Colors.Gray[200]};
  padding-bottom: 19px;
  border-bottom: 1px solid ${Colors.Gray[200]};
  margin-top: 18px;
  margin-bottom: 28px;
  box-sizing: border-box;
`;

const OrderNumber = styled.span`
  font-size: 18px;
  font-weight: 700;
  line-height: 1.222em;
  letter-spacing: -0.02em;
`;

const OrderTime = styled.span`
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
`;

const InfoSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 33px;
`;

const Title = styled.h3`
  color: ${Colors.Gray[200]};
  font-size: 18px;
  font-weight: 700;
  line-height: 1.222em;
  letter-spacing: -0.02em;
`;

const Card = styled.div`
  border-radius: 6px;
  background: ${Colors.Gray[700]};
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  padding-inline: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid ${Colors.Gray[600]};
  box-sizing: border-box;
`;

const ProductTitle = styled.span`
  width: 100%;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${Colors.Gray[100]};
  font-size: 18px;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 8px;
`;

const ProductOption = styled.span<StyledProps>`
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${Colors.Gray[200]};
  font-size: 15px;
  font-weight: 400;
  line-height: normal;
  margin-bottom: ${({ mgBtm }) => mgBtm};
`;

const ProductAmount = styled.span`
  color: ${Colors.White[500]};
  font-size: 20px;
  font-weight: 700;
  line-height: normal;
`;

const OrderStatus = styled.div`
  padding-inline: 24px;
  padding-block: 16px;
  color: ${Colors.Primary[500]};
  font-size: 18px;
  font-weight: 700;
  line-height: normal;
`;

const DefaultTicketButton = styled.button.attrs({ type: 'button' })`
  width: 100%;
  height: 34px;
  border-radius: 6px;
  box-sizing: border-box;
  background: ${Colors.Gray[600]};
  display: flex;

  align-items: center;
  justify-content: center;
  color: ${Colors.Gray[200]};
  font-size: 15px;
  font-weight: 400;
  line-height: 34px;
  cursor: pointer;
  user-select: none;
`;

const DigitalTicketButton = styled(DefaultTicketButton)`
  margin-top: 18px;
  gap: 9px;

  svg {
    margin-bottom: 1px;
  }
`;

const SeatNumberButton = styled(DefaultTicketButton)`
  margin-top: 8px;
  gap: 8px;

  svg {
    margin-bottom: 2px;
  }
`;

const CancelButton = styled.button.attrs({ type: 'button' })`
  margin-top: 33px;
  width: 100%;
  height: 46px;
  border-radius: 6px;
  box-sizing: border-box;
  background: ${Colors.Gray[600]};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${Colors.Gray[200]};
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
  user-select: none;
`;
