import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { ArtistService } from 'services/ArtistService';
import { NoticeItemResponse, NoticeListResponse } from 'dto/NoticeDto';
import { Utils } from 'utils/Utils';
import PATH from 'Path';
import HeaderNavBar from 'components/common/HeaderNavBar';
import ItemNotFound from 'components/common/ItemNotFound';
import MoreView from 'components/common/MoreView';
import FirstCard from 'components/notice/FirstCard';
import BasicCard from 'components/notice/BasicCard';
import Colors from 'styles/color-system';

const LIST_PAGE_LIMIT = 10;
function NoticeListPage() {
  const navigate = useNavigate();
  const [totalCount, setTotalCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const [noticeList, setNoticeList] = useState<NoticeItemResponse[]>([]);

  useEffect(() => {
    ArtistService.noticeList(currentPage, LIST_PAGE_LIMIT).then((response) => {
      const result = response.data as NoticeListResponse;
      setNoticeList([...noticeList, ...result.items]);
      setTotalCount(result.total);
    });
  }, [currentPage]);

  const moreData = () => {
    const totalPage = Math.ceil(totalCount / LIST_PAGE_LIMIT);
    if (currentPage === totalPage) {
      return;
    }

    setCurrentPage(currentPage + 1);
  };

  const handleClick = (noticeId: number) => {
    navigate(Utils.subNavigationPath(PATH.Notices, '' + noticeId));
  };

  const renderNotice = () => {
    if (noticeList.length === 0) {
      return <ItemNotFound message="새로운 공지가 없습니다." />;
    }

    return noticeList.map((notice, index: number) =>
      index === 0 ? (
        <FirstCard key={notice.id} data={notice} onClick={handleClick} />
      ) : (
        <BasicCard key={notice.id} data={notice} onClick={handleClick} />
      ),
    );
  };

  return (
    <Container>
      <HeaderNavBar leftItem="BACK" label="공지사항" />
      <ContentBody>
        {renderNotice()}{' '}
        <MoreView onClick={moreData} show={totalCount > noticeList.length} />
      </ContentBody>
    </Container>
  );
}

export default NoticeListPage;

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  height: 100%;
  background: ${Colors.Background[500]};
`;

const ContentBody = styled.div`
  flex: 1;
  display: block;
  overflow: hidden auto;
`;
