import { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Utils } from 'utils/Utils';
import { PRODUCT_TYPE_LABELS } from 'constants/ProductType';
import { ProductDetailDto } from 'dto/ProductDto';
import ShopItemBadge from './ShopItemBadge';
import Image from 'components/common/Image';
import DateUtils from 'utils/DateUtils';
import Colors from 'styles/color-system';
import { DeliveryDefaultDto } from 'dto/DeliveryDto';
import ToolTip from 'components/common/ToolTip';

interface Props {
  data: ProductDetailDto;
  // delivery: DeliveryDefaultDto;
  salesStatus: SalesStatus;
  soldOut: boolean;
  setSoldOut: React.Dispatch<React.SetStateAction<boolean>>;
  setSalesStatus: React.Dispatch<React.SetStateAction<SalesStatus>>;
}

interface StyledProps {
  discount?: boolean;
  timerWidth?: boolean;
}

type SalesStatus = 'BEFORE' | 'ONGOING' | 'AFTER';

function ShopDetailTopSection({
  data,
  // delivery,
  salesStatus,
  setSalesStatus,
  soldOut,
  setSoldOut,
}: Props) {
  const [timeValue, setTimeValue] = useState('');

  useEffect(() => {
    setSoldOut(data.stock <= 0);
  }, [data.stock]);

  useEffect(() => {
    if (!data.start && !data.end) {
      return;
    }
    const handleTimer = () => {
      let time = 0;
      const state = Utils.calcShopItemState(data.start, data.end);

      if (state === 'BEFORE') {
        time = data.start;
        setSalesStatus('BEFORE');
      }

      if (state === 'ONGOING' && !soldOut) {
        time = data.end;
        setSalesStatus('ONGOING');
      }

      if (state === 'AFTER' || soldOut) {
        setSalesStatus('AFTER');
        clearInterval(timer);
      }

      const { second, minute, hour, day } = DateUtils.Datebetween(
        time,
        Date.now(),
      );

      if (day === '00') {
        setTimeValue(`${hour}:${minute}:${second}`);
      } else {
        setTimeValue(`${day}일`);
      }
    };

    const timer = setInterval(() => {
      handleTimer();
    }, 1000);

    // init
    handleTimer();

    return () => clearInterval(timer);
  }, [data.start, data.end, soldOut]);

  const beforeContent = (
    <Content timerWidth={timeValue.length >= 8}>
      판매 시작까지&nbsp;
      <span className="bold">{timeValue}</span>
      &nbsp;남음
    </Content>
  );

  const ongoingContent = (
    <Content timerWidth={timeValue.length >= 8}>
      판매 종료까지&nbsp;
      <span className="bold">{timeValue}</span>
      &nbsp;남음
    </Content>
  );

  const afterContent = (
    <Content>
      <span className="bold">판매 종료</span>
    </Content>
  );

  const renderDelivery = () => {
    // const deliveryAppend = `\n제주 ${Utils.convertCurrency(
    //   delivery.jeju,
    // )}원 도서산간 ${Utils.convertCurrency(delivery.etc)}원 추가`;

    // if (delivery.free === 0) {
    return (
      <ItemSection>
        <Label>배송정보</Label>
        <Content>
          {`무료 배송`}
          &nbsp;
          <ToolTip>
            <ToolTipContent>
              <p className="title">취소, 반품, 교환 안내</p>
              <p className="text">{`고객 과실인 경우 배송비 본인 부담 \n편도 3,500원 / 왕복 7,000원 부과`}</p>
            </ToolTipContent>
          </ToolTip>
        </Content>
      </ItemSection>
    );
    // }
    // return (
    //   <ItemSection>
    //     <Label>배송정보</Label>
    //     <Content>
    //       {`${Utils.convertCurrency(delivery.price)}원 (${Utils.convertCurrency(
    //         delivery.free,
    //       )}원 이상 무료 배송)${deliveryAppend}`}
    //     </Content>
    //   </ItemSection>
    // );
  };

  return (
    <Container>
      <Thumbnail>
        <ShopItemBadge type={soldOut ? 'SOLD_OUT' : 'NONE'} size="M" />
        <ImageWrapper>
          <Image src={data.description?.thumbnail} />
        </ImageWrapper>
      </Thumbnail>
      <ProductInfo>
        <Type>{PRODUCT_TYPE_LABELS[data.type]}</Type>
        <Title>{data.title}</Title>
        <PriceSection discount={data.amount?.is_discount}>
          {data.amount?.is_discount && (
            <DiscountRate>{data.amount?.percent}%</DiscountRate>
          )}
          <Price>{Utils.convertCurrency(data.amount?.amount)}원</Price>
          {data.amount?.is_discount && (
            <Cost>{Utils.convertCurrency(data.amount?.origin)}원</Cost>
          )}
        </PriceSection>
        <ShippingInfo>
          {renderDelivery()}
          <ItemSection>
            <Label>판매기간</Label>
            {salesStatus === 'BEFORE' && beforeContent}
            {salesStatus === 'ONGOING' && ongoingContent}
            {salesStatus === 'AFTER' && afterContent}
          </ItemSection>
        </ShippingInfo>
      </ProductInfo>
    </Container>
  );
}

export default ShopDetailTopSection;

const Container = styled.div`
  position: relative;
  margin-bottom: 70px;
`;

const Thumbnail = styled.div`
  position: relative;
  width: 100%;

  &::after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }
`;

const ImageWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const ProductInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding-inline: 12px;
  box-sizing: border-box;
`;

const Type = styled.span`
  color: ${Colors.Primary[500]};
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  padding-inline: 12px;
  margin-top: 23px;
  margin-bottom: 9px;
`;

const Title = styled.span`
  width: 100%;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${Colors.Gray[100]};
  font-size: 28px;
  font-weight: 500;
  line-height: normal;
  padding-inline: 12px;
  margin-bottom: 22px;
`;

const PriceSection = styled.div<StyledProps>`
  ${({ discount }) =>
    discount
      ? css`
          display: grid;
          grid-template-areas:
            'discount cost'
            'price price';
          justify-content: start;
          column-gap: 5px;
          row-gap: 3px;
          margin-bottom: 31px;
        `
      : css`
          margin-bottom: 53px;
        `};
  padding-inline: 12px;
  font-weight: 700;
  line-height: normal;
`;

const Cost = styled.span`
  grid-area: cost;
  color: ${Colors.Gray[300]};
  font-size: 16px;
  font-weight: 500;
  text-decoration-line: line-through;
  text-decoration-thickness: 1px;
`;

const Price = styled.span`
  grid-area: price;
  color: ${Colors.Gray[100]};
  font-size: 24px;
`;

const DiscountRate = styled.span`
  grid-area: discount;
  color: ${Colors.Error[500]};
  font-size: 16px;
`;

const ShippingInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 100%;
  border-top: 1px solid ${Colors.Gray[700]};
  border-bottom: 1px solid ${Colors.Gray[700]};
  padding-block: 20px;
  padding-inline: 12px;
  box-sizing: border-box;
`;

const ItemSection = styled.div`
  display: flex;
  gap: 16px;
`;

const ShippingInfoText = styled.span`
  color: ${Colors.Gray[300]};
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  white-space: pre-line;
`;

const Label = styled(ShippingInfoText)``;

const Content = styled(ShippingInfoText)<StyledProps>`
  display: flex;
  & > .bold {
    display: block;
    width: ${({ timerWidth }) => (timerWidth ? '70px' : 'auto')};
    font-weight: 600;
  }
`;

const ToolTipContent = styled.div`
  color: ${Colors.Gray[200]};

  & .title {
    font-size: 14px;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 6px;
  }

  & .text {
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
  }
`;
