import React, { Fragment, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { CSSTransition } from 'react-transition-group';
import Select, { Option } from 'components/common/Select';
import BottomDrawerSelectedItem from 'components/shop/BottomDrawerSelectedItem';
import { SelectedOptionItemDto } from 'dto/CartDto';
import { Utils } from 'utils/Utils';
import Colors from 'styles/color-system';

const speed = 300;
interface Props {
  isShow: boolean;
  selectedItems: SelectedOptionItemDto[];
  options: Option[];
  onSelectOption: (value: string) => void;
  onHide: () => void;
  onUpdate: (option: SelectedOptionItemDto) => void;
  onCancel: (option: SelectedOptionItemDto) => void;
}

function ShopDetailBottomDrawer({
  isShow,
  onHide,
  selectedItems,
  onSelectOption,
  options,
  onUpdate,
  onCancel,
}: Props) {
  const [count, setCount] = useState(0);
  const [amount, setAmount] = useState(0);

  useEffect(() => {
    if (selectedItems) {
      let tempCount = 0;
      let tempAmount = 0;

      selectedItems.map((item) => {
        tempCount += item.count;
        tempAmount += item.count * item.amount;
      });

      setCount(tempCount);
      setAmount(tempAmount);
    }

    return () => {};
  }, [selectedItems]);

  const renderSelectItems = () => {
    if (!selectedItems || selectedItems.length === 0) {
      return <OptionNotFound>옵션 선택을 해주세요</OptionNotFound>;
    }

    return (
      <ScrollWrapper>
        <ListWrapper>
          {selectedItems.map((item: SelectedOptionItemDto) => (
            <BottomDrawerSelectedItem
              key={item.option_id}
              item={item}
              onUpdate={onUpdate}
              onCancel={onCancel}
            />
          ))}
          <TotalInfo>
            <Number>총 {count} 개</Number>
            <TotalPrice>
              <Sum>합계</Sum>
              <Price>{Utils.convertCurrency(amount)}</Price>
              <Won>원</Won>
            </TotalPrice>
          </TotalInfo>
        </ListWrapper>
      </ScrollWrapper>
    );
  };

  return (
    <CSSTransition in={isShow} timeout={speed} unmountOnExit>
      <Container>
        <CloseButton onClick={onHide} />
        {options && options.length > 0 && (
          <SelectWrapper>
            <Select
              options={options}
              onChange={onSelectOption}
              placeholder="옵션 선택"
              essential
            />
          </SelectWrapper>
        )}
        {renderSelectItems()}
      </Container>
    </CSSTransition>
  );
}

export default ShopDetailBottomDrawer;

const Transition = () => css`
  transition: ${speed}ms ease-in-out;
  transform: translateY(calc(100% + 58px));

  &[class*='enter-'] {
    transform: translateY(0);
  }

  &[class*='exit-'] {
    transform: translateY(calc(100% + 58px));
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 58px;
  width: 100%;
  height: 357px;
  border-radius: 20px 20px 0 0;
  padding-top: 32px;
  padding-bottom: 24px;
  box-sizing: border-box;
  background: ${Colors.Background[500]};
  ${Transition()}
  z-index: 50;
`;

const ScrollWrapper = styled.div`
  flex: 1;
  padding-inline: 24px;
  box-sizing: border-box;
  overflow: hidden auto;
`;

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const CloseButton = styled.div`
  position: absolute;
  top: 5px;
  left: 50%;
  width: 74px;
  height: 20px;
  transform: translate3d(-50%, 0, 0);

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    width: 54px;
    height: 4px;
    border-radius: 2px;
    background: ${Colors.Gray[500]};
  }
`;

const SelectWrapper = styled.div`
  padding-inline: 24px;
  margin-bottom: 16px;
  box-sizing: border-box;
`;

const OptionNotFound = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  color: ${Colors.Gray[600]};
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.02em;
`;

const TotalInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 4px;
`;

const Number = styled.span`
  color: ${Colors.White[500]};
  font-size: 18px;
  font-weight: 500;
  line-height: normal;
`;

const TotalPrice = styled.span`
  display: flex;
  align-items: center;
  text-align: right;
`;

const Sum = styled.span`
  color: ${Colors.White[500]};
  font-size: 18px;
  font-weight: 500;
  line-height: normal;
  margin-right: 5px;
`;

const Price = styled.span`
  color: ${Colors.Primary[500]};
  font-size: 24px;
  font-weight: 700;
  line-height: normal;
  margin-right: 2px;
`;

const Won = styled.span`
  color: ${Colors.Primary[500]};
  font-size: 18px;
  font-weight: 500;
  line-height: normal;
`;
