import React, { Dispatch } from 'react';
import createDataContext from 'contexts/createDataContext';
import { OrderDeliveryDto, OrderInfoDto } from 'dto/OrderDto';
import { Utils } from 'utils/Utils';

const KEY_ORDER_INFO = '@order_info';
const KEY_DELIVERY_INFO = '@delivery_info';

// 상태를 위한 타입
type State = {
  orderInfo: OrderInfoDto;
  deliveryInfo: OrderDeliveryDto;
};

// 모든 액션들을 위한 타입
type Action =
  | { type: 'MODIFY_ORDER'; info: OrderInfoDto }
  | { type: 'MODIFY_DELIVERY'; delivery: OrderDeliveryDto };

// 리듀서
const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'MODIFY_ORDER':
      Utils.saveToSession<OrderInfoDto>(KEY_ORDER_INFO, action.info);

      return {
        ...state,
        orderInfo: action.info,
      };
    case 'MODIFY_DELIVERY':
      Utils.saveToSession<OrderDeliveryDto>(KEY_DELIVERY_INFO, action.delivery);
      return {
        ...state,
        deliveryInfo: action.delivery,
      };
    default:
      throw new Error('Unhandled action');
  }
};

export const initialize = (dispatch: Dispatch<Action>) => {
  return () => {
    dispatch({
      type: 'MODIFY_ORDER',
      info: defaultOrder(),
    });
    dispatch({
      type: 'MODIFY_DELIVERY',
      delivery: defaultDelivery(),
    });
  };
};

const modifyOrder = (dispatch: Dispatch<Action>) => {
  return (info: OrderInfoDto) => {
    dispatch({
      type: 'MODIFY_ORDER',
      info,
    });
  };
};

const modifyDelivery = (dispatch: Dispatch<Action>) => {
  return (delivery: OrderDeliveryDto) => {
    dispatch({
      type: 'MODIFY_DELIVERY',
      delivery,
    });
  };
};

const defaultOrder = (): OrderInfoDto => {
  return {
    order_amount: 0,
    delivery_amount: 0,
    items: [],
    agree_payment: false,
    isolated_price: 0,
  };
};

const defaultDelivery = (): OrderDeliveryDto => {
  return {
    name: '',
    phone: '',
    email: '',
    post_code: '',
    address0: '',
    address1: '',
    memo: '',
  };
};

const loadInfo = (): OrderInfoDto => {
  return Utils.loadFromSession<OrderInfoDto>(KEY_ORDER_INFO, defaultOrder())!;
};

const loadDelivery = (): OrderDeliveryDto => {
  return Utils.loadFromSession<OrderDeliveryDto>(
    KEY_DELIVERY_INFO,
    defaultDelivery(),
  )!;
};

export const { Provider, Context } = createDataContext({
  reducer,
  actions: {
    initialize,
    modifyOrder,
    modifyDelivery,
  },
  defaultValue: {
    orderInfo: loadInfo(),
    deliveryInfo: loadDelivery(),
  },
});
