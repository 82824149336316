import { customAxios } from './CustomAxiosLoader';
import { UpdateUserRequest } from 'dto/AuthDto';

const me = (token?: string) => {
  if (token) {
    const header = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return customAxios.get(`/me`, header);
  }

  return customAxios.get(`/me`);
};

const changeArtist = (artistId: number) => {
  return customAxios.put(`/me/${artistId}`);
};

const likeFeed = (feedId: number, like: boolean) => {
  return customAxios.post(`/feed/${feedId}/like/${like}`);
};

const completeSignUp = (token: string, req: UpdateUserRequest) => {
  const header = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  console.log(req);
  return customAxios.put(`/sign-up/complete`, req, header);
};

const signInKakao = (accessToken: string) => {
  return customAxios.post(`/auth/kakao`, { token: accessToken });
};

const signInNaver = (accessToken: string) => {
  return customAxios.post(`/auth/naver`, { token: accessToken });
};

const checkCollection = (key: string) => {
  return customAxios.get(`/collections/check/${key}`);
};

const registerCollection = (key: string) => {
  return customAxios.post(`/collections/register/${key}`);
};

const collections = () => {
  return customAxios.get(`/collections`);
};

const collectionDetail = (collectionId: string) => {
  return customAxios.get(`/collections/${collectionId}`);
};

export const UserService = {
  me,
  likeFeed,
  completeSignUp,
  changeArtist,
  signInKakao,
  signInNaver,
  checkCollection,
  registerCollection,
  collections,
  collectionDetail,
};
