import React from 'react';
import styled from 'styled-components';
import Image from 'components/common/Image';
import Colors from 'styles/color-system';
import { ArtistThumbnailDto } from 'dto/CommonDto';

interface Props {
  artists: ArtistThumbnailDto[];
  onClick: (artistId: number) => void;
}
function RootArtists({ artists, onClick }: Props) {
  return (
    <Container>
      <Title>아티스트</Title>
      <List>
        {artists.map((artist) => (
          <Artist key={artist.id} onClick={() => onClick(artist.id)}>
            <ImageSection>
              <ImageWrapper>
                <Image src={artist.thumbnail} />
              </ImageWrapper>
            </ImageSection>
            <Name>{artist.name}</Name>
          </Artist>
        ))}
      </List>
    </Container>
  );
}

export default RootArtists;

const Container = styled.div`
  width: 100%;
  padding-bottom: 91px;
  box-sizing: border-box;
`;

const Title = styled.h3`
  color: ${Colors.Gray[200]};
  width: 100%;
  font-size: 18px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.02em;

  margin-bottom: 41px;
  padding-inline: 20px;
  box-sizing: border-box;
`;

const List = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 40px;
  row-gap: 49px;
  padding-inline: 40px;
  box-sizing: border-box;
`;

const Artist = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 17px;
  width: calc(50% - 20px);
  height: auto;
  cursor: pointer;
  user-select: none;
`;

const Name = styled.span`
  color: ${Colors.Gray[100]};
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.02em;
`;

const ImageSection = styled.div`
  position: relative;
  width: 100%;
  border-radius: 50%;
  overflow: hidden;

  &::after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }
`;

const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;

  & img {
    border-radius: 50%;
  }
`;
