import { customAxios } from './CustomAxiosLoader';
import {
  CheckOrderRequest,
  ConfirmOrderRequest,
  SubmitOrderRequest,
  RefundOrderRequest,
} from 'dto/OrderDto';
import { AddCartRequest } from 'dto/CartDto';

const addCart = (req: AddCartRequest) => {
  console.log(req);
  return customAxios.post(`/cart`, req);
};

const cart = () => {
  return customAxios.get(`/cart`);
};

const deleteCart = (cartId: number) => {
  return customAxios.delete(`/cart/${cartId}`);
};

const checkOrder = (req: CheckOrderRequest) => {
  return customAxios.put(`/order/check`, req);
};

// 20231207 배송비 무료화로 도서산간 지방 배송비 체크 주석 처리
// const checkDelivery = (postCode: string) => {
//   return customAxios.get(`/delivery/check/${postCode}`);
// };

const submitOrder = (req: SubmitOrderRequest) => {
  return customAxios.post(`/order/submit`, req);
};

const confirmOrder = (req: ConfirmOrderRequest) => {
  return customAxios.put(`/order/confirm`, req);
};

const orders = (page: number, limit: number) => {
  return customAxios.get(`/orders`, {
    params: { limit: limit, offset: limit * (page - 1) },
  });
};

const moreOrders = (page: number, limit: number) => {
  return customAxios.get(`/orders/more`, {
    params: { limit: limit, offset: limit * (page - 1) },
  });
};

const orderDetail = (orderId: string) => {
  return customAxios.get(`/orders/${orderId}`);
};

const orderAdminDetail = (uuid: string) => {
  return customAxios.get(`/admin/orders/${uuid}`);
};

const refund = (orderId: number, req: RefundOrderRequest) => {
  return customAxios.post(`/orders/${orderId}/refund`, req);
};

export const OrderService = {
  addCart,
  cart,
  deleteCart,
  checkOrder,
  // checkDelivery,
  submitOrder,
  confirmOrder,
  orders,
  moreOrders,
  orderDetail,
  orderAdminDetail,
  refund,
};
