import { ReactNode, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import CheckBox from 'components/common/CheckBox';
import PolicyPopup from 'components/overlays/PolicyPopup';
import useOverlay from 'hook/useOverlay';
import TermsPolicy from 'components/policy/TermsPolicy';
import PrivacyPolicy from 'components/policy/PrivacyPolicy';
import Colors from 'styles/color-system';

interface Props {
  onChangeAgreeTerms: (value: boolean) => void;
  onChangeAgreePrivacy: (value: boolean) => void;
  onChangeAgreeMarketing: (value: boolean) => void;
  onReady: (value: boolean) => void;
}

interface StyleProps {
  type: 'ALL' | 'LINK' | 'NORMAL';
}

function PolicyAgree({
  onChangeAgreeTerms,
  onChangeAgreePrivacy,
  onChangeAgreeMarketing,
  onReady,
}: Props) {
  const { showHandler } = useOverlay();
  const [chkAll, setChkAll] = useState<boolean>(false);
  const [chkTerms, setChkTerms] = useState<boolean>(false);
  const [chkPrivacy, setChkPrivacy] = useState<boolean>(false);
  const [chkMarketing, setChkMarketing] = useState<boolean>(false);

  useEffect(() => {
    setChkTerms(chkAll);
    onChangeAgreeTerms(chkTerms);

    setChkPrivacy(chkAll);
    setChkMarketing(chkAll);
    onChangeAgreePrivacy(chkPrivacy);
  }, [chkAll]);

  useEffect(() => {
    // validation 체크

    onChangeAgreeTerms(chkTerms);
    onChangeAgreePrivacy(chkPrivacy);
    onChangeAgreeMarketing(chkMarketing);

    onReady(chkTerms && chkPrivacy);
  }, [chkTerms, chkPrivacy]);

  const onPopupHandler = (content: ReactNode, index: number) => {
    showHandler(
      <PolicyPopup
        content={content}
        onConfirm={(val) => {
          if (index === 0) {
            setChkTerms(val);
          } else {
            setChkPrivacy(val);
          }
        }}
      />,
      'POPUP',
    );
  };

  return (
    <ContentBody>
      <Section>
        <Title>팬어스 서비스를 이용하기 위한 동의를 해주세요</Title>
        <CheckBox
          onChange={setChkAll}
          value={chkAll}
          style="margin-bottom: 24px;"
        >
          <Label type="ALL">모두 동의</Label>
        </CheckBox>
        <CheckBox
          onChange={setChkTerms}
          value={chkTerms}
          contact={false}
          style="margin-bottom: 8px;"
        >
          <Label type="LINK" onClick={() => onPopupHandler(<TermsPolicy />, 0)}>
            이용약관 동의(필수)
          </Label>
        </CheckBox>
        <CheckBox
          onChange={setChkPrivacy}
          value={chkPrivacy}
          contact={false}
          style="margin-bottom: 8px;"
        >
          <Label
            type="LINK"
            onClick={() => onPopupHandler(<PrivacyPolicy />, 1)}
          >
            개인정보 처리방침 동의(필수)
          </Label>
        </CheckBox>
        <CheckBox onChange={setChkMarketing} value={chkMarketing}>
          <Label type="NORMAL">혜택, 푸쉬, 문자, 이메일 수신 동의(선택)</Label>
        </CheckBox>
      </Section>
    </ContentBody>
  );
}

export default PolicyAgree;

const ContentBody = styled.div`
  flex: 1;
  box-sizing: border-box;
  overflow: hidden auto;
`;

const Section = styled.div`
  padding-inline: 24px;
`;

const Title = styled.h3`
  color: ${Colors.Gray[100]};
  font-size: 24px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.024em;
  margin-block: 35px;
`;

const Label = styled.div<StyleProps>`
  ${({ type }) => {
    switch (type) {
      case 'ALL':
        return css`
          color: ${Colors.Gray[100]};
          font-size: 18px;
          font-weight: 600;
          letter-spacing: -0.02em;
        `;
      case 'LINK':
        return css`
          color: ${Colors.Gray[200]};
          font-size: 16px;
          font-weight: 400;
          letter-spacing: -0.02em;
          text-decoration-line: underline;
          text-underline-offset: 0.25em;
        `;

      case 'NORMAL':
        return css`
          color: ${Colors.Gray[200]};
          font-size: 16px;
          font-weight: 400;
          letter-spacing: -0.02em;
        `;
    }
  }}
`;
