const Gray = {
  100: '#F4F4F4',
  200: '#D9D9D9',
  300: '#818181',
  400: '#666666',
  500: '#6D6D77',
  600: '#484850',
  700: '#303137',
  800: '#0C0D10',
};

const Primary = {
  500: '#8B54FF',
  600: '#39344C',
};

const Background = {
  500: '#1E1F26',
};

const White = {
  500: '#FFFFFF',
};

const Error = {
  500: '#FB7575',
};

const Colors = { Gray, Primary, Background, White, Error };

export default Colors;
