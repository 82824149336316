import React from 'react';
import styled, { css } from 'styled-components';
import { Utils } from 'utils/Utils';
import Colors from 'styles/color-system';

interface Props {
  orderAmount: number;
  deliveryAmount: number;
  totalAmount: number;
}

function CartTotalInfo({ orderAmount, deliveryAmount, totalAmount }: Props) {
  return (
    <Container>
      <InfoTitle>결제정보</InfoTitle>
      <OrderSection>
        <span className="label">총 주문금액</span>
        <span className="cost">{Utils.convertCurrency(orderAmount)}원</span>
      </OrderSection>
      <DeliverySection>
        <span className="label">총 배송비</span>
        <span className="cost">
          {deliveryAmount === 0
            ? '무료'
            : `${Utils.convertCurrency(deliveryAmount)}원`}
        </span>
      </DeliverySection>
      <TotalSection>
        <span className="label">총 결제 금액</span>
        <span className="cost">{Utils.convertCurrency(totalAmount)}원</span>
      </TotalSection>
    </Container>
  );
}

export default CartTotalInfo;

const Container = styled.div`
  width: 100%;
  padding-inline: 24px;
  box-sizing: border-box;
  margin-top: 24px;
  margin-bottom: 38px;
`;

const InfoTitle = styled.span`
  display: block;
  width: 100%;
  color: ${Colors.Gray[100]};
  font-size: 20px;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 18px;
`;

const TextAlign = css`
  display: flex;
  justify-content: space-between;
`;

const LabelStyle = css`
  font-size: 15px;
  font-weight: 400;
  line-height: normal;
`;

const CostStyle = css`
  text-align: right;
  font-weight: 700;
  line-height: normal;
`;

const OrderSection = styled.div`
  ${TextAlign};
  margin-bottom: 7px;
  color: ${Colors.Gray[100]};

  & > .label {
    ${LabelStyle}
  }

  & > .cost {
    font-size: 15px;
    ${CostStyle};
  }
`;

const DeliverySection = styled.div`
  ${TextAlign};
  padding-bottom: 21px;
  border-bottom: 1px solid ${Colors.Gray[200]};
  color: ${Colors.Gray[100]};

  & > .label {
    ${LabelStyle}
  }

  & > .cost {
    font-size: 15px;
    ${CostStyle};
  }
`;

const TotalSection = styled.div`
  ${TextAlign};
  margin-top: 18px;
  color: ${Colors.Primary[500]};

  & > .label {
    ${LabelStyle}
  }

  & > .cost {
    font-size: 20px;
    ${CostStyle};
  }
`;
