import React, { Dispatch } from 'react';
import createDataContext from 'contexts/createDataContext';
import { TokenResponse } from 'dto/AuthDto';

const KEY_ACCESS_TOKEN = '@access_token';
const KEY_REFRESH_TOKEN = '@refresh_token';
const KEY_ARTIST = '@artist';

// 상태를 위한 타입
type State = {
  artist: string;
  accessToken: string;
};

// 모든 액션들을 위한 타입
type Action =
  | { type: 'INITIALIZED' }
  | { type: 'SET_ARTIST'; artist: string }
  | { type: 'SET_ACCESS_TOKEN'; token: string };

// 리듀서
const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'SET_ARTIST':
      return {
        ...state,
        artist: action.artist,
      };
    case 'SET_ACCESS_TOKEN':
      return {
        ...state,
        accessToken: action.token,
      };
    default:
      throw new Error('Unhandled action');
  }
};

export const setArtist = (dispatch: Dispatch<Action>) => {
  return (artist: string) => {
    try {
      localStorage.setItem(KEY_ARTIST, artist);

      dispatch({
        type: 'SET_ARTIST',
        artist: artist,
      });
    } catch (e) {
      console.log(e);
    }
  };
};

const resetLocal = () => {
  localStorage.setItem(KEY_ACCESS_TOKEN, '');
  localStorage.setItem(KEY_REFRESH_TOKEN, '');
};

const updateToken = (dispatch: Dispatch<Action>) => {
  return (token: TokenResponse) => {
    localStorage.setItem(KEY_ACCESS_TOKEN, token.access_token);
    if (token.refresh_token) {
      localStorage.setItem(KEY_REFRESH_TOKEN, token.refresh_token);
    }

    dispatch({
      type: 'SET_ACCESS_TOKEN',
      token: token.access_token,
    });
  };
};

const signOut = (dispatch: Dispatch<Action>) => {
  return () => {
    try {
      resetLocal();
      dispatch({
        type: 'SET_ACCESS_TOKEN',
        token: '',
      });
    } catch (error) {
      console.log('error signing out: ', error);
    }
  };
};

export const getRefreshToken = () => {
  const token = localStorage.getItem(KEY_REFRESH_TOKEN);
  return token || '';
};

export const getAccessToken = () => {
  const token = localStorage.getItem(KEY_ACCESS_TOKEN);
  return token || '';
};

export const resetArtist = (dispatch: Dispatch<Action>) => {
  return () => {
    try {
      console.log('resetArtist');
      sessionStorage.setItem(KEY_ARTIST, '');

      dispatch({
        type: 'SET_ARTIST',
        artist: '',
      });
    } catch (e) {
      console.log(e);
    }
  };
};

const getData = (key: string) => {
  const temp = localStorage.getItem(key);
  if (temp) {
    return temp;
  }

  return '';
};

export const { Provider, Context } = createDataContext({
  reducer,
  actions: {
    setArtist,
    resetArtist,
    updateToken,
    signOut,
  },
  defaultValue: {
    artist: getData(KEY_ARTIST),
    accessToken: getAccessToken(),
    inApp: false,
  },
});
