import {
  ReactNode,
  createContext,
  useReducer,
  useMemo,
  useCallback,
} from 'react';

const KEY_UI = '@ui';

interface Props {
  children: ReactNode;
}

interface UIInteractionReducerValue {
  hideBtmNavBar: boolean;
  topNavBgColor: string;
  topNavthemeIsWhite: boolean;
}

type UIInteractionReducerAction =
  | {
      type: 'UPDATE_BTMNAV';
      hide: boolean;
    }
  | {
      type: 'UPDATE_ARTISTCOLOR';
      bgColor: string;
      isWhite: boolean;
    };

interface UIInteractionAction {
  handleHideBtmNav: (hide: boolean) => void;
  handleTopNavStyles: (bgColor: string, isWhite: boolean) => void;
  resetTopNavStyles: () => void;
}

const init = () => {
  const value = sessionStorage.getItem(KEY_UI);
  if (value) {
    const { topNavBgColor, topNavthemeIsWhite } = JSON.parse(value);
    return { topNavBgColor, topNavthemeIsWhite };
  }

  const topNavBgColor = '';
  const topNavthemeIsWhite = false;
  return { topNavBgColor, topNavthemeIsWhite };
};

const defaultUIInteractionState = {
  hideBtmNavBar: true,
  topNavBgColor: init().topNavBgColor,
  topNavthemeIsWhite: init().topNavthemeIsWhite,
} as UIInteractionReducerValue;

export const UIInteractionValueContext = createContext<
  UIInteractionReducerValue | undefined
>(defaultUIInteractionState);

export const UIInteractionActionContext = createContext<
  UIInteractionAction | undefined
>(undefined);

const UIInteractionReducer = (
  state: UIInteractionReducerValue,
  action: UIInteractionReducerAction,
): UIInteractionReducerValue => {
  switch (action.type) {
    case 'UPDATE_BTMNAV':
      return { ...state, hideBtmNavBar: action.hide };

    case 'UPDATE_ARTISTCOLOR':
      sessionStorage.setItem(
        KEY_UI,
        JSON.stringify({
          topNavBgColor: action.bgColor,
          topNavthemeIsWhite: action.isWhite,
        }),
      );
      return {
        ...state,
        topNavBgColor: action.bgColor,
        topNavthemeIsWhite: action.isWhite,
      };

    default:
      return defaultUIInteractionState;
  }
};

function UIInteractionContext({ children }: Props) {
  const [state, dispatch] = useReducer(
    UIInteractionReducer,
    defaultUIInteractionState,
  );

  const handleHideBtmNav = useCallback((hide: boolean) => {
    dispatch({
      type: 'UPDATE_BTMNAV',
      hide: hide,
    });
  }, []);

  const handleTopNavStyles = useCallback(
    (bgColor: string, isWhite: boolean) => {
      dispatch({
        type: 'UPDATE_ARTISTCOLOR',
        bgColor: bgColor,
        isWhite: isWhite,
      });
    },
    [],
  );

  const resetTopNavStyles = useCallback(() => {
    dispatch({
      type: 'UPDATE_ARTISTCOLOR',
      bgColor: '',
      isWhite: true,
    });
  }, []);

  const action = useMemo(() => {
    return {
      handleHideBtmNav,
      handleTopNavStyles,
      resetTopNavStyles,
    };
  }, []);
  return (
    <UIInteractionActionContext.Provider value={action}>
      <UIInteractionValueContext.Provider value={state}>
        {children}
      </UIInteractionValueContext.Provider>
    </UIInteractionActionContext.Provider>
  );
}

export default UIInteractionContext;
