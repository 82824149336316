import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  ArtistThumbnailDto,
  CommonResponse,
  RootResponse,
} from 'dto/CommonDto';
import { ArtistService } from 'services/ArtistService';
import HeaderNavBar from 'components/common/HeaderNavBar';
import BottomButton from 'components/common/BottomButton';
import Image from 'components/common/Image';
import { Context as AuthContext } from 'contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { UserService } from 'services/UserService';
import { RESULT_CODE } from 'constants/ResultCode';
import useOverlay from 'hook/useOverlay';
import Colors from 'styles/color-system';
import { Utils } from '../../utils/Utils';

interface StyledProps {
  selected: boolean;
}

function ArtistChangePage() {
  const navigate = useNavigate();
  const {
    state: { artist },
    setArtist,
  } = useContext(AuthContext);

  const { showToast } = useOverlay();

  const [items, setItems] = useState<ArtistThumbnailDto[]>([]);
  const [selectedArtist, setSelectArtist] = useState<number>();

  useEffect(() => {
    setSelectArtist(parseInt(artist));

    ArtistService.root().then((response) => {
      const result = response.data as RootResponse;
      Utils.shuffle(result.artists);
      setItems(result.artists);
    });
  }, []);

  const handleSave = () => {
    UserService.changeArtist(selectedArtist!).then(({ data }) => {
      const result = data as CommonResponse;
      if (result.result === RESULT_CODE.SUCCESS) {
        setArtist('' + selectedArtist);
        showToast('아티스트를 저장했습니다.');
        navigate(-1);
      } else {
        showToast('아티스트 저장에 실패했습니다.');
      }
    });
  };

  return (
    <Container>
      <HeaderNavBar
        label="아티스트 변경"
        leftItem="BACK"
        bgColor={Colors.Background[500]}
        btmLine
      />
      <ContentBody>
        <ArtistList>
          {items.map((item) => (
            <ArtistItem
              key={item.name}
              onClick={() => setSelectArtist(item.id)}
              selected={item.id === selectedArtist}
            >
              <VisualWrapper selected={item.id === selectedArtist}>
                <ImageWrapper>
                  <Image src={item.thumbnail} />
                </ImageWrapper>
              </VisualWrapper>
              <ArtistName>{item.name}</ArtistName>
            </ArtistItem>
          ))}
        </ArtistList>
        <Description>* 1명의 아티스트 선택을 해주세요</Description>
      </ContentBody>
      <ButtonWrapper>
        <BottomButton
          label="취소하기"
          sort="GRAY"
          onClick={() => navigate(-1)}
        />
        <BottomButton label="변경하기" onClick={handleSave} />
      </ButtonWrapper>
    </Container>
  );
}

export default ArtistChangePage;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const ContentBody = styled.div`
  flex: 1;
  padding-inline: 24px;
  box-sizing: border-box;
`;

const ArtistList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  width: 100%;
  margin-top: 35px;
`;

const ArtistItem = styled.li<StyledProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  width: calc((100% - 30px) / 3);
  cursor: pointer;
  user-select: none;
  opacity: ${({ selected }) => (selected ? 1 : 0.5)};
  transition: opacity 200ms ease-in-out;
`;

const VisualWrapper = styled.div<StyledProps>`
  position: relative;
  width: 100%;

  &::after {
    content: '';
    display: block;
    width: 100%;
    padding-bottom: 100%;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 2px solid ${Colors.Primary[500]};
    box-sizing: border-box;
    opacity: ${({ selected }) => (selected ? 1 : 0)};
    transition: opacity 200ms ease-in-out;
  }
`;

const ImageWrapper = styled.div`
  position: absolute;
  top: 6px;
  left: 6px;
  width: calc(100% - 12px);
  height: calc(100% - 12px);
  border-radius: 50%;
  overflow: hidden;
  z-index: 100;
`;

const ArtistName = styled.span`
  color: ${Colors.Gray[100]};
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.02em;
`;

const Description = styled.span`
  display: block;
  color: ${Colors.Primary[500]};
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.02em;
  margin-top: 35px;
`;

const ButtonWrapper = styled.div`
  display: flex;
`;
