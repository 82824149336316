const PATH = {
  Root: '/root',

  Home: '/',

  RedirectArtist: '/artist/:key',
  RedirectProduct: '/redirect/product/:productId',

  Feeds: '/feeds',
  FeedDetail: '/feeds/:feedId',

  Notices: '/notices',
  NoticeDetail: ':noticeId',

  Schedules: '/schedules',

  SignIn: '/sign-in',
  SignUp: '/sign-up',

  Shop: '/shop',
  ShopDetail: ':productId',

  Cart: '/cart',
  Orders: '/orders',
  OrderDetail: ':orderId',
  OrderCancel: 'cancel',
  OrderAdminDetail: '/admin/orders/:uuid',
  OrderAdminError: '/admin/orders/error',

  OrderRequest: 'request',
  OrderPayment: 'payment', // 결제 팝업 용
  OrderComplete: 'complete',

  Settings: '/settings',
  CustomerService: '/customerService',
  ArtistChange: '/artist/change',

  Terms: '/terms',
  Privacy: '/privacy',

  CollectionRegister: '/collection/register/:serial',
  Collections: '/collections',
  CollectionDetail: ':collectionId',

  TicketSeat: '/ticket-seat/:uuid',

  Error: '*',
};
export default PATH;
