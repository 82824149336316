import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useLocation, useNavigate, useParams, type To } from 'react-router-dom';
import { ProductDetailDto } from 'dto/ProductDto';
import { ArtistService } from 'services/ArtistService';
import HeaderNavBar from 'components/common/HeaderNavBar';
import ShopDetailTopSection from 'components/shop/ShopDetailTopSection';
import ShopDetailMiddleSection from 'components/shop/ShopDetailMiddleSection';
import ShopDetailBottomSection from 'components/shop/ShopDetailBottomSection';
import BottomButton from 'components/common/BottomButton';
import ShopDetailBottomDrawer from 'components/shop/ShopDetailBottomDrawer';
import { SelectedOptionItemDto } from 'dto/CartDto';
import { OrderService } from 'services/OrderService';
import { CommonResponse } from 'dto/CommonDto';
import { RESULT_CODE } from 'constants/ResultCode';
import BasicPopup from 'components/overlays/BasicPopup';
import PATH from 'Path';
import { Context as AuthContext } from 'contexts/AuthContext';
import useOverlay from 'hook/useOverlay';
import { ShopDetailResponse } from 'dto/ShopDto';
import { Option } from 'components/common/Select';

type SalesStatus = 'BEFORE' | 'ONGOING' | 'AFTER';

type LocationState = {
  isBack: boolean;
};

function ShopDetailPage() {
  const navigate = useNavigate();

  const {
    state: { artist, accessToken },
    setArtist,
  } = useContext(AuthContext);

  const {
    showToast,
    showHandler: showOverlay,
    hideHandler: hideOverlay,
  } = useOverlay();

  const { state: locationState } = useLocation();

  const { productId } = useParams();
  const [shopDetail, setShopDetail] = useState<ProductDetailDto>(
    {} as ProductDetailDto,
  );

  // const [delivery, setDelivery] = useState<DeliveryDefaultDto>(
  //   {} as DeliveryDefaultDto,
  // );

  const [showOption, setShowOption] = useState(false);
  const [disableCartButton, setDisableCartButton] = useState(false);

  const [options, setOptions] = useState<Option[]>([]);
  const [selectedItems, setSelectedItems] = useState<SelectedOptionItemDto[]>(
    [],
  );
  const [salesStatus, setSalesStatus] = useState<SalesStatus>('BEFORE');
  const [soldOut, setSoldOut] = useState(false);

  useEffect(() => {
    if (productId) {
      ArtistService.shopDetail(parseInt(productId)).then((response) => {
        const result = response.data as ShopDetailResponse;

        if (!result.product.publish) {
          navigate(-1);
          return;
        }

        setShopDetail(result.product);
        // setDelivery(result.delivery);

        if (result.product.options) {
          setOptions(
            result.product.options.map((option) => {
              // 상품 재고로 체크 할때
              let soldOut = false;
              let title = '';
              if (option.stock > 0) {
                const count =
                  option.stock - (option.condition ? option.condition : 0);
                soldOut = count <= 0;
                title =
                  option.title +
                  ' (' +
                  (soldOut ? '품절' : `잔여:${count}개`) +
                  ')';
              } else {
                title = option.title;
              }

              return { title: title, soldOut: soldOut };
            }),
          );
        }

        if (!artist) {
          setArtist(result.product.artist_id);
        }
      });
    } else {
      handleBack();
    }

    return () => {};
  }, [productId]);

  const handleBack = () => {
    navigate(-1);
  };

  const onHideOptionSlide = () => {
    setShowOption(false);
  };

  const handleCartBtnState = () => {
    if (salesStatus === 'BEFORE') {
      return { state: true, label: '판매 시작 전입니다.' };
    }

    if (salesStatus === 'AFTER' || shopDetail.condition >= shopDetail.stock) {
      return { state: true, label: '판매가 종료되었습니다.' };
    }

    return { state: disableCartButton, label: '장바구니 담기' };
  };

  const onSelectOption = (value: string) => {
    if (!value) return;

    let totalCount = 0;
    for (const item of selectedItems) {
      if (value.indexOf(item.title) === 0) {
        showToast('이미 선택된 옵션입니다.');
        return;
      }

      totalCount += item.count;
    }

    if (shopDetail.max <= totalCount) {
      showToast('최대 구매 제한을 초과 합니다.');
      return;
    }

    let option = shopDetail.options.find(
      (prev) => value.indexOf(prev.title) === 0,
    );
    setSelectedItems((prev) => {
      return [
        ...prev,
        {
          product_id: shopDetail.id,
          option_id: option ? option.id : 0,
          count: 1,
          title: option ? option.title : value,
          amount: shopDetail.amount.amount,
          max: shopDetail.max,
        },
      ];
    });
  };

  useEffect(() => {
    if (!showOption) {
      setDisableCartButton(false);
      return;
    }

    setDisableCartButton(selectedItems.length === 0);
  }, [selectedItems, showOption]);

  const onClickCart = () => {
    if (!accessToken) {
      const popupId = showOverlay(
        <BasicPopup
          message={'로그인이 필요합니다.\n로그인 하시겠습니까?'}
          onLeftClick={() => {
            hideOverlay(popupId);
          }}
          onRightClick={() => {
            hideOverlay(popupId);
            navigate(PATH.SignIn);
          }}
        />,
        'POPUP',
      );
      return;
    }

    if (showOption) {
      OrderService.addCart({
        items: selectedItems,
      }).then((response) => {
        const result = response.data as CommonResponse;
        console.log(result);
        if (result.result === RESULT_CODE.SUCCESS) {
          showToast('상품을 추가했습니다.');
          if (locationState) {
            const { isBack } = locationState as LocationState;
            if (isBack) {
              navigate(-1);
              return;
            }
          }

          navigate('..');
        }
      });
      return;
    }

    if (options.length === 0) {
      onSelectOption(shopDetail.title);
    }

    setShowOption(true);
  };

  const onUpdateCartItem = (item: SelectedOptionItemDto) => {
    console.log('onUpdateCartItem');
    const items = [...selectedItems];
    const selectIndex = items.findIndex(
      (it) => it.option_id === item.option_id,
    );
    items[selectIndex] = item;

    let totalCount = 0;
    for (const item of items) {
      totalCount += item.count;
    }

    if (shopDetail.max < totalCount) {
      showToast('최대 구매 갯수를 초과 합니다.');
      return;
    }

    setSelectedItems(items);
  };

  const onCancelCartItem = (item: SelectedOptionItemDto) => {
    const items = [...selectedItems];
    const selectIndex = items.findIndex(
      (it) => it.option_id === item.option_id,
    );
    items.splice(selectIndex, 1);

    setSelectedItems(items);
  };

  return (
    <Container>
      <HeaderNavBar leftItem="BACK" />
      <ContentBody>
        <ShopDetailTopSection
          data={shopDetail}
          // delivery={delivery}
          salesStatus={salesStatus}
          soldOut={soldOut}
          setSoldOut={setSoldOut}
          setSalesStatus={setSalesStatus}
        />
        <ShopDetailMiddleSection data={shopDetail} />
        <ShopDetailBottomSection />
      </ContentBody>
      <ShopDetailBottomDrawer
        isShow={showOption}
        selectedItems={selectedItems}
        options={options}
        onSelectOption={onSelectOption}
        onHide={onHideOptionSlide}
        onUpdate={onUpdateCartItem}
        onCancel={onCancelCartItem}
      />
      <BottomButton
        label={handleCartBtnState().label}
        onClick={onClickCart}
        state={handleCartBtnState().state ? 'DISABLE' : 'DEFAULT'}
      />
    </Container>
  );
}

export default ShopDetailPage;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  height: 100%;
`;

const ContentBody = styled.div`
  flex: 1;
  overflow: hidden auto;
`;
