import { ReactNode } from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import styled, { css } from 'styled-components';

interface Props {
  children: ReactNode;
  location: {
    hash: string;
    key: string;
    pathname: string;
    state: {} | null;
  };
}

const speed = 200;

function TransitionContainer({ children, location }: Props) {
  const { pathname } = location;

  return (
    <Container>
      <TransitionGroup>
        <CSSTransition key={pathname} classNames="slide" timeout={speed}>
          {children}
        </CSSTransition>
      </TransitionGroup>
    </Container>
  );
}

export default TransitionContainer;

const SlideMotion = css`
  & [class*='slide-'] {
    transition: transform ${speed}ms ease-in-out;
  }

  & .slide-enter {
    opacity: 0;
    transform: translateX(100%);
  }

  & .slide-enter-active {
    opacity: 1;
    transform: translateX(0);
  }

  & .slide-exit {
    opacity: 1;
    transform: translateX(0);
  }

  & .slide-exit-active {
    opacity: 0;
    transform: translateX(-100%);
  }
`;

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 100%;
  box-sizing: border-box;
  ${SlideMotion};
  overflow: hidden;

  & > div {
    width: 100%;
    height: 100%;
  }
`;
