export enum PAYMENT_TYPE {
  CARD = 'CARD',
  VIRTUAL = 'VIRTUAL', // 가상계좌
  TOSSPAY = 'TOSSPAY', // 토스페이
  TRANSFER = 'TRANSFER', // 계좌이체
  PHONE = 'PHONE',
}

export enum PAYMENT_TYPE_VALUES {
  CARD = '카드',
  VIRTUAL = '가상계좌',
  TOSSPAY = '토스페이',
  PHONE = '휴대폰',
  TRANSFER = '계좌이체',
}
