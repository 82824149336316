import { Fragment, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import PATH from 'Path';
import useUIInteractionValue from 'hook/useUIInteractionValue';
import { ReactComponent as FanusSymbol } from 'assets/icon/fanus-logo-symbol.svg';
import { ReactComponent as FanusText } from 'assets/icon/fanus-logo-text.svg';
import { ReactComponent as Cart } from 'assets/icon/cart.svg';
import { ReactComponent as Back } from 'assets/icon/back.svg';
import Colors from 'styles/color-system';
import { Context as AuthContext } from 'contexts/AuthContext';
import { ArtistService } from 'services/ArtistService';
import { ThemeResponse } from 'dto/HomeDto';
import useUIInteractionAction from 'hook/useUIInteractionAction';

interface Props {
  bgColor?: string;
  btmLine?: boolean;
  leftItem?: 'BACK' | 'LOGO' | 'FULL_LOGO' | 'NONE';
  rightItem?: 'CART' | 'NONE';
  label?: string;
}

interface StyledProps {
  textColor?: boolean;
  bgColor?: string;
  btmLine?: boolean;
}

type LocationState = {
  isBack: boolean;
};

function HeaderNavBar({ bgColor, leftItem, rightItem, label, btmLine }: Props) {
  const navigate = useNavigate();
  const { topNavBgColor, topNavthemeIsWhite } = useUIInteractionValue();
  const topNavTheme = bgColor ? true : topNavthemeIsWhite;
  const iconFill = topNavTheme
    ? 'rgba(255, 255, 255, 0.80)'
    : 'rgba(48, 49, 55, 0.80)';

  const { handleTopNavStyles } = useUIInteractionAction();

  const {
    state: { artist },
  } = useContext(AuthContext);

  const { state: locationState } = useLocation();

  useEffect(() => {
    if (artist && !topNavBgColor) {
      console.log(artist);
      console.log(topNavBgColor);

      ArtistService.theme(artist).then((response) => {
        const result = response.data as ThemeResponse;
        handleTopNavStyles(result.color, result.is_white);
      });
    }
  }, [artist, topNavBgColor]);

  const clickLeft = () => {
    if (leftItem === 'LOGO') {
      navigate(PATH.Root);
      return;
    }

    if (leftItem === 'BACK') {
      if (locationState) {
        const { isBack } = locationState as LocationState;
        if (isBack) {
          navigate(-1);
          return;
        }
      }

      navigate('..');
      return;
    }
  };

  const clickRight = () => {
    navigate(PATH.Cart);
  };

  const leftSection = () => {
    if (leftItem !== 'NONE') {
      return (
        <LeftSection onClick={clickLeft}>
          {leftItem === 'BACK' && <Back fill={iconFill} />}
          {leftItem === 'LOGO' && <FanusSymbol fill={iconFill} />}
          {leftItem === 'FULL_LOGO' && (
            <Fragment>
              <FanusSymbol fill="url(#fanusGradient)" />
              <FanusText />
            </Fragment>
          )}
        </LeftSection>
      );
    }
    return <Fragment />;
  };
  const rightSection = () => {
    if (rightItem === 'CART') {
      return (
        <RightSection onClick={clickRight}>
          <Cart fill={iconFill} />
        </RightSection>
      );
    }
    return <Fragment />;
  };
  return (
    <Container btmLine={btmLine}>
      {leftSection()}
      {label && <Label textColor={topNavTheme}>{label}</Label>}
      {rightSection()}
      <BackGround bgColor={bgColor ? bgColor : `#${topNavBgColor}`} />
    </Container>
  );
}

export default HeaderNavBar;

HeaderNavBar.defaultProps = {
  btmLine: false,
  leftItem: 'NONE',
  rightItem: 'NONE',
  label: '',
};

const Container = styled.div<StyledProps>`
  position: relative;
  width: 100%;
  height: 68px;
  min-height: 68px;

  border-bottom: ${({ btmLine }) => btmLine && `1px solid ${Colors.Gray[600]}`};
`;

const BackGround = styled.div<StyledProps>`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  background: ${({ bgColor }) => `linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.15) 0%,
    rgba(0, 0, 0, 0) 60.94%
  ), ${bgColor}`};
`;

const Label = styled.span<StyledProps>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  color: ${({ textColor }) =>
    textColor ? `${Colors.White[500]}` : `${Colors.Gray[700]}`};
  font-size: 20px;
  font-weight: 700;
  letter-spacing: -0.02em;
  z-index: 100;
`;

const LeftSection = styled.div`
  position: absolute;
  top: 50%;
  left: 16px;
  transform: translate3d(0, -50%, 0);
  z-index: 100;
  cursor: pointer;
  user-select: none;

  display: flex;
  align-items: center;
  gap: 8px;

  & .fanus-text-type {
    margin-top: 3px;
  }
`;

const RightSection = styled.div`
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translate3d(0, -50%, 0);
  z-index: 100;
  cursor: pointer;
  user-select: none;
`;
