// import { getAccessToken } from 'contexts/AuthContext';
import PATH from '../Path';

// const isSignIn = (): boolean => {
//   return getAccessToken().length > 0;
// };

const subNavigationPath = (path: string, id: string) => {
  return `${path}/${id}`;
};

const convertCounterToTime = (counter: number) => {
  const minutes: number = Math.floor(counter / 60);
  const seconds: number = Math.floor(counter % 60);

  return `${minutes.toString().padStart(2, '0')}분 ${seconds
    .toString()
    .padStart(2, '0')}초`;
};

const groupBy = <T extends Record<PropertyKey, any>>(
  arr: T[],
  key: keyof T,
): any => {
  return arr.reduce((accumulator, val) => {
    const groupedKey = val[key];
    if (!accumulator[groupedKey]) {
      accumulator[groupedKey] = [];
    }
    accumulator[groupedKey].push(val);
    return accumulator;
  }, {} as any);
};

const printError = (error: any) => {
  if (error.response) {
    // Request made and server responded
    console.log('printError');
    console.log(JSON.stringify(error.response));
    // console.log(error.response.data);
    // console.log(error.response.status);
    // console.log(error.response.headers);
  } else if (error.request) {
    // The request was made but no response was received
    console.log(error.request);
  } else {
    // Something happened in setting up the request that triggered an Error
    console.log('Error', error.message);
  }
};

const convertNumberOptions = (len: number): string[] => {
  return Array.from({ length: len + 1 }, (v, i) => `${i}`);
};

const loadFromSession = <T>(key: string, def?: T) => {
  const temp = sessionStorage.getItem(key);
  if (temp) {
    return JSON.parse(temp) as T;
  }

  return def;
};

const saveToSession = <T>(key: string, obj: T) => {
  sessionStorage.setItem(key, JSON.stringify(obj));
};

const generateId = (): number => {
  return -Math.floor(Math.random() * 1000000);
};
// const START_DAY = Number(process.env.REACT_APP_LIMIT_RESERVATION_START_DAY);
// const LAST_DAY = Number(process.env.REACT_APP_LIMIT_RESERVATION_LAST_DAY);
// const generateStartTime = (roundings?: RoundingInfo[]): number => {
//   let date: Date;
//   if (roundings) {
//     // 라운딩 일정이 있을 경우 중복 되지 않도록
//     if (roundings.length === 1) {
//       date = addDays(convertTimeToDate(roundings[0].time), 1);
//     } else {
//       const last = convertTimeToDate(roundings[roundings.length - 1].time);
//       date = addDays(convertTimeToDate(roundings[0].time), 1);
//       while (date < last) {
//         // eslint-disable-next-line no-loop-func
//         const exists = roundings.find((r: RoundingInfo) =>
//           Utils.sameDate(convertTimeToDate(r.time), date),
//         );
//         if (!exists) {
//           break;
//         }
//         date = addDays(date, 1);
//       }
//       if (date > last) {
//         return 0;
//       }
//     }
//   } else {
//     date = addDays(new Date(), START_DAY);
//     date.setMinutes(0);
//     date.setSeconds(0, 0);
//   }
//   return convertDateToTime(date);
// };

// const PLAY_TIME = Number(process.env.REACT_APP_GOLF_PLAY_TIME);
// const generateLastTime = (): number => {
//   const date = addDays(new Date(), START_DAY);
//   date.setMinutes(0);
//   date.setSeconds(0, 0);
//   date.setHours(date.getHours() + PLAY_TIME);
//   return convertDateToTime(date);
// };

// const generateLimitStartDate = (roundings?: RoundingInfo[]): Date => {
//   let date: Date;
//   if (roundings && roundings.length > 0) {
//     // 라운딩 일정이 있을 경우 중복 되지 않도록
//     date = addDays(convertTimeToDate(roundings[0].time), 1);
//   } else {
//     date = addDays(new Date(), START_DAY);
//   }
//   date.setHours(0);
//   date.setMinutes(0);
//   date.setSeconds(0, 0);
//   return date;
// };

// const generateLimitLastDate = (
//   type: DELIVERY_TYPE,
//   roundings?: RoundingInfo[],
// ): Date => {
//   let date: Date;
//   if (roundings && roundings.length > 0) {
//     if (type === DELIVERY_TYPE.ONE) {
//       date = addDays(convertTimeToDate(roundings[0].time), LAST_DAY);
//     } else {
//       // 라운딩 일정이 있을 경우 중복 되지 않도록
//       date = subDays(
//         convertTimeToDate(roundings[roundings.length - 1].time),
//         1,
//       );
//     }
//   } else {
//     date = addDays(new Date(), LAST_DAY);
//   }
//   date.setHours(23);
//   date.setMinutes(59);
//   date.setSeconds(59, 59);
//   return date;
// };

const convertPhoneAddedHyphen = (phone: string) => {
  if (!phone) {
    return '';
  }

  if (phone.length === 12) {
    return phone.replace(/^(\d{4})(\d{4})(\d{4})$/, `$1-$2-$3`);
  }

  if (phone.substring(0, 2) === '02') {
    return phone.replace(/^(\d{2})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
  }

  return phone.replace(/^(\d{3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
};

const convertPhoneRemovedHyphen = (phone: string) => {
  return phone.replace(/-/g, '');
};

// const assignAddress = (address: AddressInfo) => {
//   return `${address.address0} ${address.address1}`;
// };
//
// const assignFriendAddress = (friend: FriendInfo) => {
//   return `${friend.address0} ${friend.address1}`;
// };

const convertCurrency = (won = 0) => {
  let value = '0';
  if (won > 0) {
    let cutting = Math.floor(won / 100) * 100;
    value = cutting.toLocaleString('ko-KR');
  }
  return value;
};

const EMAIL_REGEX = /([a-z0-9]+@[a-z]+\.[a-z]{2,3})(\]?)$/;
const validateEmail = (email: string) => {
  if (email === '') {
    return false;
  }

  if (!EMAIL_REGEX.test(email)) {
    return false;
  }

  return true;
};

const calcShopItemState = (startTime: number, endTime: number) => {
  const now = Date.now();
  const convert = (time: number) => {
    return time.toString().length === 13 ? time : time * 1000;
  };
  const start = convert(startTime);
  const end = convert(endTime);

  if (start <= now && end > now) {
    // 판매중
    return 'ONGOING';
  }

  if (start > now) {
    // 판매예정
    return 'BEFORE';
  }

  if (end <= now) {
    // 판매종료
    return 'AFTER';
  }
};

const currentUrl = () => {
  return window.location.origin + window.location.pathname;
};

const shuffle = <T extends Record<PropertyKey, any>>(array: T[]) => {
  array.sort(() => Math.random() - 0.5);
};

const isInApp = () => {
  return /inApp/.test(window.navigator.userAgent);
};

export const Utils = {
  subNavigationPath,
  convertCounterToTime,
  groupBy,
  printError,
  convertNumberOptions,
  saveToSession,
  loadFromSession,
  generateId,
  convertPhoneAddedHyphen,
  convertPhoneRemovedHyphen,
  convertCurrency,
  validateEmail,
  calcShopItemState,
  currentUrl,
  shuffle,
  isInApp,
};
