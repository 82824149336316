import { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import { ArtistService } from 'services/ArtistService';
import { RedirectArtistResponse } from 'dto/AuthDto';
import PATH from 'Path';
import { Context as AuthContext } from 'contexts/AuthContext';

export const RedirectArtistPage = () => {
  const { key } = useParams();
  const navigate = useNavigate();
  const { setArtist } = useContext(AuthContext);

  useEffect(() => {
    if (key) {
      ArtistService.redirectArtist(key).then(({ data }) => {
        const result = data as RedirectArtistResponse;
        if (result.id > 0) {
          setArtist(result.id);
          navigate(PATH.Home, { replace: true });
          return;
        }

        navigate(PATH.Root, { replace: true });
      });
      return;
    }
    navigate(PATH.Root, { replace: true });
  }, []);

  return <Container></Container>;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: linear-gradient(137deg, #4122ff 0%, #932eff 100%);
`;
