import styled from 'styled-components';
import Colors from 'styles/color-system';

interface Props {
  onClick: () => void;
  show?: boolean;
}

function MoreView({ onClick, show = true }: Props) {
  return show ? (
    <Container type="button" onClick={onClick}>
      더보기
    </Container>
  ) : (
    <HideContainer />
  );
}

export default MoreView;

const Container = styled.button`
  display: block;
  padding: 6px 13px 4px;
  margin-top: 45px;
  margin-bottom: 90px;
  margin-inline: auto;
  border-radius: 14.5px;
  box-sizing: border-box;
  overflow: hidden;
  background: #656774;
  color: ${Colors.Background[500]};
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.02em;
  cursor: pointer;
  user-select: none;
`;

const HideContainer = styled(Container)`
  background: transparent;
  padding: initial;
`;
