import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import Colors from 'styles/color-system';
import HeaderNavBar from 'components/common/HeaderNavBar';
import Image from 'components/common/Image';
import Stage from 'assets/img/img-stage-smj.png';
import { ReactComponent as LocationIcon } from 'assets/icon/location.svg';

function TicketSeatPage() {
  const { uuid } = useParams();
  const [seatNum, setSeatNum] = useState<string | undefined>(undefined);

  useEffect(() => {
    const reqeust = async () => {
      const res = await fetch(
        'https://ds8dul99zsjkz.cloudfront.net/json/smj-concert-seat-list.json',
      );
      if (res.ok) {
        const data = await res.json();
        const index = Object.keys(data).findIndex(
          (key) => data[key].order_id === uuid,
        );
        const conveatSeatNum: string | undefined = data[index]?.seat_number;
        // .split(',')
        // .map((seat: string) => seat.trim());

        setSeatNum(conveatSeatNum);
      }
    };

    reqeust();
  }, []);
  return (
    <Container>
      <HeaderNavBar
        leftItem="BACK"
        label="좌석 확인"
        btmLine
        bgColor={Colors.Background[500]}
      />
      <ContentSection>
        <MapSection>
          <div className="location">
            <LocationIcon />
            동자 아트홀
          </div>
          <Image src={Stage} />
        </MapSection>
        <SeatNumberSection>
          {seatNum ? (
            <>
              고객님의 좌석은
              <br />
              <span className="seat-number">{seatNum}</span>
              &nbsp;입니다.
            </>
          ) : (
            '준비중입니다.'
          )}
        </SeatNumberSection>
        <InfoSection>
          <p className="title">확인 및 유의 사항</p>
          <ul>
            <li className="txt">
              지정된 좌석은 변경,취소,양도가 불가능합니다.
            </li>
            <li className="txt">
              본 좌석배치도는 이해를 돕기 위한 이미지이며 사정에 따라 무대
              구성이 사전 공지 없이 변경될 수 있습니다.
            </li>
          </ul>
        </InfoSection>
      </ContentSection>
    </Container>
  );
}

export default TicketSeatPage;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 100%;
`;

const ContentSection = styled.div`
  flex: 1;
  overflow: hidden auto;
`;

const MapSection = styled.div`
  margin-top: 26px;
  width: 100%;
  padding-inline: 12px;
  box-sizing: border-box;

  .location {
    display: flex;
    justify-content: center;
    gap: 6px;
    margin-bottom: 23px;
    color: ${Colors.Gray[100]};
    font-size: 17px;
    font-weight: 500;
    line-height: normal;
  }
`;

const SeatNumberSection = styled.p`
  width: 100%;
  margin-block: 26px;
  padding-inline: 24px;
  box-sizing: border-box;
  text-align: center;
  color: ${Colors.Gray[100]};
  font-size: 28px;
  font-weight: 400;
  line-height: normal;

  .seat-number {
    color: ${Colors.Primary[500]};
    font-weight: 700;
  }
`;

const InfoSection = styled.div`
  position: relative;
  width: 100%;
  padding-top: 20px;
  padding-inline: 24px;
  padding-bottom: 80px;
  box-sizing: border-box;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    width: calc(100% - 48px);
    height: 1px;
    background: ${Colors.Gray[700]};
  }

  .title {
    color: ${Colors.Gray[200]};
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.32px;
    margin-bottom: 15px;
  }

  ul li.txt:not(:last-child) {
    margin-bottom: 6px;
  }

  .txt {
    color: ${Colors.Gray[300]};
    font-size: 14px;
    list-style: disc;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.28px;
    margin-left: 16px;
  }
`;
