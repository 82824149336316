import React, { useEffect } from 'react';
import styled from 'styled-components';
import Image from 'components/common/Image';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import 'swiper/css';
import { ReactComponent as NavIcon } from 'assets/icon/chevron-right02.svg';
import { CollectionItemDto } from 'dto/CollectionDto';

interface Props {
  list?: CollectionItemDto[];
  currentId: number;
  onClose: () => void;
}

function GalleryPopup({ list = [], currentId, onClose }: Props) {
  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);

  useEffect(() => {
    if (list.length <= 0) {
      onClose();
    }
  }, []);

  const handleClose = () => {
    onClose();
  };

  return (
    <Container>
      <ContentSection>
        <SwiperWrapper
          initialSlide={currentId - 1}
          loop
          navigation={{
            prevEl: navigationPrevRef.current,
            nextEl: navigationNextRef.current,
          }}
          modules={[Navigation]}
        >
          {list.map((item) => (
            <SwiperItem key={item.id}>
              <Image src={item.url} />
            </SwiperItem>
          ))}
          <PrevButton ref={navigationPrevRef}>
            <NavIcon stroke="#ffffff99" />
          </PrevButton>
          <NextButton ref={navigationNextRef}>
            <NavIcon stroke="#ffffff99" />
          </NextButton>
        </SwiperWrapper>
      </ContentSection>
      <Close onClick={handleClose}>닫기</Close>
      <Outer onClick={handleClose} />
    </Container>
  );
}

export default GalleryPopup;

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  pointer-events: auto;

  & .swiper-button-prev {
    left: 16px;
  }

  & .swiper-button-prev svg {
    transform: rotate(180deg);
  }

  & .swiper-button-next {
    right: 16px;
  }
`;

const Close = styled.div`
  position: absolute;
  top: 18px;
  right: 17px;
  width: 38px;
  height: 31px;
  color: #fff;
  text-align: right;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.32px;
  text-decoration-line: underline;
  text-underline-offset: 0.2em;
  cursor: pointer;
  user-select: none;
  z-index: 100;
`;

const Outer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
`;

const ContentSection = styled.div`
  position: absolute;
  top: 50%;
  transform: translate3d(0, -50%, 0);
  width: 100%;
  height: fit-content;
  z-index: 100;
  pointer-events: none;
`;

const SwiperWrapper = styled(Swiper)`
  & .swiper-wrapper {
    align-items: center;
  }
`;

const SwiperItem = styled(SwiperSlide)`
  position: relative;
  width: 100%;
  pointer-events: auto;
`;

const NavBtnLayout = styled.div`
  position: absolute;
  top: 50%;
  transform: translate3d(0, -50%, 0);
  pointer-events: auto;
  z-index: 110;
  cursor: pointer;
  user-select: none;
  & svg {
    -webkit-filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.25));
    filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.25));
  }
`;

const NextButton = styled(NavBtnLayout)`
  right: 16px;
`;

const PrevButton = styled(NavBtnLayout)`
  left: 16px;

  & svg {
    transform: rotate(180deg);
  }
`;
