import { createGlobalStyle } from 'styled-components';
import { reset } from 'styled-reset';
import Colors from 'styles/color-system';

const GlobalStyle = createGlobalStyle`
${reset};

:root {
    /* --gobal-width: calc(clamp(0.888888px, 0.277778vw, 1.18056px) * 360); */
    --gobal-width: clamp(320px, 100vw, 500px);
    --skeleton-color: ${Colors.Gray[700]};
    -webkit-tap-highlight-color: transparent; 
}

html {
    width: var(--gobal-width);
    margin: 0 auto;
    background: #202124;
    height: 100%;
};

body, #root {
    position: relative;
    width: 100%;
    height: 100%;
    background: ${Colors.Background[500]};
    font-family: "Pretendard Variable", Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
}

body {
    button {
        border: initial;
        margin: initial;
        padding: initial;
        background: initial;
    }

    h3 {
        margin: initial;
        padding: initial;
    }
}

`;

export { GlobalStyle };
