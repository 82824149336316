export const KEY_PRODUCT_ID = '@product_id';

export enum PRODUCT_TYPE {
  GOODS = 'GOODS',
  PHOTO_CARD = 'PHOTO_CARD',
  TICKET = 'TICKET',
  ETC = 'ETC',
}

export enum PRODUCT_TYPE_LABELS {
  GOODS = '굿즈',
  PHOTO_CARD = '포토카드',
  TICKET = '티켓',
  ETC = '기타',
}
