import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';
import styled, { css } from 'styled-components';
import { OverlayItem } from 'types/overlay-types';

interface Props {
  items: OverlayItem[];
}

const dimSpeed = 200;

function RenderOverlay({ items }: Props) {
  const portalElement = document.getElementById('overlays');
  const [dimIsShown, SetDimIsShown] = useState(false);
  useEffect(() => {
    const notToastLength = items.filter(
      ({ type }: OverlayItem) => type !== 'TOAST',
    );

    if (notToastLength.length >= 1) {
      SetDimIsShown(true);
    } else if (dimIsShown) {
      SetDimIsShown(false);
    }
  }, [items, dimIsShown]);

  return (
    <React.Fragment>
      {items.length !== 0 &&
        ReactDOM.createPortal(
          <Outside>
            {items.map((item) => (
              <div key={item.id}>{React.cloneElement(item.element, item)}</div>
            ))}
          </Outside>,
          portalElement as HTMLElement,
        )}
      {ReactDOM.createPortal(
        <CSSTransition in={dimIsShown} timeout={dimSpeed} unmountOnExit>
          <Dim />
        </CSSTransition>,
        portalElement as HTMLElement,
      )}
    </React.Fragment>
  );
}

export default RenderOverlay;

const Layout = css`
  position: fixed;
  width: var(--gobal-width);
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
`;

const Outside = styled.div`
  ${Layout}
  z-index: 900;
  pointer-events: none;
`;

const Dim = styled.div`
  ${Layout}
  z-index: 800;
  background: rgba(0, 0, 0, 0.7);

  &.enter {
    opacity: 0;
  }

  &.enter-active {
    opacity: 1;
    transition: opacity ${dimSpeed}ms ease-in-out;
  }

  &.exit {
    opacity: 1;
  }

  &.exit-active {
    opacity: 0;
    transition: opacity ${dimSpeed}ms ease-in-out;
  }
`;
