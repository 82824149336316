import { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { CSSTransition } from 'react-transition-group';
import { useNavigate, useLocation } from 'react-router-dom';
import PATH from 'Path';
import useUIInteractionValue from 'hook/useUIInteractionValue';
import { ReactComponent as Home } from 'assets/icon/home.svg';
import { ReactComponent as Schedule } from 'assets/icon/schedule.svg';
import { ReactComponent as Shop } from 'assets/icon/shop.svg';
import { ReactComponent as Myinfo } from 'assets/icon/myinfo.svg';
import Colors from 'styles/color-system';

interface StyledProps {
  active?: boolean;
}

const navList = [
  {
    icon: <Home />,
    label: '홈',
    path: PATH.Home,
  },
  {
    icon: <Schedule />,
    label: '스케줄',
    path: PATH.Schedules,
  },
  {
    icon: <Shop />,
    label: '쇼핑',
    path: PATH.Shop,
  },
  {
    icon: <Myinfo />,
    label: '내정보',
    path: PATH.Settings,
  },
];

const speed = 200;

function BottomNavBar() {
  const location = useLocation();
  const navigate = useNavigate();
  const { hideBtmNavBar } = useUIInteractionValue();
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const path = location.pathname;
    navList.map((item, index) => {
      if (item.path === path) {
        setCurrentIndex(index);
        return;
      }
      if (path.includes(item.path)) {
        setCurrentIndex(index);
        return;
      }
    });
  }, [location.pathname]);

  const clickHandler = (path: string) => {
    navigate(path);
  };

  return (
    <Container
      in={!hideBtmNavBar}
      timeout={speed}
      unmountOnExit
      classNames="btm-nav-bar"
    >
      <div>
        {navList.map((item, index) => (
          <Section
            onClick={() => clickHandler(item.path)}
            active={currentIndex === index}
            key={item.label}
          >
            {item.icon}
            <Label>{item.label}</Label>
          </Section>
        ))}
      </div>
    </Container>
  );
}

export default BottomNavBar;

const Container = styled(CSSTransition)<StyledProps>`
  position: fixed;
  bottom: 0;
  width: var(--gobal-width);
  height: 70px;
  padding: 13px 26px;
  box-sizing: border-box;
  background: ${Colors.Gray[800]};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  user-select: none;
  z-index: 100;

  &.btm-nav-bar-enter {
    transform: translateY(100%);
  }
  &.btm-nav-bar-enter-active {
    transform: translateY(0);
    transition: opacity, transform ${speed}ms;
  }

  &.btm-nav-bar-exit-active {
    transform: translateY(100%);
    transition: opacity, transform ${speed}ms;
  }
`;

const Section = styled.div<StyledProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  width: 68px;
  height: 100%;
  & :is(svg, span) {
    transition: 200ms ease-in-out;
    transition-property: font-weight, color, stroke;
  }
  ${({ active }) =>
    active
      ? css`
          & span {
            font-weight: 700;
            color: ${Colors.Primary[500]};
          }
          & svg {
            stroke: ${Colors.Primary[500]};
          }
        `
      : css`
          & span {
            font-weight: 500;
            color: ${Colors.Gray[600]};
          }

          & svg {
            stroke: ${Colors.Gray[600]};
          }
        `}
`;

const Label = styled.span<StyledProps>`
  font-size: 13px;
  line-height: 1.077em;
  letter-spacing: -0.038em;
`;
