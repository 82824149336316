import styled, { css } from 'styled-components';
import Colors from 'styles/color-system';
import Input, { InputState } from 'components/common/Input';

interface Props {
  placeholder: string;
  ActionLabel: string;
  state?: InputState;
  actionActive: boolean;
  value: string;
  onChange?: (value: string, valid?: boolean) => void;
  onAction: () => void;
}

interface StyledProps {
  active?: boolean;
  error?: boolean;
}

function AuthInput({
  placeholder,
  ActionLabel,
  state = 'DEFAULT',
  actionActive,
  value,
  onChange,
  onAction,
}: Props) {
  return (
    <Container>
      <Action
        onClick={onAction}
        disabled={!actionActive}
        error={state === 'ERROR'}
      >
        {ActionLabel}
      </Action>
      <Input
        paddingRight="87px"
        placeholder={placeholder}
        state={state}
        value={value}
        onChange={onChange}
      />
    </Container>
  );
}

export default AuthInput;

const Container = styled.div`
  position: relative;
`;

const Action = styled.button<StyledProps>`
  width: 52px;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translate3d(0, -50%, 0);
  color: ${Colors.Primary[500]};
  text-align: right;
  font-size: 18px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.36px;
  transition: color 100ms ease-in-out;

  &:disabled {
    color: ${Colors.Gray[600]};
  }

  ${({ error }) =>
    error &&
    css`
      color: ${Colors.Error[500]};
    `}
`;
