import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import Colors from 'styles/color-system';

export type InputState = 'DEFAULT' | 'FOCUS' | 'ERROR' | 'DISABLED';

interface Props {
  label?: string;
  placeholder?: string;
  state: InputState;
  readOnly: boolean;
  errorMessage?: string;
  essential: boolean;
  value?: string;
  onChange?: (value: string, valid?: boolean) => void;
  onBlur?: (value?: string) => void;
  onFocus?: () => void;
  regexp?: RegExp;
  maxLength?: number;
  bgColor?: string;
  paddingRight?: string;
}

interface StyleProps {
  state?: InputState;
  bgColor?: string;
  paddingRight?: string;
}

function Input({
  label,
  placeholder,
  state,
  readOnly,
  errorMessage,
  essential,
  value,
  onChange,
  onBlur,
  onFocus,
  regexp,
  maxLength,
  bgColor,
  paddingRight,
}: Props) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    if (state === 'FOCUS' || state === 'ERROR') {
      inputRef.current?.focus();
    }
  }, [state]);

  useEffect(() => {
    if (value) {
      setInputValue(value);
    }
  }, [value]);

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    if (maxLength && value.length > maxLength) {
      return;
    }

    setInputValue(value);
    if (!onChange) return;

    if (essential && !value) {
      onChange('', false);
      return;
    }

    if (regexp) {
      onChange(value, regexp.test(value.trim()));
      return;
    }
    onChange(value);
  };

  const onBlurHandler = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    if (!onBlur) return;
    onBlur(e.currentTarget.value);
  };

  const inputLabel = (
    <Label>
      {label}
      {essential && <Essential>*</Essential>}
    </Label>
  );

  return (
    <Container>
      <InputWrapper>
        {label && inputLabel}
        <InputElm
          onChange={onChangeHandler}
          onBlur={onBlurHandler}
          onFocus={onFocus && onFocus}
          value={inputValue}
          ref={inputRef}
          state={state}
          placeholder={placeholder}
          type="text"
          readOnly={readOnly}
          disabled={state === 'DISABLED'}
          bgColor={bgColor}
          paddingRight={paddingRight}
        />
      </InputWrapper>
      {state === 'ERROR' && errorMessage && <Error>{errorMessage}</Error>}
    </Container>
  );
}

export default Input;

Input.defaultProps = {
  state: 'DEFAULT',
  essential: false,
  readOnly: false,
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const InputWrapper = styled.label`
  display: flex;
  flex-direction: column;
  gap: 9px;
`;

const InputElm = styled.input<StyleProps>`
  height: 48px;
  padding: 13px 15px;
  padding-right: ${({ paddingRight }) => `${paddingRight}`};
  border: 1px solid transparent;
  border-radius: 6px;
  outline: initial;
  box-sizing: border-box;
  background: ${({ bgColor }) => (bgColor ? bgColor : `${Colors.Gray[700]}`)};
  transition: border 150ms ease-in-out, color 100ms ease-in-out;

  color: ${Colors.Gray[100]};
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.02em;

  &::placeholder {
    color: ${Colors.Gray[500]};
  }

  &:read-only:focus {
    border-color: transparent;
  }

  ${({ state }) =>
    state === 'ERROR'
      ? css`
          border-color: ${Colors.Error[500]};
          color: ${Colors.Error[500]};
        `
      : css`
          &:focus {
            border-color: ${Colors.Primary[500]};
          }
        `}
`;

const Label = styled.span`
  color: ${Colors.Gray[200]};
  font-size: 15px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.02em;
`;

const Error = styled.span`
  color: ${Colors.Error[500]};
  font-size: 15px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.02em;
`;

const Essential = styled.span`
  color: ${Colors.Primary[500]};
`;
