import React from 'react';
import styled, { keyframes } from 'styled-components';
import { FeedItemResponse } from 'dto/FeedDto';
import Image from 'components/common/Image';
import { ReactComponent as LikeIcon } from 'assets/icon/like.svg';
import Colors from 'styles/color-system';

interface Props {
  data: FeedItemResponse;
  onFeedLike: (
    id: number,
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => void;
}

interface StyledProps {
  isLike?: boolean;
}

function HomeFeedCardBasic({ data, onFeedLike }: Props) {
  return (
    <Container>
      <TextWrapper>
        <Content>{data.title}</Content>
        <Like isLike={data.me} onClick={(e) => onFeedLike(data.id, e)}>
          <LikeIcon />
          {data.like.toLocaleString('ko-KR')}
        </Like>
      </TextWrapper>
      <Thumbnail>
        <Image src={data.thumbnail} />
      </Thumbnail>
    </Container>
  );
}

export default HomeFeedCardBasic;

const Container = styled.div`
  position: relative;
  width: 100%;
  border-radius: 20px;
  overflow: hidden;

  &::after {
    content: '';
    display: block;
    padding-bottom: 109.5238%;
  }
`;

const TextWrapper = styled.div`
  position: absolute;
  left: 20px;
  bottom: 22px;
  width: calc(100% - 40px);
  height: auto;
  z-index: 50;
  display: flex;
  flex-direction: column;
  gap: 14px;
`;

const LikeMotion = keyframes`
0%{
  fill: transparent;
  stroke: ${Colors.Gray[200]};
  transform: scale(1);
}
50% {
  transform: scale(1.35);
}
100%{
  transform: scale(1);
  fill: ${Colors.Gray[200]};
  stroke: ${Colors.Gray[200]};
}
`;

const UnlikeMotion = keyframes`
0%{
  transform: scale(1);
  fill: ${Colors.Gray[200]};
  stroke: ${Colors.Gray[200]};
}
50% {
  transform: scale(0.7);
}
100%{
  transform: scale(1);
  fill: transparent;
  stroke: ${Colors.Gray[200]};
}
`;

const Like = styled.div<StyledProps>`
  position: relative;
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 4px;
  & svg {
    animation: ${({ isLike }) => (isLike ? LikeMotion : UnlikeMotion)} 250ms
      ease-in-out forwards;
  }
  color: ${Colors.Gray[200]};
  font-size: 15px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.02em;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: scale(2);
    opacity: 0.4;
  }
`;

const Content = styled.span`
  width: 100%;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${Colors.White[500]};
  font-size: 20px;
  font-weight: 400;
  line-height: 1.3em;
  letter-spacing: -0.02em;
`;

const Thumbnail = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 0;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 17.54%,
      rgba(0, 0, 0, 0.13) 77.58%,
      rgba(0, 0, 0, 0.58) 100%
    );
  }
`;
