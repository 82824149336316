import { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { CSSTransition } from 'react-transition-group';
import useOverlay from 'hook/useOverlay';
import Colors from 'styles/color-system';

interface Props {
  id?: string;
  display?: string;
  speed?: number;
  message: string;
}

interface StyledProps {
  duration: number;
}

function Toast({ id, display, speed, message }: Props) {
  const { hideHandler } = useOverlay();
  const [show, setShow] = useState(false);

  useEffect(() => {
    const selfClose = setTimeout(() => {
      hideHandler(id!);
    }, 3000);

    return () => {
      clearTimeout(selfClose);
    };
  }, []);

  useEffect(() => {
    if (display === 'ON') {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [display]);

  return (
    <CSSTransition in={show} timeout={speed!}>
      <Container duration={speed!}>
        <Content>{message}</Content>
      </Container>
    </CSSTransition>
  );
}

export default Toast;

const Transition = (duration: number) => css`
  transition: ${duration}ms ease-in-out;
  transform: translate3d(-50%, calc(100% + 117px), 0);
  opacity: 0;

  &[class*='enter-'] {
    transform: translate3d(-50%, 0, 0);
    opacity: 1;
  }

  &[class*='exit-'] {
    transform: translate3d(-50%, calc(100% + 117px), 0);
    opacity: 0;
  }
`;

const Container = styled.div<StyledProps>`
  position: absolute;
  bottom: 117px;
  left: 50%;
  width: fit-content;
  max-width: 86.6666vw;
  padding-inline: 20px;
  padding-block: 13px;
  border-radius: 6px;
  overflow: hidden;
  box-sizing: border-box;
  background: #000000;
  ${(props) => Transition(props.duration)}
`;

const Content = styled.span`
  white-space: pre;
  color: ${Colors.White[500]};
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.02em;
`;
