import React, { useContext, useEffect, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { Context as OrderContext } from 'contexts/OrderContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { OrderService } from 'services/OrderService';
import { ConfirmOrderResponse, TossPaymentDto } from 'dto/OrderDto';
import PATH from 'Path';
import { Utils } from 'utils/Utils';
import { RESULT_CODE } from 'constants/ResultCode';
import HeaderNavBar from 'components/common/HeaderNavBar';
import { ReactComponent as Checked } from 'assets/icon/checked.svg';
import OrderDeliveryInfoCard from 'components/order/OrderDeliveryInfoCard';
import OrderPaymentInfoCard from 'components/order/OrderPaymentInfoCard';
import Colors from 'styles/color-system';
import OrderPaymentInfoAccount from 'components/order/OrderPaymentInfoAccount';

interface StyledProps {
  getReady: boolean;
}

type LocationState = {
  data: TossPaymentDto;
};

function OrderCompletePage() {
  const navigate = useNavigate();
  const {
    state: { orderInfo, deliveryInfo },
  } = useContext(OrderContext);

  const { state: locationState } = useLocation();

  const [paymentMethod, setPaymentMethod] = useState<string>('');
  const [getReady, setGetReady] = useState<boolean>(false);
  const [currentId, setCurrentId] = useState<number>(0);
  const [data, setData] = useState<TossPaymentDto>();
  const [response, setResponse] = useState<ConfirmOrderResponse>();

  useEffect(() => {
    const { data } = locationState as LocationState;
    setData(data);
  }, []);

  useEffect(() => {
    if (data) {
      OrderService.confirmOrder({
        order_id: data.orderId,
        payment_key: data.paymentKey,
        amount: data.amount,
      }).then(({ data }) => {
        const result = data as ConfirmOrderResponse;
        if (result.result === RESULT_CODE.SUCCESS) {
          setCurrentId(result.id);
          setPaymentMethod(result.method!);
        }
        setResponse(result);
        setGetReady(true);
      });
    }
  }, [data]);

  const handleNavigate = (pathType: 'HOME' | 'ORDER') => {
    if (pathType === 'HOME') {
      navigate(PATH.Home, { replace: true });
    }

    if (pathType === 'ORDER') {
      navigate(Utils.subNavigationPath(PATH.Orders, '' + currentId));
    }
  };

  return (
    <Container>
      <HeaderNavBar label="주문완료" bgColor={Colors.Background[500]} btmLine />
      <ContentBody>
        <TopSection>
          <Graphic getReady={getReady}>
            <Checked />
          </Graphic>
          <Title>주문이 완료되었습니다.</Title>
          <OrderNumber>
            <span className="label">주문번호 : </span>
            <span className="value">{data?.orderId}</span>
          </OrderNumber>
          {response?.account_bank ? (
            <OrderPaymentInfoAccount
              bank={response.account_bank}
              name={response.account_name!}
              accountNumber={response.account_number!}
              amount={data?.amount}
              showTitle={false}
              date={response.account_expire!}
              margin={{ top: -4, bottom: 44 }}
            />
          ) : (
            <></>
          )}
          <ActionWrapper>
            <OrderListBtn onClick={() => handleNavigate('ORDER')}>
              주문내역 조회
            </OrderListBtn>
            <HomeBtn onClick={() => handleNavigate('HOME')}>
              홈 화면 가기
            </HomeBtn>
          </ActionWrapper>
        </TopSection>
        <OrderDeliveryInfoCard
          name={deliveryInfo.name}
          phone={deliveryInfo.phone}
          address0={deliveryInfo.address0}
          address1={deliveryInfo.address1}
          memo={deliveryInfo.memo}
        />
        <OrderPaymentInfoCard
          method={paymentMethod}
          order={orderInfo.order_amount}
          delivery={orderInfo.delivery_amount + orderInfo.isolated_price}
        />
      </ContentBody>
    </Container>
  );
}

export default OrderCompletePage;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const ContentBody = styled.div`
  flex: 1;
  padding-inline: 24px;
  padding-bottom: 30px;
  box-sizing: border-box;
  overflow: hidden auto;
`;

const TopSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  margin-top: 40px;
  margin-bottom: 30px;
`;

const GraphicMotion = keyframes`
  0% {
    stroke-dashoffset: 24;
  }
  100% {
    stroke-dashoffset: 0;
  }
`;

const Graphic = styled.div<StyledProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  border: 1px solid ${Colors.Gray[600]};
  box-sizing: border-box;
  margin-bottom: 30px;

  & > svg {
    width: 40px;
    height: 40px;
    stroke-dasharray: 24;
    stroke-dashoffset: 24;
    ${({ getReady }) =>
      getReady &&
      css`
        animation: ${GraphicMotion} 500ms ease-in-out 400ms forwards;
      `};
  }

  & > svg > path {
    stroke: ${Colors.Primary[500]};
  }
`;

const Title = styled.h3`
  color: ${Colors.Gray[100]};
  font-size: 28px;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 15px;
`;

const OrderNumber = styled.div`
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.02em;
  margin-bottom: 44px;
  & > .label {
    color: ${Colors.Gray[100]};
  }

  & > .value {
    color: ${Colors.Primary[500]};
  }
`;

const ActionWrapper = styled.div`
  display: flex;
  gap: 6px;
  width: 100%;
  padding-bottom: 29px;
  border-bottom: 1px solid ${Colors.Gray[600]};
  box-sizing: border-box;
`;

const Button = styled.button`
  width: calc(50% - 3px);
  height: 58px;
  border-radius: 6px;
  cursor: pointer;
  user-select: none;
  text-align: center;
  color: ${Colors.White[500]};
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  box-shadow: 0px -4px 4px 0px rgba(0, 0, 0, 0.05);
`;

const OrderListBtn = styled(Button)`
  background: ${Colors.Gray[600]};
`;

const HomeBtn = styled(Button)`
  background: ${Colors.Primary[500]};
`;
