import React, { Fragment } from 'react';
import styled, { css } from 'styled-components';
import Colors from 'styles/color-system';

export type Status =
  | 'BEST'
  | 'SOLD_OUT'
  | 'NONE'
  | 'LIMITED'
  | 'BEFORE'
  | 'AFTER'
  | 'ONGOING';

interface Props {
  type?: Status;
  size?: 'S' | 'M';
}

interface StyledProps {
  size?: string;
  color?: string;
  bgColor?: string;
}

type Option = {
  [key: string]: {
    [key: string]: string;
  };
};

const badgeOption: Option = {
  BEST: {
    label: 'BEST',
    color: Colors.White[500],
    bgColor: '#ffbe5c',
  },
  SOLD_OUT: {
    label: 'SOLD\nOUT',
    color: Colors.White[500],
    bgColor: '#8A8A8A',
  },
  LIMITED: {
    label: '한정\n수량',
    color: Colors.White[500],
    bgColor: '#48C38F',
  },
  AFTER: {
    label: '판매\n종료',
    color: Colors.White[500],
    bgColor: '#8A8A8A',
  },
  BEFORE: {
    label: '판매\n예정',
    color: Colors.White[500],
    bgColor: Colors.Primary[500],
  },
  ONGOING: {},
};

function ShopItemBadge({ type = 'NONE', size = 'S' }: Props) {
  return type !== 'NONE' ? (
    <Container
      size={size}
      color={badgeOption[type].color}
      bgColor={badgeOption[type].bgColor}
    >
      {badgeOption[type].label}
    </Container>
  ) : (
    <Fragment />
  );
}

export default ShopItemBadge;

const Small = css`
  right: -10px;
  width: 48px;
  height: 48px;
  font-size: 12px;
`;

const Medium = css`
  top: 20px;
  right: 17px;
  width: 91px;
  height: 91px;
  font-size: 21px;
`;

const Container = styled.div<StyledProps>`
  position: absolute;
  ${({ size }) => size === 'S' && Small}
  ${({ size }) => size === 'M' && Medium}

  border-radius: 50%;
  background: ${({ bgColor }) => bgColor};
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  z-index: 100;

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: ${({ color }) => color};
  font-weight: 700;
  line-height: normal;
  white-space: pre-line;
`;
