import { customAxios } from './CustomAxiosLoader';

const root = () => {
  return customAxios.get(`/`);
};

const redirectArtist = (key: string) => {
  return customAxios.get(`/redirect/artist/${key}`);
};

const redirectProduct = (productId: string) => {
  return customAxios.get(`/redirect/product/${productId}`);
};

const home = (artistId: number) => {
  return customAxios.get(`/artist/${artistId}`);
};

const theme = (artistId: number) => {
  return customAxios.get(`/artist/${artistId}/theme`);
};

const more = (artistId: number, page: number, limit: number) => {
  return customAxios.get(`/artist/${artistId}/more`, {
    params: { limit: limit, offset: limit * (page - 1) },
  });
};

const noticeList = (page: number, limit: number) => {
  return customAxios.get(`/notices`, {
    params: { limit: limit, offset: limit * (page - 1) },
  });
};

const noticeDetail = (noticeId: number) => {
  return customAxios.get(`/notices/${noticeId}`);
};

const feedDetail = (feedId: number) => {
  return customAxios.get(`/feeds/${feedId}`);
};

const scheduleList = (artistId: number, page: number, limit: number) => {
  return customAxios.get(`/artist/${artistId}/schedules`, {
    params: { limit: limit, offset: limit * (page - 1) },
  });
};

const scheduleDetail = (scheduleId: number) => {
  return customAxios.get(`/schedules/${scheduleId}`);
};

const shopList = (artistId: number, page: number, limit: number) => {
  return customAxios.get(`/shop`, {
    params: { artist_id: artistId, limit: limit, offset: limit * (page - 1) },
  });
};

const shopDetail = (productId: number) => {
  return customAxios.get(`/shop/${productId}`);
};

export const ArtistService = {
  root,
  redirectArtist,
  redirectProduct,
  home,
  more,
  noticeList,
  noticeDetail,
  feedDetail,
  scheduleList,
  scheduleDetail,
  shopList,
  shopDetail,
  theme,
};
