import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import PATH from 'Path';
import { Context as OrderContext } from 'contexts/OrderContext';
import { OrderService } from 'services/OrderService';
import { SubmitOrderResponse } from 'dto/OrderDto';
import HeaderNavBar from 'components/common/HeaderNavBar';
import OrderRequestDeliveryInfo from 'components/order/OrderRequestDeliveryInfo';
import OrderRequestOrderItemList from 'components/order/OrderRequestOrderItemList';
import OrderRequestPaymentMethod from 'components/order/OrderRequestPaymentMethod';
import OrderRequestOrderInfo from 'components/order/OrderRequestOrderInfo';
import OrderRequestPolicy from 'components/order/OrderRequestPolicy';
import BottomButton from 'components/common/BottomButton';
import { CartItemDto } from 'dto/CartDto';
import { PAYMENT_TYPE } from 'constants/PaymentType';
import { AxiosError } from 'axios';
import { RESULT_CODE } from 'constants/ResultCode';
import { useNavigate } from 'react-router-dom';
import useOverlay from 'hook/useOverlay';
import DateUtils from 'utils/DateUtils';

function OrderRequestPage() {
  const {
    state: { orderInfo, deliveryInfo },
    modifyOrder,
  } = useContext(OrderContext);

  const navigate = useNavigate();
  const { showToast } = useOverlay();

  const [totalCount, setTotalCount] = useState<number>(0);
  const [paymentType, setPaymentType] = useState<PAYMENT_TYPE>(
    PAYMENT_TYPE.CARD,
  );
  const [readyDelivery, setReadyDelivery] = useState<boolean>(false);
  const [allReady, setAllReady] = useState<boolean>(false);

  const [popup, setPopup] = useState<Window | null>();
  const [tempOrder, setTempOrder] = useState<SubmitOrderResponse>();

  useEffect(() => {
    let totalCount: number = 0;
    orderInfo.items.forEach((item: CartItemDto) => (totalCount += item.count));
    setTotalCount(totalCount);
  }, []);

  useEffect(() => {
    setAllReady(readyDelivery && paymentType && orderInfo.agree_payment);
  }, [readyDelivery, orderInfo, paymentType]);

  useEffect(() => {
    if (!popup || !tempOrder) {
      return;
    }

    let href = `${window.location.href}/${PATH.OrderPayment}?customerName=${tempOrder.customer_name}&orderName=${tempOrder.order_name}&orderId=${tempOrder.order_id}&amount=${tempOrder.amount}&paymentType=${paymentType}`;

    if (paymentType === PAYMENT_TYPE.VIRTUAL) {
      const now = new Date();
      const tomorrow = new Date(now.setDate(now.getDate() + 1));
      let end = tomorrow.getTime() / 1000;
      for (const item of orderInfo.items) {
        if (end > item.product.end) {
          end = item.product.end;
        }
      }
      href += `&dueDate=${DateUtils.convertDateToIOS8601(
        DateUtils.convertTimeToDate(end),
      )}`;
    }

    popup.location.href = href;
    const onListener = (e: any) => {
      console.log(e.data);
      if (e.data.result === RESULT_CODE.FAIL) {
        showToast(e.data.message);
      } else if (e.data.result === RESULT_CODE.SUCCESS) {
        navigate(PATH.OrderComplete, {
          replace: true,
          state: { data: e.data.data },
        });
      }

      closePaymentPopup();
    };

    window.addEventListener('message', onListener, false);
    return () => {
      window.removeEventListener('message', onListener);
    };
  }, [popup, tempOrder]);

  const openPaymentPopup = () => {
    const popup = window.open(undefined, '_blank', 'width=800, height=800');
    setPopup(popup);
  };

  const closePaymentPopup = () => {
    console.log('closePaymentPopup');
    popup?.close();
    setPopup(null);
  };

  const handlePayment = () => {
    if (!allReady) return;

    openPaymentPopup();

    if (tempOrder) {
      return;
    }

    OrderService.submitOrder({
      order_info: orderInfo,
      delivery_info: deliveryInfo,
    })
      .then((response) => {
        const result = response.data as SubmitOrderResponse;
        setTempOrder(result);
      })
      .catch((e: AxiosError) => {
        closePaymentPopup();
      });
  };

  const onReadyDeliveryInfo = (ready: boolean) => {
    setReadyDelivery(ready);
  };

  return (
    <Container>
      <HeaderNavBar leftItem="BACK" label="주문하기" />
      <ContentBody>
        <OrderRequestDeliveryInfo onReadyDeliveryInfo={onReadyDeliveryInfo} />
        <OrderRequestOrderItemList items={orderInfo.items} />
        <OrderRequestPaymentMethod
          paymentType={paymentType}
          onChangePaymentType={(value) => {
            setPaymentType(value);
          }}
        />
        <OrderRequestOrderInfo totalCount={totalCount} orderInfo={orderInfo} />
        <OrderRequestPolicy
          setOrderPolicy={(value) => {
            let tempInfo = { ...orderInfo };
            tempInfo.agree_payment = value;
            modifyOrder(tempInfo);
          }}
        />
      </ContentBody>
      <BottomButton
        label="결제하기"
        onClick={handlePayment}
        state={allReady ? 'DEFAULT' : 'DISABLE'}
      />
    </Container>
  );
}

export default OrderRequestPage;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 100%;
`;

const ContentBody = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-inline: 24px;
  box-sizing: border-box;
  overflow: hidden auto;
`;

const List = styled.ul`
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
