import React, { useEffect } from 'react';
import styled from 'styled-components';
import { loadTossPayments } from '@tosspayments/payment-sdk';
import { PAYMENT_TYPE, PAYMENT_TYPE_VALUES } from 'constants/PaymentType';
import { useSearchParams } from 'react-router-dom';
import { RESULT_CODE } from 'constants/ResultCode';

function OrderPaymentPage() {
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const orderName = searchParams.get('orderName');
    const code = searchParams.get('code');
    const paymentKey = searchParams.get('paymentKey');

    if (paymentKey) {
      const orderId = searchParams.get('orderId');
      const amount = parseInt(searchParams.get('amount')!);
      window.opener.postMessage(
        {
          result: RESULT_CODE.SUCCESS,
          data: { paymentKey: paymentKey, orderId: orderId, amount: amount },
        },
        window.location.origin,
      );
      return;
    }

    if (code) {
      const message = searchParams.get('message');
      window.opener.postMessage(
        { result: RESULT_CODE.FAIL, message: message },
        window.location.origin,
      );
      return;
    }

    if (orderName) {
      const amount = parseInt(searchParams.get('amount')!);
      const orderId = searchParams.get('orderId');
      const customerName = searchParams.get('customerName');
      const paymentType: string = searchParams.get('paymentType')!;
      const dueDate = searchParams.has('dueDate')
        ? searchParams.get('dueDate')!
        : undefined;

      console.log(dueDate);

      doPayment(
        orderId!,
        orderName!,
        customerName!,
        amount!,
        paymentType as PAYMENT_TYPE,
        dueDate,
      );
      return;
    }
  }, []);

  const doPayment = (
    orderId: string,
    orderName: string,
    customerName: string,
    amount: number,
    paymentType: PAYMENT_TYPE,
    dueDate?: string,
  ) => {
    const url = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
    loadTossPayments(process.env.REACT_APP_TOSS_CLIENT_KEY!).then(
      (tossPayments) => {
        tossPayments
          .requestPayment(PAYMENT_TYPE_VALUES[paymentType!], {
            amount: amount,
            orderId: orderId,
            orderName: orderName,
            customerName: customerName,
            successUrl: url,
            failUrl: url,
            windowTarget: 'self',
            dueDate: dueDate,
          })
          .catch((error) => {
            window.opener.postMessage(
              { result: RESULT_CODE.FAIL, message: error.toString() },
              window.location.origin,
            );
          });
      },
    );
  };

  return <Container></Container>;
}

export default OrderPaymentPage;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 100%;
`;
