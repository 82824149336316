import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import Colors from 'styles/color-system';
import { ReactComponent as ToolTipIcon } from 'assets/icon/tooltip-icon.svg';

interface Props {
  children: React.ReactNode;
}

interface StyledProps {
  left: number;
}

function ToolTip({ children }: Props) {
  const iconRef = useRef<HTMLDivElement>(null);
  const modalRef = useRef<HTMLDivElement>(null);
  const root = document.getElementById('root') as HTMLElement;
  const [show, setShow] = useState<boolean>(false);
  const [posLeft, setPosLeft] = useState<number>(0);

  useEffect(() => {
    window.addEventListener('click', handleHideToolTip);

    return () => {
      window.removeEventListener('click', handleHideToolTip);
    };
  }, []);

  const handleShowToolTip = () => {
    setPos();
    setShow((prev) => !prev);
  };

  const handleHideToolTip = (e: MouseEvent) => {
    if (e.target !== iconRef.current && e.target !== modalRef.current)
      setShow(false);
  };

  const setPos = () => {
    if (!iconRef.current) return;
    const pos =
      (window.innerWidth - root.clientWidth) / 2 -
      iconRef.current.getBoundingClientRect().x +
      12;

    setPosLeft(pos);
  };

  return (
    <Container>
      {show && (
        <Modal ref={modalRef} left={posLeft}>
          {children}
        </Modal>
      )}
      <Icon ref={iconRef} onClick={handleShowToolTip}>
        <ToolTipIcon />
      </Icon>
    </Container>
  );
}

export default ToolTip;

const Container = styled.div`
  position: relative;
`;

const Icon = styled.div`
  width: 18px;
  height: 18px;
  cursor: pointer;
  user-select: none;

  & > svg {
    pointer-events: none;
  }
`;

const Modal = styled.div<StyledProps>`
  position: absolute;
  bottom: -9px;
  left: 0;
  transform: ${({ left }) => `translate3d(${left}px, 100%, 0)`};
  width: calc(var(--gobal-width) - 24px);
  height: fit-content;
  padding: 12px;
  box-sizing: border-box;
  border-radius: 6px;
  background-color: ${Colors.Background[500]};
  border: 1px solid ${Colors.Gray[300]};
  z-index: 100;

  & > * {
    pointer-events: none;
  }
`;
