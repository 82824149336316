import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import HeaderNavBar from 'components/common/HeaderNavBar';
import useOverlay from 'hook/useOverlay';
import Colors from 'styles/color-system';
import BottomButton from 'components/common/BottomButton';
import Image from 'components/common/Image';
import { UserService } from 'services/UserService';
import {
  CollectionCheckResponse,
  CollectionRegisterResponse,
} from 'dto/CollectionDto';
import { RESULT_CODE } from 'constants/ResultCode';
import PATH from 'Path';
import useUIInteractionAction from 'hook/useUIInteractionAction';

function CollectionRegisterPage() {
  const navigate = useNavigate();

  const { serial } = useParams();

  const { showToast } = useOverlay();

  const { handleTopNavStyles } = useUIInteractionAction();

  const [title, setTitle] = useState<string>('');
  const [thumbnail, setThumbnail] = useState<string>('');
  const [used, setUsed] = useState<boolean>(false);

  useEffect(() => {
    if (serial) {
      UserService.checkCollection(serial).then((response) => {
        const result = response.data as CollectionCheckResponse;

        handleTopNavStyles(result.color, result.is_white);

        if (result.used && result.user_collection_id > 0) {
          navigate(PATH.Collections + `/${result.user_collection_id}`, {
            replace: true,
          });
        } else {
          setTitle(result.title);
          setThumbnail(result.thumbnail);
          setUsed(result.used);
          console.log(result);
        }
      });
    }
  }, []);

  const handleRegister = () => {
    if (serial) {
      UserService.registerCollection(serial)
        .then((response) => {
          const result = response.data as CollectionRegisterResponse;
          if (result.result === RESULT_CODE.SUCCESS) {
            showToast('등록 했습니다.');
            navigate(PATH.Collections + `/${result.user_collection_id}`, {
              replace: true,
            });
          }
        })
        .catch((e) => {
          showToast('실패 했습니다.');
        });
    }
  };

  const renderButton = (register: boolean) => {
    if (register) {
      return (
        <ButtonWapper>
          <BottomButton label="취소" sort="GRAY" onClick={() => navigate(-1)} />
          <BottomButton label="확인" onClick={handleRegister} />
        </ButtonWapper>
      );
    }
    return <BottomButton label="확인" onClick={() => navigate(-1)} />;
  };

  return (
    <Container>
      <HeaderNavBar leftItem="LOGO" />
      <ContentSection>
        <Thumbnail>
          <ImageWapper>
            <Image src={thumbnail} />
          </ImageWapper>
        </Thumbnail>
        <CollectionName>{title}</CollectionName>
        <Description>
          {used
            ? '이미 사용된 QR입니다.\nQR은 ID당 1개만 등록 가능합니다.'
            : 'QR을 등록하겠습니까?'}
        </Description>
      </ContentSection>
      {renderButton(!used)}
    </Container>
  );
}

export default CollectionRegisterPage;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  background: ${Colors.Background[500]};
`;

const ContentSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  overflow: hidden auto;
  padding-inline: 24px;
  box-sizing: border-box;
  z-index: 10;
  text-align: center;
  color: ${Colors.White[500]};
  font-size: 20px;
  line-height: normal;
`;

const Thumbnail = styled.div`
  position: relative;
  width: 73.7179%;
  height: 0;
  padding-bottom: 73.7179%;
  margin-bottom: 29px;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const ImageWapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const CollectionName = styled.span`
  font-weight: 700;
  margin-bottom: 9px;
`;

const Description = styled.span`
  font-weight: 400;
  white-space: pre-line;
`;

const ButtonWapper = styled.div`
  display: flex;
`;
