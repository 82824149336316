import styled from 'styled-components';
import Colors from 'styles/color-system';
import HeaderNavBar from 'components/common/HeaderNavBar';
import { ReactComponent as ErrorIcon } from 'assets/icon/error.svg';

function QRCodePage() {
  return (
    <Container>
      <HeaderNavBar
        leftItem="BACK"
        label="티켓 확인"
        btmLine
        bgColor={Colors.Background[500]}
      />
      <ContentSection>
        <div className="assets-wrapper">
          <ErrorIcon fill={Colors.Error[500]} stroke={Colors.Error[500]} />
        </div>
        <p className="title">관계자용 페이지입니다</p>
        <span className="description">
          {
            '공연 관계자만 내용 확인이 가능합니다 \n입장 시 진행 요원에게 QR을 제시해주세요'
          }
        </span>
      </ContentSection>
    </Container>
  );
}

export default QRCodePage;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 100%;
`;

const ContentSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-inline: 24px;
  box-sizing: border-box;
  text-align: center;

  & .assets-wrapper {
    position: relative;
    width: 24%;
    padding-bottom: 24%;
    margin-bottom: 36px;
  }

  & .assets-wrapper svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  & .title {
    color: ${Colors.Error[500]};
    font-size: 24px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.48px;
    margin-bottom: 19px;
  }

  & .description {
    color: ${Colors.Gray[600]};
    white-space: pre-line;
    font-size: 18px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.36px;
    margin-bottom: 119px;
  }
`;
