import styled, { css } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import companyInfo from 'assets/info/company-info';
import PATH from 'Path';
import Colors from 'styles/color-system';
import { Utils } from '../../utils/Utils';

type FooterInfoType = {
  [key: string]: {
    label?: string;
    content?: string;
  };
};

function RootFooter() {
  const navigate = useNavigate();
  const footerInfo: FooterInfoType = {
    ceo: {
      label: `${companyInfo.COMPANY} 대표이사 ${companyInfo.CEO}`,
    },
    businessNumber: {
      label: '사업자등록번호',
      content: companyInfo.BUSINESS_NUMBER,
    },
    mailOrderSalesRegistrationNumber: {
      label: '통신판매업신고번호',
      content: companyInfo.MAIL_ORDER_SALES_REGISTRATION_NUMBER,
    },
    address: {
      label: '주소',
      content: companyInfo.ADDRESS,
    },
    customerService: {
      label: '고객센터',
      content: companyInfo.CS_TEL,
    },
  };

  const handleBusinessInfo = () => {
    const inApp = Utils.isInApp();
    if (inApp) {
      window.location.href =
        'https://www.ftc.go.kr/bizCommPop.do?wrkr_no=7598802239';
    } else {
      window.open(
        'https://www.ftc.go.kr/bizCommPop.do?wrkr_no=7598802239',
        '_blank',
        'noopener, noreferrer',
      );
    }
  };

  const handlePolicy = (type: string) => {
    if (type === 'PRIVACY') {
      navigate(PATH.Privacy);
    }

    if (type === 'TERMS') {
      navigate(PATH.Terms);
    }
  };

  const renderItem = (key: string) => {
    if (
      key === 'ceo' ||
      key === 'mailOrderSalesRegistrationNumber' ||
      key === 'address' ||
      key === 'customerService'
    ) {
      return (
        <Section key={key}>
          <Label>{footerInfo[key].label}</Label>
          {footerInfo[key].content && (
            <Content>{footerInfo[key].content}</Content>
          )}
        </Section>
      );
    }

    if (key === 'businessNumber') {
      return (
        <Section key={key}>
          <Label>{footerInfo[key].label}</Label>
          <Content>{footerInfo[key].content}</Content>
          <Action type="button" onClick={handleBusinessInfo}>
            사업자정보 확인
          </Action>
        </Section>
      );
    }
  };
  return (
    <Container>
      <CompanyInfo>
        {Object.keys(footerInfo).map((key) => renderItem(key))}
      </CompanyInfo>
      <PolicyInfo>
        <PolicyButton onClick={() => handlePolicy('TERMS')}>
          이용약관
        </PolicyButton>
        <PolicyButton onClick={() => handlePolicy('PRIVACY')}>
          개인정보처리방침
        </PolicyButton>
      </PolicyInfo>
      <Copyright>{companyInfo.COPYRIGHT}</Copyright>
    </Container>
  );
}

export default RootFooter;

const TextStyle = css`
  font-size: 10px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.02em;
`;

const Container = styled.div`
  width: 100%;
  padding: 24px;
  box-sizing: border-box;
  background: #18191f;
  display: flex;
  flex-direction: column;
  gap: 17px;
  ${TextStyle};
`;

const CompanyInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Section = styled.div`
  display: flex;
  gap: 10px;
`;

const Label = styled.span`
  color: ${Colors.Gray[500]};
`;

const Content = styled.span`
  color: ${Colors.Gray[200]};
`;

const Action = styled.button`
  color: ${Colors.Gray[200]};
  ${TextStyle};
  text-decoration-line: underline;
  text-underline-offset: 0.15em;
  cursor: pointer;
  user-select: none;
`;

const PolicyInfo = styled.div`
  display: flex;
  gap: 9px;
`;

const PolicyButton = styled.button.attrs({ type: 'button' })`
  color: ${Colors.Gray[500]};
  font-size: 10px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.02em;
  text-decoration-line: underline;
  text-underline-offset: 0.15em;
  cursor: pointer;
  user-select: none;
`;

const Copyright = styled.span`
  color: ${Colors.Primary[500]};
`;
