import { customAxios } from './CustomAxiosLoader';
import { getRefreshToken } from 'contexts/AuthContext';

const refreshToken = () => {
  return customAxios.post(
    `/auth/refresh`,
    {},
    {
      headers: {
        Authorization: `Bearer ${getRefreshToken()}`,
        'Content-Type': 'application/json',
      },
    },
  );
};

export const AuthService = {
  refreshToken,
};
