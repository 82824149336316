import React from 'react';
import styled from 'styled-components';
import Colors from 'styles/color-system';
import Image from 'components/common/Image';
import Lock from 'assets/img/lock.svg';
import { UserCollectionDto } from 'dto/CollectionDto';

interface Props {
  data: UserCollectionDto | { [key: string]: undefined };
  onClick: (id: number) => void;
}

interface StyledProp {
  dim?: boolean;
}

function CollectionListItem({ data, onClick }: Props) {
  return (
    <Container onClick={() => data?.id && onClick(data.id)}>
      <Thumbnail>
        <ImageWapper>
          <Image
            src={
              data?.collection?.list_thumbnail
                ? data.collection.list_thumbnail
                : Lock
            }
          />
        </ImageWapper>
      </Thumbnail>
      <Label dim={!data?.collection?.title}>
        {data?.collection?.title ? data.collection.title : 'Coming Soon'}
      </Label>
    </Container>
  );
}

export default CollectionListItem;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 9px;
  width: calc(50% - (13px / 2));
  cursor: pointer;
  user-select: none;
`;

const Thumbnail = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 100%;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const ImageWapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 6px;
  overflow: hidden;
  background: ${Colors.Gray[700]};
`;

const Label = styled.div<StyledProp>`
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  text-align: center;
  color: ${({ dim }) => (dim ? `${Colors.Gray[500]}` : `${Colors.Gray[100]}`)};
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
`;
