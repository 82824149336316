const COMPANY = '㈜팀블루';
const CEO = '이상길';
const ADDRESS = '서울시 용산구 한강대로 109 용성 비즈텔 1306호';
const BUSINESS_NUMBER = '759-88-02239';
const MAIL_ORDER_SALES_REGISTRATION_NUMBER = '2023-서울용산-0756호';
const COPYRIGHT = 'CopyrightⓒFANUS Corp. All rights Reserved.';

const CS_TEL = '070-4626-3611';
const CS_EMAIL = 'csfanus@teamblue.co.kr';

const PRIVACY_OFFICER_NAME = '원종석';
const PRIVACY_OFFICER_EMAIL = 'csfanus@teamblue.co.kr';
const PRIVACY_OFFICER_TEL = '070-4626-3611';

const DELIVERY_COMPANY = '팀블루 계약 배송업체';

const companyInfo = {
  COMPANY,
  CEO,
  CS_TEL,
  CS_EMAIL,
  ADDRESS,
  BUSINESS_NUMBER,
  MAIL_ORDER_SALES_REGISTRATION_NUMBER,
  COPYRIGHT,
  PRIVACY_OFFICER_NAME,
  PRIVACY_OFFICER_EMAIL,
  PRIVACY_OFFICER_TEL,
  DELIVERY_COMPANY,
};

export default companyInfo;
