import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';
import Image from 'components/common/Image';
import 'swiper/css';
import 'swiper/css/pagination';
import Colors from 'styles/color-system';
import { BannerSimpleDto } from 'dto/CommonDto';

interface Props {
  banners: BannerSimpleDto[];
}

function RootMainBanner({ banners }: Props) {
  const navigate = useNavigate();
  const [total, setTotal] = useState(0);
  const [current, setCurrent] = useState(0);

  useEffect(() => {
    setTotal(banners.length);
    setCurrent(0);
  }, [banners]);

  const setting = {
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
    speed: 450,
    loop: true,
    onSlideChange: (swiper: any) => setCurrent(swiper.realIndex),
    initialSlide: 1,
    modules: [Autoplay],
  };

  const handleClick = (path: string) => {
    navigate(path, {
      state: {
        isBack: true,
      },
    });
  };

  return (
    <Container>
      <SwiperWrapper {...setting}>
        {banners.map((item) => (
          <SwiperItem
            key={item.thumbnail}
            onClick={() => handleClick(item.path)}
          >
            <ImageWrapper>
              <Image src={item.thumbnail} />
            </ImageWrapper>
          </SwiperItem>
        ))}
        <PaginationWrapper>
          {current + 1} / {total}
        </PaginationWrapper>
      </SwiperWrapper>
    </Container>
  );
}

export default RootMainBanner;

const Container = styled.div`
  position: relative;
  width: 100%;
  margin-block: 8px;

  &::after {
    content: '';
    display: block;
    padding-bottom: 65.55555%;
  }
`;

const SwiperWrapper = styled(Swiper)`
  position: absolute;
  top: 0px;
  left: 12px;
  width: calc(100% - 24px);
  height: 100%;
  border-radius: 20px;
  overflow: hidden;
`;

const SwiperItem = styled(SwiperSlide)`
  position: relative;
  width: 100%;
  height: 100%;
  cursor: pointer;
  user-select: none;
`;

const TextWrapper = styled.div`
  position: absolute;
  left: 20px;
  bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  z-index: 100;
`;

const Title = styled.h3`
  color: ${Colors.White[500]};
  font-size: 24px;
  font-weight: 600;
  line-height: 1.167em;
  letter-spacing: -0.02em;
  white-space: pre-wrap;
`;

const Descirption = styled.span`
  color: rgba(255, 255, 255, 0.7);
  font-size: 15px;
  font-weight: 500;
  line-height: normal;
  white-space: nowrap;
`;

const ImageWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const PaginationWrapper = styled.div`
  position: absolute;
  bottom: 12px;
  right: 12px;
  width: 46px;
  padding-block: 4px;
  padding-right: 9px;
  text-align: right;
  border-radius: 13px;
  box-sizing: border-box;
  background: rgba(0, 0, 0, 0.2);
  z-index: 100;
  color: ${Colors.White[500]};
  font-size: 15px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.02em;
`;
