import { useState, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import styled, { css, keyframes } from 'styled-components';
import { ReactComponent as Circle } from 'assets/icon/progress-circle.svg';

interface Props {
  display?: string;
  speed?: number;
}

interface StyledProps {
  duration: number;
}

function Progress({ display, speed }: Props) {
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (display === 'ON') {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [display]);

  return (
    <CSSTransition in={show} timeout={speed!}>
      <Container duration={speed!}>
        <Section>
          <Circle />
        </Section>
      </Container>
    </CSSTransition>
  );
}

export default Progress;

const Transition = (duration: number) => css`
  transition: opacity ${duration}ms ease-in-out;
  opacity: 0;

  &[class*='enter-'] {
    opacity: 1;
  }

  &[class*='exit-'] {
    opacity: 0;
  }
`;

const ProgressAnimation = keyframes`
0%{
    transform: rotate(0deg);
}
100%{
    transform: rotate(360deg);
}
`;

const Container = styled.div<StyledProps>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  ${(props) => Transition(props.duration)}
`;

const Section = styled.div`
  display: flex;
  animation: ${ProgressAnimation} 1000ms ease-in-out infinite;
`;
