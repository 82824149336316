import React from 'react';
import 'styles/policy.css';

function TermsPolicy() {
  return (
    <div className="container policy">
      <h3 className="h-1 mg-19">이용약관 동의(필수)</h3>
      <h4 className="h-2 mg-9">환영합니다.</h4>
      <p className="txt-1 mg-5">
        본 약관은 팬어스(FANUS) 서비스의 이용과 관련하여 팬어스 서비스를
        제공하는 ㈜팀블루(이하 팬어스 또는 ‘회사’)와 이를 이용하는 팬어스 서비스
        회원(이하 ‘회원’) 또는 비회원과의 관계를 설명하며, 서비스 이용에 도움이
        될 수 있는 내용을 포함하고 있습니다.
      </p>
      <p className="txt-1 mg-24">
        팬어스 서비스를 이용하거나 팬어스 서비스 회원으로 가입할 경우 본 약관 및
        관련 운영 정책을 확인하여 동의하게 되므로, 잠시 시간을 내어 주의 깊게
        살펴봐 주시기 바랍니다.
      </p>
      <h4 className="h-2 mg-9">
        회원으로 가입하면 서비스를 편리하게 이용할 수 있습니다.
      </h4>
      <p className="txt-1 mg-5">
        여러분은 본 약관을 읽고 동의하신 후 회원 가입을 신청할 수 있으며, 회사는
        이에 대한 승낙을 통해 회원 가입 절차를 완료하고, 팬어스 서비스 이용
        계정(이하 ‘계정’)을 부여합니다. 계정이란 회원이 팬어스 서비스에 로그인한
        이후 이용하는 서비스 이용 이력을 회원 별로 관리하기 위해 설정한 회원
        식별을 말합니다. 회원은 자신의 계정을 통해 다양한 팬어스 서비스를 보다
        편리하게 이용할 수 있습니다.
      </p>
      <p className="txt-1 mg-24">
        여러분은 회원 가입 후에 계정의 정보 비밀유지에 대한 책임과 계정에서
        발생하는 모든 일을 책임지게 됩니다. 계정은 본인만 사용할 수 있으며, 다른
        사용자와 공유하거나 명의 이전을 할 수 없습니다. 계정이 부정 사용되거나
        보안 위협이 의심되는 정황이 발생되면 회사로 즉시 알려야 합니다.
        이용약관을 준수하지 않거나 당신이 제공한 정보가 거짓 또는 부정확한 경우
        등 불미스러운 정황이 확인되면 계정은 정지 또는 종료가 될 수 있습니다.
      </p>
      <h4 className="h-2 mg-9">콘텐츠 소유를 확인하고 동의합니다.</h4>
      <p className="txt-1 mg-5">
        회원은 팬어스 서비스를 이용해서 제작한 내용을 게시하기 전에 내용 또는
        이미지에 대한 적법한 권리를 소유하거나 보유하고 있어야 합니다. 회원의
        서비스 이용은 회원에게 다른 이용자들로부터 제공된 데이터에 관한 권리를
        부여하지 않습니다.
      </p>
      <p className="txt-1 mg-5">
        회사는 자체 제작한 콘텐츠와 제3자로부터 저작권을 양수 받은 콘텐츠에 대한
        모든 권리를 소유하고 있습니다. 팬어스의 콘텐츠는 여러분이 보는 목적으로
        팬어스 서비스 상에 게재한 부호, 문자, 음성, 음향, 그림, 사진, 동영상,
        링크 등으로 구성된 각종 콘텐츠 자체 또는 파일을 말합니다.
      </p>
      <p className="txt-1 mg-24">
        회원은 회사의 사전 동의 없이는 팬어스 서비스 또는 서비스에 포함된
        소프트웨어의 전부 또는 일부를 복제, 변경, 배포, 판매, 이전, 임대, 담보
        제공, 타인에 대한 사용 허락을 하여서는 안 됩니다. 그에 더하여, 회원은
        서비스와 관련된 설계 또는 팬어스 서비스에 포함된 소프트웨어 소스코드
        추출 시도, 서비스의 복제, 분해, 재현, 기타 변경 행위 등 서비스의 원활한
        운영을 방해할 목적으로 서비스 기능을 비정상적으로 이용하는 행위는
        금지됩니다.
      </p>
      <h4 className="h-2 mg-9">
        여러분의 데이터가 서비스 향상을 위해 사용될 수 있습니다.
      </h4>
      <p className="txt-1 mg-5">
        회사는 서비스의 원활한 제공을 위하여 회원이 동의한 목적과 범위 내에서만
        개인정보를 수집하고 이용하며, 개인정보 보호 관련 법령에 따라 안전하게
        관리합니다.
      </p>
      <p className="txt-1 mg-5">
        회사는 서비스의 운영, 발전 및 홍보와 새로운 서비스를 개발하기 위한
        목적으로 회원의 데이터에 대한 어떤 권리를 부여할 것을 필요로 합니다.
        예를 들어 서버 증설 등의 목적에 따라 각 계정 별로 이미지 사용, 음성변환
        문자량, 콘텐츠 배포처 등에 대한 로그를 수집합니다.
      </p>
      <p className="txt-1 mg-5">
        회사는 팬어스 서비스 및 관련 기술의 이용 및 성과와 관련한 다른 정보를
        수집, 이용 및 분석할 수 있고, 그것을 사업적으로 이용하거나 사업과
        관련하여 통계적 또는 식별 불가능한 형태로 공개할 수 있습니다. 여러분의
        어떤 정보가 수집 및 이용되는지는 개인정보 취급방침을 통하여 보다 많은
        정보를 확인할 수 있습니다.
      </p>
      <p className="txt-1 mg-24">
        회사는 팬어스를 통해 제작된 이미지 및 비디오 콘텐츠에 대한 비식별적 메타
        데이터를 광고 플랫폼과 공유하여 성과를 분석할 수 있으며, 이는 팬어스의
        디자인 엔진 개선을 위해 활용될 수 있습니다.
      </p>
      <h4 className="h-2 mg-9">이용과 관련하여 주의사항이 있습니다.</h4>
      <p className="txt-1 mg-9">
        회사는 여러분이 서비스를 이용할 수 있도록 최선을 다하고 있습니다.
        여러분이 팬어스 서비스를 보다 편리하게 이용하고 팬어스 서비스의 이용
        권리 보호를 위해 서비스 이용이 제한될 수 있는 항목을 안내하고 이에 대한
        확인 및 준수를 요청합니다.
      </p>
      <p className="txt-1 mg-9">
        관련 법령, 본 약관, 운영정책 등을 준수하지 않을 경우 회사는 여러분의
        관련 행위 내용을 확인할 수 있으며, 그 확인 결과에 따라 팬어스 서비스
        이용에 대한 주의를 당부하거나, 서비스 이용을 일부 또는 전부, 일시 또는
        영구히 정지시키는 등 그 이용을 제한할 수 있습니다. 이러한 이용 제한에도
        불구하고 이용계약의 온전한 유지를 기대하기 어려운 경우 여러분과의 이용
        계약을 해지할 수 있습니다.
      </p>
      <p className="txt-1 mg-5">
        - 불쾌함, 외설스러움, 무례한, 외설적인 내용, 불쾌감, 위협, 불법, 명예
        훼손, 침해, 학대, 염증, 괴롭힘, 저속한 내용, 사기성, 사생활 침해 또는
        홍보 또는 인격적인 내용의 콘텐츠 인종적, 윤리적, 성적, 정치적 또는
        인본적으로 용납될 수 없는 모든 콘텐츠
      </p>
      <p className="txt-1 mg-5">
        - 스팸, 정크 메일, 피라미드 (방식), 폰지 (사기), 또는 기타 방법으로 금융
        활동 참여를 권유하는 행위
      </p>
      <p className="txt-1 mg-5">
        - 서비스를 통해 전송되는 모든 콘텐츠의 출처를 위장하기 위해 조작하는
        행위
      </p>
      <p className="txt-1 mg-5">
        - 거짓 개인정보로 등록을 하거나 타인의 명의를 도용하여 계정을 만들고
        사용하는 경우
      </p>
      <p className="txt-1 mg-5">
        - 계정을 판매, 양도, 대여 또는 담보로 제공하거나 다른 사람에게 사용을
        허락하는 경우
      </p>
      <p className="txt-1 mg-5">- 승인되지 소프트웨어 코드가 포함된 콘텐츠</p>
      <p className="txt-1 mg-5">
        - 우리가 납득할 수 있는 선에서 바람직하지 않다고 생각되거나 다른 사람이
        서비스를 사용하거나 즐기는 데 있어 방해가 되는 내용의 콘텐츠
      </p>
      <p className="txt-1 mg-5">
        - 형사 범죄, 테러 행위 또는 기타 불법 또는 범죄 행위에 대한 지침을 권장
        또는 제공하는 내용의 콘텐츠
      </p>
      <p className="txt-1 mg-9">
        - 의도적이든 비의도적이든 지역, 국가 및 국제법을 준수하지 않는 모든
        종류의 콘텐츠
      </p>
      <p className="txt-1 mg-24">
        회사는 본 약관을 위반하는 내용의 부적절한 콘텐츠로 간주되는 사용자
        콘텐츠에 대해 거부, 이동 또는 삭제에 대한 권리를 보유합니다.
      </p>
      <h4 className="h-2 mg-9">온라인 쇼핑 서비스를 제공합니다.</h4>
      <p className="txt-1 mg-5">
        회사는 상품 구매에 대한 정보 제공 및 구매 신청을 통한 구매계약의 체결이
        발생하기 위한 구매계약 체결이 가능한 팬어스 온라인 쇼핑 서비스를
        제공합니다.
      </p>
      <p className="txt-1 mg-24">
        온라인 쇼핑 서비스에서는 상품이 품절되거나 기술적 사양의 변경 등으로 더
        이상 제공할 수 없는 경우에는 제공할 상품의 내용을 변경할 수 있습니다. 이
        경우 변경된 상품의 내용 및 일자를 안내하여 현재의 상품 내용을 게시한 곳
        또는 상품과 연관된 곳에 안내를 합니다.
      </p>
      <h4 className="h-2 mg-9">구매신청을 통한 구매계약 체결이 성립됩니다.</h4>
      <p className="txt-1 mg-5">
        회원은 팬어스에서 구매를 신청하며, 회원이 구매 신청을 함에 있어 상품
        등에 대한 거래조건을 정확하게 이해하고 거래할 수 있도록 관계법령상
        요구하는 정보를 알기 쉽게 표시 및 고지합니다.
      </p>
      <p className="txt-1 mg-5">
        회사는 상품의 선택, 주문자 정보, 배송방법, 주문자 정보 입력, 약관,
        구매계약 체결 제한상품(해당하는 제품의 경우), 배송료 등의 비용부담 등
        구매 조건과 관련한 내용에 대한 확인, 구매조건에 대한 동의 표시, 상품의
        구매 신청 및 이에 대한 확인 동의, 결제방법의 선택 및 결제 등을 제공해
        구매계약 체결이 성립할 수 있도록 제공합니다.
      </p>
      <p className="txt-1 mg-24">
        회사는 구매 신청 시 회원으로부터 개인정보의 수집, 이용, 제공 및 위탁에
        관하여 동의를 받을 수 있습니다. 이 때 관계법령상 요구되는 사항을
        회원에게 고지하고 동의를 받습니다. 개인정보 처리와 관련하여 개인정보
        처리방침에 따라 관련 법령을 준수합니다.
      </p>
      <h4 className="h-2 mg-9">구매계약이 취소될 수 있습니다.</h4>
      <p className="txt-1 mg-5">
        구매계약의 성립에 문제가 발생하는 사유가 존재하거나 존재할 수 있는
        것으로 볼만한 사정이 존재하는 경우, 회원의 구매 신청을 승낙하지 않을 수
        있습니다. 또한 19세 미만의 미성년자와 계약이 체결되는 경우 법정대리인의
        동의를 얻지 못하면 미성년자 본인 또는 법정대리인의 계약을 취소될 수
        있습니다.
      </p>
      <p className="txt-1 mg-5">
        회사는 신청 내역에 허위, 기재누락, 오기가 있는 경우가 있으며,
        상거래행위(재판매)를 목적으로 구매하는 거래이거나 거래 정황상
        상거래행위를 목적으로 한 구매인 경우도 계약이 취소될 수 있습니다.
      </p>
      <h4 className="h-2 mg-9">대금지급방법을 제공하고 있습니다.</h4>
      <p className="txt-1 mg-9">
        회사는 회원이 구매한 상품에 대한 대금지급방법을 제공하고 있습니다. 단,
        대금지금방법은 회사의 내부 정책 및 관리, 기술적 사유에 따라 변경될 수
        있습니다.
      </p>
      <p className="txt-1 mg-5">
        - 선불카드, 직불카드, 신용카드 등의 각종 카드 결제
      </p>
      <p className="txt-1 mg-5">- 폰뱅킹, 인터넷뱅킹 등의 각종 계좌이체</p>
      <p className="txt-1 mg-5">- 전자화폐에 의한 결제</p>
      <p className="txt-1 mg-5">
        - 회사와 계약을 맺었거나 회사가 인정한 상품권에 의한 결제
      </p>
      <p className="txt-1 mg-9">- 기타 전자적 지급 방법에 의한 대금 지급 등</p>
      <p className="txt-1 mg-5">
        회사는 회원의 구매금액에 대하여 내부정책 및 외부 결제업체(은행, 카드사,
        통신사 등), 기타 관련 법령의 변경에 따라 월 누적 결제액, 결제한도 등의
        거래한도를 정할 수 있으며, 거래한도 발생으로 인한 상품구매가 불가능할 수
        있습니다.
      </p>
      <h4 className="h-2 mg-9">구매계약 상품 등의 공급을 합니다.</h4>
      <p className="txt-1 mg-5">
        회사는 상품의 공급시기에 관해 별도의 특이 사항이 없는 이상 구매계약
        체결이 발생한 날로부터 영업일 기준 7일 이내 상품의 배송에 필요한 조치를
        취하고, 회사가 이미 상품 대금의 전부 또는 일부를 지급받은 경우 대금의
        전부 또는 일부를 지급받은 날부터 영업일 기준 3일 이내에 조치를 취합니다.
        단, 천재지변 등 불가항력적인 사유가 발생한 경우 해당 기간은 소요기간에서
        제외됩니다. 회사는 구매 체결한 회원의 상품 공급 절차 및 진행 사항을
        확인할 수 있도록 적절한 조치를 취합니다.
      </p>
      <p className="txt-1 mg-24">
        회사는 상품 준비 시간이 소요되거나 배송이 제한적인 지역에 대하여는 그
        공급시점에 관해 소비자에게 별도로 고지하고 동의를 얻어 이와 달리 공급할
        수 있습니다. 회사는 배송수단, 배송비용, 배송기간 등을 안내하여
        명시합니다.
      </p>
      <h4 className="h-2 mg-9">환급 처리의 조치를 취합니다.</h4>
      <p className="txt-1 mg-24">
        회사는 구매 체결한 회원이 구매 신청한 상품을 품절 등의 사유로 제공할 수
        없을 때 지체 없이 그 사유를 통지하고 사전에 상품의 대금을 받은 경우에는
        대금을 받은 날로부터 영업일 기준 3일 이내 환급하거나 환급에 필요한
        조치를 취합니다.
      </p>
      <h4 className="h-2 mg-9">체결된 구매계약은 철회할 수 있습니다.</h4>
      <p className="txt-1 mg-5">
        회사와 상품의 구매에 관한 계약을 체결한 회원은 체결 발생한 날로부터
        7일이내에 구매계약 철회를 할 수 있습니다. 구매계약 철회에 관해
        전자상거래 등에서의 소비자보호에 관한 법률에 달리 정함이 있는 경우에는
        법률 규정에 따릅니다.
      </p>
      <p className="txt-1 mg-9">
        회사는 취소, 환불 처리 조치에 관해 상품 설명에 표시하여 방법 안내를
        합니다. 단, 취소, 환불 조치에 다음 사항은 제한될 수 있습니다.
      </p>
      <p className="txt-1 mg-5">
        - 환불 요청 가능 기간이 초과한 경우 배송 완료 시점으로부터 7 일 초과 시
      </p>
      <p className="txt-1 mg-5">
        - 주문 제작 상품의 경우 상품의 제작이 이미 진행된 경우
      </p>
      <p className="txt-1 mg-5">- 분실 파손 고장 오염이 발생한 경우</p>
      <p className="txt-1 mg-5">
        - 모니터 해상도의 차이로 인해 색상이나 이미지가 실제와 달라 고객인 단순
        변심으로 처리를 요청하는 경우
      </p>
      <p className="txt-1 mg-5">
        - 재고가 부족한 경우 단순 변심으로 요청했으나 재고가 부족한 경우 환불
        처리될 수 있음
      </p>
      <p className="txt-1 mg-5">
        - 상품의 훼손 및 구성품 누락으로 상품의 가치가 현저히 감소된 경우
      </p>
      <p className="txt-1 mg-5">- 설치 또는 사용하여 재판매가 어려운 경우</p>
      <p className="txt-1 mg-5">
        - 상품의 시리얼 넘버 유출로 내장된 소프트웨어의 가치가 감소한 경우
      </p>
      <p className="txt-1 mg-5">
        - 상품을 개봉하여 장착한 이후 단순 변심인 경우
      </p>
      <p className="txt-1 mg-9">- 복제가 가능한 상품의 포장 등을 훼손한 경우</p>
      <p className="txt-1 mg-24">
        회사는 구매계약 철회에 제한되는 사항을 상품의 판매 페이지에 이용자가
        확인할 수 있는 곳에 명시하거나 제공하는 등의 조치를 취합니다. 위
        규정에도 불구하고 상품의 내용이 표시, 광고 내용과 다르거나 계약 내용과
        다르게 이행된 경우에는 상품을 공급받은 날부터 3개월 이내, 그 사실을 알게
        된 날 또는 알 수 있었던 날부터 30일 이내에 구매계약 철회를 할 수
        있습니다.
      </p>
      <h4 className="h-2 mg-9">
        프리오더 상품의 구매계약, 철회 원칙이 있습니다.
      </h4>
      <p className="txt-1 mg-5">
        프리오더 상품은 사전 예약으로 주문 제작되는 과정을 거쳐 진행됩니다.
        프리오더 상품의 예약구매 신청 방법은 구매 신청으로 구매계약을 성립하는
        일반 상품의 구매계약 신청 처리 방법과 동일합니다. 다만 프리오더가 표시된
        상품의 상세 정보 및 원칙을 추가적으로 안내하며 프리오더에 유의사항이
        포함된 구매계약이 성립됩니다. 프리오더의 용어는 아래와 같이 정의합니다.
      </p>
      <p className="txt-1 mg-5">
        - '예약 기간'은 상품 구매 가능한 기간으로 회원에게 예약판매 할 수 있는
        기간으로 판매 종료일까지 회원에게 판매 신청을 받는 기간을 의미합니다.
      </p>
      <p className="txt-1 mg-5">
        - 프리오더 상품의 '구매 신청'은 예약구매 신청으로 상품에 대한 구매계약에
        성립을 의미합니다.
      </p>
      <p className="txt-1 mg-5">
        - '사전 예약'은 예약구매 상품의 신청으로 주문 제작되는 상품을 미리
        예약하는 구매계약이 성립된 상태를 말합니다.
      </p>
      <p className="txt-1 mg-24">
        - 순차 배송'은 예약구매 신청으로 구매계약이 성립된 주문 제작되는 상품을
        정해진 일자에 배송을 순서대로 발송하는 방법입니다.
      </p>
      <h4 className="h-2 mg-9">
        프리오더의 유의사항은 아래와 같이 안내합니다.
      </h4>
      <p className="txt-1 mg-5">
        프리오더의 예약구매를 신청하기 전에 상품에 제공되는 정보뿐 아니라
        예약구매 기간, 배송, 취소, 환불 정책의 내용을 충분히 숙지한 후
        구매계약을 성립하여야 합니다. 프리오더의 상품은 일반 상품과 다른 정책이
        발생되고 있음을 숙지하고 구매계약에 성립해야 합니다.
      </p>
      <p className="txt-1 mg-5">
        프리오더 상품의 상세 페이지 내에 작성한 상세 내용과 거래의 조건을
        정확하게 확인해야 합니다. 구매하는 거래의 조건을 확인하지 않고 구매하여
        발생한 모든 손해는 회원님이 부담합니다.
      </p>
      <p className="txt-1 mg-5">
        회사는 상품에 대한 이용 편의를 위해 정보와 콘텐츠를 제공하는 경우에도
        구매 여부는 회원님의 판단과 책임으로 결정하여야 합니다. 이 경우 회사는
        고의 또는 중과실이 있는 경우를 제외하고 회원의 구매 결정에 대하여 책임을
        부담하지 않습니다.
      </p>
      <p className="txt-1 mg-5">
        예약 기간이 종료되면 회사는 판매 종료 시점부터 발송처리 진행으로
        간주되어 취소가 아닌 교환, 환불 절차에 따라 처리됩니다. 발송처리 전
        상태에서는 취소 신청 접수 시 특별한 사정이 없는 한 즉시 취소처리를 위한
        조치가 완료됩니다.
      </p>
      <p className="txt-1 mg-5">
        전자상거래 등에서의 소비자보호에 관한 법률 시행령 제21조에 따라 프리오더
        상품은 소비자의 주문에 따라 개별적으로 생산되는 재화등 또는 이와 유사한
        재화등에 대하여 회사에게 회복할 수 없는 피해가 예상되는 경우로 사전 해당
        상품에 대한 별도로 사실을 고지한 기반으로 구매계약에 대한 철회가 제한될
        수 있습니다.
      </p>
      <p className="txt-1 mg-24">
        프리오더의 상품은 최대 90일 이내 모든 상품을 받을 수 있도록 조치됩니다.
      </p>
      <h4 className="h-2 mg-9">구매계약 철회 등의 효과</h4>
      <p className="txt-1 mg-5">
        회사는 구매계약 철회한 회원으로부터 상품을 반환 받은 경우 영업일 기준
        3일 이내에 이미 지급받은 상품의 대금을 환급합니다. 회사는 상품 대금의
        환급을 지연하는 경우 그 지연기간에 대한 전자상거래 등에서의 소비자보호에
        관한 법률 시행령에서 정하는 지연이자율을 산정하여 지연이자를 지급합니다.
      </p>
      <p className="txt-1 mg-5">
        상품 대금을 환급함에 있어 신용카드 또는 전자화폐 등의 결제수단으로
        상품의 대금을 지급한 경우 지체 없이 결제수단을 제공한 사업자로 하여금
        상품 대금의 청구를 정지 또는 취소하도록 요청합니다.
      </p>
      <p className="txt-1 mg-24">
        구매계약 철회 등의 경우 공급받은 상품 반환에 필요한 비용은 구매한 회원이
        부담합니다. 회사는 구매계약 철회 등을 이유로 구매한 회원에게 위약금 또는
        손해배상을 청구하지 않습니다. 다만, 상품의 내용, 표시, 광고 내용과
        다르거나 계약내용과 다르게 이행되어 청약철회 등을 하는 경우 상품의
        반환에 필요한 비용은 회사가 부담합니다.
      </p>
      <h4 className="h-2 mg-9">저작권 보호</h4>
      <p className="txt-1 mg-5">
        회사의 팬어스 서비스에 게재된 내용은 저작물에 대한 소유권을 포함한
        지적재산권 일체는 회사에게 있으며, 회사가 소유한 저작권 기타
        지식재산권을 회사의 사전 승낙 없이 복제, 송신, 출판, 배포, 방송 기타의
        방법으로 이용하거나 제3자에게 이용하도록 하는 등의 침해행위를 하여서는
        안 됩니다. 이용하는 모든 사용자는 회사의 소유권을 포함한 지적재산권
        일체를 지켜야 할 의무가 있습니다.
      </p>
      <p className="txt-1 mg-5">
        팬어스를 이용하면서 직접 등록한 콘텐츠의 저작권은 이를 작성한 회원에게
        있으며, 해당 콘텐츠의 저작권 침해를 비롯한 일체의 책임을 작성한 회원이
        부담합니다.
      </p>
      <p className="txt-1 mg-9">
        회사는 등록한 콘텐츠 등 게시물이 다음에 해당하는 경우, 작성한 회원에게
        사전통보 없이 게시물을 숨김 또는 삭제할 수 있습니다.
      </p>
      <p className="txt-1 mg-5">
        - 법령을 위반하거나 공공질서 및 미풍양속에 위배되는 내용을 포함하는 경우
      </p>
      <p className="txt-1 mg-5">- 허위 또는 과대광고의 내용을 포함하는 경우</p>
      <p className="txt-1 mg-5">
        - 정치, 사회, 경제적 분쟁을 야기할 수 있는 내용을 포함하는 경우
      </p>
      <p className="txt-1 mg-5">
        - 회사를 포함한 제3자의 권리나 개인정보, 명예, 신용 기타 정당한 권리
        또는 이익을 침해하는 경우
      </p>
      <p className="txt-1 mg-5">
        - 특정 단어나 문구를 반복 삽입하거나 의미 없는 특수기호 내지 부호,
        외국어로 작성된 경우
      </p>
      <p className="txt-1 mg-5">
        - 회사가 제공하는 방법에 의하지 아니하고 비정상적인 방법으로 작성 및
        게시하는 경우
      </p>
      <p className="txt-1 mg-5">
        - 상품이 품절되거나 기술적 사양의 변경 등으로 특정 상품의 판매가
        중단되어 더 이상 제공할 수 없는 경우
      </p>
      <p className="txt-1 mg-5">
        - 회사 운영과 관련하여 관리적, 기술적 필요 내지 사유가 있다고 합리적으로
        판단하는 경우
      </p>
      <p className="txt-1 mg-24">
        - 회사가 제공하는 서비스의 원활한 제공을 방해하는 경우
      </p>
      <h4 className="h-2 mg-9">권리 위반에 대한 의무가 발생합니다.</h4>
      <p className="txt-1 mg-24">
        회사는 팬어스 서비스에 권리 위반에 대한 책임이 발생하고 권리를 지키기
        위해 노력해야 합니다. 여러분은 모든 권리 위반에 대해 발생하는 모종의
        손해와 손실, 경비, 비용 또는 법적 책임, 발생하는 클레임, 요구, 혐의에
        대해 회사에 보상을 약속하고 이에 동의해야 합니다.
      </p>
      <h4 className="h-2 mg-9">책임을 명확히 합니다.</h4>
      <p className="txt-1 mg-5">
        회사는 여러분이 팬어스 서비스를 이용함에 있어 회사의 고의 또는 과실로
        인하여 손해를 입게 될 경우 관련 법령에 따라 여러분의 손해를 배상합니다.
        다만, 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수
        없거나 사용자의 고의 또는 과실로 인하여 서비스를 이용할 수 없어 발생한
        손해에 대해서 회사는 책임을 부담하지 않습니다.
      </p>
      <p className="txt-1 mg-5">
        회사가 손해배상책임을 부담하는 경우에도 통상적으로 예견이 불가능하거나
        특별한 사정으로 인한 특별 손해 또는 간접 손해, 기타 징벌적 손해에
        대해서는 관련 법령에 특별한 규정이 없는 한 책임을 부담하지 않습니다.
      </p>
      <p className="txt-1 mg-24">
        그리고 관계법령의 변경, 천재지변 또는 이에 준하는 불가항력으로 인한 유료
        서비스를 제공할 수 없는 경우에는 유료 서비스 제공에 관한 책임이
        면제됩니다. 회원의 귀책사유로 인한 유료 서비스 이용 장애 대하여는 회사의
        귀책사유가 없는 한 책임을 지지 않습니다.
      </p>
      <h4 className="h-2 mg-9">언제든지 이용계약을 해지할 수 있습니다.</h4>
      <p className="txt-1 mg-5">
        회원은 언제든지 팬어스 서비스 이용계약 해지를 신청하여 회원에서 탈퇴할
        수 있으며, 회사는 관련 법령 등이 정한 바에 따라 이를 지체 없이
        처리하겠습니다. 그리고 다른 소셜 서비스 계정을 이용해 가입한 회원은 동의
        철회를 통해 회원이 이용계약 제공을 중단할 수 있습니다.
      </p>
      <p className="txt-1 mg-24">
        팬어스 서비스 이용계약이 해지되면, 관련 법령 및 개인정보 취급방침에 따라
        해당 회원의 정보를 보유할 수 있는 경우를 제외하고, 회원의 모든 데이터는
        소멸됨과 동시에 복구할 수 없습니다.
      </p>
      <h4 className="h-2 mg-9">서비스를 제공하기 위해 최선을 다하겠습니다.</h4>
      <p className="txt-1 mg-5">
        회사는 연중무휴, 1일 24시간 서비스를 제공하기 위해 최선을 다하고
        있습니다. 하지만 정보통신 설비 컴퓨터, 서버 등의 보수 점검, 교체, 고장,
        통신 두절 등 부득이 서비스 운영의 이유가 있는 경우 서비스의 전부 또는
        일부를 중단할 수 있습니다. 그리고 회사는 서비스 운영 개선을 위해
        필요성이 있는 경우 서비스의 전부 또는 일부를 수정, 변경, 종료할 수
        있으며, 이 경우 관련 법령에 특별한 규정이 없는 한 별도의 보상을 하지
        않습니다.
      </p>
      <p className="txt-1 mg-24">
        이러한 상황에서 팬어스는 예측 가능한 경우 이를 안내하며, 만약 예측
        불가능한 경우라면 사후 지체 없이 설명하고 안내 드리겠습니다.
      </p>
      <h4 className="h-2 mg-9">분쟁 해결은 법령 적용하여 절차를 준수합니다.</h4>
      <p className="txt-1 mg-24">
        본 약관 또는 팬어스 서비스와 관련된 여러분과 관계에는 대한민국의 법령이
        적용됩니다. 그리고 본 약관 또는 팬어스 서비스와 관련하여 여러분과 회사
        사이에 분쟁이 발생할 경우, 그 분쟁의 처리는 대한민국 ‘민사소송법’에서
        정한 절차를 따릅니다. 발생된 소송은 회사의 주소를 관할하는 법원을 전속
        관할로 지정합니다.
      </p>
      <h4 className="h-2 mg-9">약관 및 운영정책을 게시합니다.</h4>
      <p className="txt-1">
        회사는 수시로 본 약관, 계정 및 게시물 운영정책을 개정할 수 있습니다.
        하지만 관련 법령을 위배하지 않는 범위 내에서 개정할 것이며, 사전에 그
        개정 이유와 적용 일자를 서비스 7일 전부터 알리도록 하겠습니다. 또한
        여러분에게 불리할 수 있는 중대한 내용의 약관 변경은 해당 서비스 내
        공지하고 가능한 전자적 수단으로 개별적으로 알릴 것입니다. 변경된 약관을
        게시한 날로부터 효력이 발생되는 날까지 이의신청이 없는 경우 변경된
        약관에 따라 서비스를 이용하는 데 동의하는 것으로 간주됩니다.
      </p>
    </div>
  );
}

export default TermsPolicy;
