import React from 'react';
import styled from 'styled-components';
import Image from 'components/common/Image';
import Colors from 'styles/color-system';
import useUIInteractionValue from 'hook/useUIInteractionValue';

interface Props {
  image: string;
  nameKO: string;
  nameEN: string;
}

interface StyledProps {
  isWhite: boolean;
}

function HomeProfile({ image, nameKO, nameEN }: Props) {
  const { topNavthemeIsWhite: isWhite } = useUIInteractionValue();

  return (
    <Container>
      <NameSection isWhite={isWhite}>
        <NameKO>{nameKO}</NameKO>
        <NameEN>{nameEN}</NameEN>
      </NameSection>
      <ImageWrapper>
        <Image src={image} />
      </ImageWrapper>
    </Container>
  );
}

export default HomeProfile;

const Container = styled.div`
  position: relative;
  width: 100%;

  &::after {
    content: '';
    display: block;
    width: 100%;
    padding-bottom: 100%;
  }
`;

const ImageWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
`;

const NameSection = styled.div<StyledProps>`
  position: absolute;
  left: 50%;
  bottom: 16px;
  transform: translate3d(-50%, 0, 0);
  z-index: 50;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  color: ${({ isWhite }) => (isWhite ? Colors.White[500] : Colors.Gray[700])};
`;

const NameKO = styled.span`
  font-size: 30px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.1em;
`;
const NameEN = styled.span`
  font-size: 15px;
  font-weight: 500;
  line-height: normal;
`;
