import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Context as AuthContext } from './contexts/AuthContext';
import PATH from './Path';
import React, { useContext, useEffect } from 'react';
import useOverlay from './hook/useOverlay';
import BasicPopup from './components/overlays/BasicPopup';

export const ProtectedRoute = () => {
  const {
    state: { accessToken },
  } = useContext(AuthContext);

  const location = useLocation();
  const navigate = useNavigate();

  const { showHandler: showOverlay, hideHandler: hideOverlay } = useOverlay();

  useEffect(() => {
    if (!accessToken) {
      const popupId = showOverlay(
        <BasicPopup
          message={'로그인이 필요합니다.\n로그인 하시겠습니까?'}
          onLeftClick={() => {
            hideOverlay(popupId);
            navigate(-1);
          }}
          onRightClick={() => {
            hideOverlay(popupId);
            navigate(PATH.SignIn);
          }}
        />,
        'POPUP',
      );
    }
  }, []);

  if (!accessToken) {
    return <></>;
  }

  return <Outlet />;
};
