import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import HeaderNavBar from 'components/common/HeaderNavBar';
import Colors from 'styles/color-system';
import DateUtils from 'utils/DateUtils';
import useOverlay from 'hook/useOverlay';
import Image from 'components/common/Image';
import GalleryPopup from 'components/overlays/GalleryPopup';
import { UserService } from 'services/UserService';
import { CollectionDetailDto } from 'dto/CollectionDto';
import useProtection from 'hook/useProtection';
import ItemNotFound from 'components/common/ItemNotFound';

function CollectionDetailPage() {
  useProtection();
  const { showHandler, hideHandler } = useOverlay();
  const { collectionId } = useParams();
  const [serchParams] = useSearchParams();
  const collectionItemId = parseInt(serchParams.get('collection')!) || 0;
  const navigate = useNavigate();
  const [detail, setDetail] = useState<CollectionDetailDto>();
  const [hideId, setHideId] = useState<string>();

  useEffect(() => {
    if (collectionId) {
      UserService.collectionDetail(collectionId).then((response) => {
        const result = response.data as CollectionDetailDto;
        setDetail(result);
        console.log(result);
      });
    }
  }, []);

  useEffect(() => {
    if (collectionItemId) {
      handleShowView(collectionItemId);
    }

    if (!collectionItemId) {
      hideHandler(hideId!);
    }
  }, [collectionItemId]);

  const handleShowView = (id: number) => {
    const hideId = showHandler(
      <GalleryPopup
        list={detail?.items}
        currentId={id}
        onClose={() => navigate(-1)}
      />,
      'POPUP',
    );
    setHideId(hideId);
  };

  if (!detail) {
    return <ItemNotFound message="굿즈 콜렉션을 찾을 수 없습니다." />;
  }

  return (
    <Container>
      <HeaderNavBar leftItem="BACK" />
      <ScrollSection>
        <InfoSection>
          <span className="name">{detail.title}</span>
          <span className="type">{detail.sub_title}</span>
          <span className="date">
            등록일&nbsp;:&nbsp;
            {DateUtils.convertDateToYYMMDD(
              DateUtils.convertTimeToDate(detail.created),
            )}
          </span>
        </InfoSection>
        <Copyright>
          저작권법에 따라 본 페이지에서만 이용 가능하며, 이 외의 공간에서
          저작물을 공유 또는 게시하는 행위는 저작권법 위반에 해당됩니다.
        </Copyright>
        <ListSection>
          {detail.items.map((item, index) => (
            <Item
              key={item.id}
              onClick={() => navigate(`?collection=${index + 1}`)}
            >
              <ImageWapper>
                <Image src={item.url} />
              </ImageWapper>
            </Item>
          ))}
        </ListSection>
      </ScrollSection>
    </Container>
  );
}

export default CollectionDetailPage;

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  height: 100%;
  background: ${Colors.Background[500]};
  overflow: hidden;
`;

const ScrollSection = styled.div`
  flex: 1;
  overflow: hidden auto;
`;

const InfoSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-inline: 24px;
  box-sizing: border-box;
  margin-top: 30px;
  margin-bottom: 19px;

  & .name {
    color: ${Colors.White[500]};
    font-size: 18px;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 8px;
  }

  & .type {
    color: ${Colors.White[500]};
    font-size: 24px;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 19px;
  }

  & .date {
    color: ${Colors.Gray[300]};
    font-size: 15px;
    font-weight: 400;
    line-height: normal;
  }
`;

const Copyright = styled.span`
  position: relative;
  display: flex;
  width: 100%;
  padding-top: 11px;
  padding-inline: 24px;
  box-sizing: border-box;
  margin-bottom: 30px;

  color: ${Colors.Gray[300]};
  font-size: 15px;
  font-weight: 400;
  line-height: normal;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 12px;
    width: calc(100% - 24px);
    height: 1px;
    background: ${Colors.Gray[700]};
  }
`;

const ListSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 3px;
`;

const Item = styled.div`
  position: relative;
  width: calc((100% - 6px) / 3);
  height: 0;
  padding-bottom: calc((100% - 3px) / 3);
  box-sizing: border-box;
  cursor: pointer;
  user-select: none;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const ImageWapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: ${Colors.Gray[700]};
`;
