import React, { useRef } from 'react';
import styled from 'styled-components';
import Colors from 'styles/color-system';
import html2canvas from 'html2canvas';
// import Image from 'components/common/Image';
import { QRCodeSVG } from 'qrcode.react';

interface Props {
  onClose: () => void;
  thumbnail: string;
  title: string;
  fileName: string;
  location: string;
  date: string;
  qrcodeLink: string;
  orderNumber: string;
}

function TicketPopup({
  thumbnail,
  title,
  fileName,
  location,
  date,
  orderNumber,
  qrcodeLink,
  onClose,
}: Props) {
  const captureRef = useRef<HTMLDivElement>(null);

  const handleClose = () => {
    onClose();
  };

  const getCanvas = async () => {
    if (!captureRef.current) return;
    const canvas = await html2canvas(captureRef.current, {
      backgroundColor: 'rgba(0,0,0,0)',
      allowTaint: true,
      useCORS: true,
      logging: true,
      proxy: thumbnail,
    });
    const url = canvas.toDataURL('image/png');

    return url;
  };

  const handleSaveImage = async () => {
    const shareData = (await getCanvas()) || '';
    const link = document.createElement('a');
    document.body.appendChild(link);
    link.href = shareData;
    link.download = `${fileName}.png`;
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Container>
      <TicketSection ref={captureRef}>
        <TopSection>
          <Thumnbnail>
            <img
              src={`${thumbnail}?timestamp=${Date.now()}`}
              crossOrigin="anonymous"
            />
          </Thumnbnail>
          <TicketName>{title}</TicketName>
        </TopSection>
        <MiddleSection>
          <span className="notch-left" />
          <span className="notch-right" />
          <span className="divider" />
        </MiddleSection>
        <BottomSection>
          <InfoSection>
            <span className="location">{location}</span>
            <span className="date">{date}</span>
            <span className="order-number">{orderNumber}</span>
          </InfoSection>
          <QRCode>
            <QRCodeSVG value={qrcodeLink} />
          </QRCode>
        </BottomSection>
      </TicketSection>
      <SaveImageButton onClick={handleSaveImage}>
        디지털 티켓 이미지 저장
      </SaveImageButton>
      <Outer onClick={handleClose} />
    </Container>
  );
}

export default TicketPopup;

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  pointer-events: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 22px;
  overflow: hidden;
`;

const TicketSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 314px;
  height: 488px;
  border-radius: 10px;
  overflow: hidden;
  z-index: 100;
`;
const SaveImageButton = styled.button.attrs({ type: 'button' })`
  width: 236px;
  height: 58px;
  background: ${Colors.Primary[500]};
  border-radius: 6px;
  overflow: hidden;
  box-shadow: 0px -4px 4px 0px rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${Colors.White[500]};
  font-size: 20px;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
  user-select: none;
  z-index: 100;
`;

const TopSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  height: 385px;
  background: ${Colors.White[500]};
  padding: 12px 12px 14px 12px;
  box-sizing: border-box;
`;

const Thumnbnail = styled.div`
  width: 100%;
  height: calc(100% - (15px + 54px));
  border-radius: 8px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
    pointer-events: none;
  }
`;

const TicketName = styled.span`
  width: 100%;
  height: 54px;
  padding-inline: 3px;
  box-sizing: border-box;
  white-space: pre-line;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 22px;
  font-weight: 700;
  line-height: 27px;
  letter-spacing: -0.44px;
`;

const MiddleSection = styled.div`
  position: relative;
  width: 100%;
  height: 12px;

  & .divider {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    width: 300px;
    height: 100%;
    background: ${Colors.White[500]};
  }

  & .divider::after {
    content: '';
    position: absolute;
    top: 50%;
    transform: translate3d(0, -50%, 0);
    left: 3px;
    width: calc(100% - 6px);
    border-bottom: 3px dotted #09090b;
  }

  & :is(.notch-left, .notch-right) {
    position: absolute;
    top: 50%;
    transform: translate3d(0, -50%, 0);
    width: 12px;
    height: 12px;
  }

  & .notch-left {
    left: -5px;
  }

  & .notch-right {
    right: -5px;
  }

  & :is(.notch-left, .notch-right)::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0) rotate(45deg);
    width: 100%;
    height: 100%;
    background: transparent;
    border: 5px solid ${Colors.White[500]};
    border-radius: 50%;
  }
`;

const BottomSection = styled.div`
  width: 100%;
  height: 91px;
  padding: 8px 15px 15px 15px;
  box-sizing: border-box;
  background: ${Colors.White[500]};
  display: flex;
  gap: 6px;

  & :is(.location, .date) {
    color: ${Colors.Gray[800]};
    font-size: 15px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: -0.3px;
  }

  & .date {
    margin-bottom: 5px;
  }

  & .order-number {
    color: ${Colors.Gray[300]};
    font-size: 13px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.26px;
  }
`;

const InfoSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const QRCode = styled.div`
  width: 66px;
  height: 66px;
  padding: 3px;
  box-sizing: border-box;
  border: 3px solid #000;

  & svg {
    width: 100%;
    height: 100%;
  }
`;

const Outer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
`;
