import styled from 'styled-components';
import { NoticeItemResponse } from 'dto/NoticeDto';
import DateUtils from 'utils/DateUtils';
import Colors from 'styles/color-system';

interface Props {
  data: NoticeItemResponse;
  onClick: (noticeId: number) => void;
}

function FirstCard({ data, onClick }: Props) {
  const createdDate = DateUtils.convertDateToMMDD(
    DateUtils.convertTimeToDate(data.created),
  );
  return (
    <Container>
      <Content onClick={() => onClick(data.id)}>
        <Title>
          [{createdDate}]&nbsp;{data.title}
        </Title>
      </Content>
    </Container>
  );
}

export default FirstCard;

const Container = styled.div`
  width: 100%;
  padding-inline: 12px;
  padding-block: 17px;
  box-sizing: border-box;
`;

const Content = styled.div`
  padding-inline: 19px;
  padding-block: 17px;
  border-radius: 20px;
  border: 1px solid ${Colors.Primary[500]};
  overflow: hidden;
  box-sizing: border-box;
  background: ${Colors.Gray[700]};
  cursor: pointer;
  user-select: none;
`;

const Title = styled.p`
  width: 100%;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${Colors.Gray[200]};
  font-size: 20px;
  font-weight: 400;
  line-height: 1.3em;
  letter-spacing: -0.02em;
`;
