import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { CartItemDto, CartListResponse } from 'dto/CartDto';
import { OrderService } from 'services/OrderService';
import { OrderDeliveryDto } from 'dto/OrderDto';
import { Context as OrderContext } from 'contexts/OrderContext';
import PATH from 'Path';
import HeaderNavBar from 'components/common/HeaderNavBar';
import CartListItem from 'components/cart/CartListItem';
import CartTotalInfo from 'components/cart/CartTotalInfo';
import BottomButton from 'components/common/BottomButton';
import { CommonResponse } from 'dto/CommonDto';
import { RESULT_CODE } from 'constants/ResultCode';
import ItemNotFound from 'components/common/ItemNotFound';
import useOverlay from 'hook/useOverlay';

function CartPage() {
  const navigate = useNavigate();
  const { showToast } = useOverlay();

  const { modifyOrder, modifyDelivery, initialize } = useContext(OrderContext);

  const [items, setItems] = useState<CartItemDto[]>([]);
  const [orderAmount, setOrderAmount] = useState<number>(0);
  // const [deliveryAmount, setDeliveryAmount] = useState<number>(0);
  // const [freeLimit, setFreeLimit] = useState<number>(99999999999);

  const [disableActionButton, setDisableActionButton] =
    useState<boolean>(false);

  useEffect(() => {
    initialize();

    OrderService.cart().then((response) => {
      const result = response.data as CartListResponse;
      result.items.map((item) => (item.checked = true));
      setItems(result.items);

      // setDeliveryAmount(result.delivery);
      // setFreeLimit(result.free_limit);
    });
  }, []);

  useEffect(() => {
    // CheckList init
    let count = 0;
    let amount = 0;
    for (const item of items) {
      if (item.checked) {
        count += item.count;
        amount += item.count * item.product.amount.amount;
      }
    }
    setDisableActionButton(count === 0);
    setOrderAmount(amount);
  }, [items]);

  const handleOrder = () => {
    const limitCountMap = new Map<number, number>();

    let selectedItems: CartItemDto[] = [];
    items.forEach((item, index) => {
      if (item.checked && item.count > 0) {
        selectedItems.push(items[index]);
        if (limitCountMap.has(item.product.id)) {
          limitCountMap.set(
            item.product.id,
            limitCountMap.get(item.product.id)! - item.count,
          );
        } else {
          limitCountMap.set(item.product.id, item.product.max - item.count);
        }
      }
    });

    // @ts-ignore
    for (let value of limitCountMap.values()) {
      if (value < 0) {
        showToast('최대 구매 제한을 초과 합니다.');
        return;
      }
    }

    OrderService.checkOrder({ items: selectedItems })
      .then((response) => {
        const result = response.data as OrderDeliveryDto;
        modifyDelivery(result);

        modifyOrder({
          items: selectedItems,
          delivery_amount: 0,
          order_amount: orderAmount,
          isolated_price: 0,
          free_limit: 0,
          agree_payment: false,
          agree_privacy: false,
          agree_third: false,
        });

        navigate(PATH.OrderRequest);
      })
      .catch((e) => {
        console.log(e);
        // showToast('실패 했습니다.');
      });
  };

  const onUpdateItem = useCallback(
    (index: number, item: CartItemDto) => {
      const newItems = [...items];
      newItems[index] = item;

      setItems(newItems);
    },
    [items],
  );

  const onDeleteItem = useCallback(
    (index: number) => {
      console.log(index);
      const item = items[index];
      OrderService.deleteCart(item.id).then(({ data }) => {
        const result = data as CommonResponse;
        if (result.result === RESULT_CODE.SUCCESS) {
          const newItems = [...items];
          newItems.splice(index, 1);
          setItems(newItems);

          console.log(newItems);
        }
      });
    },
    [items],
  );

  const renderCartList = () => {
    if (items.length === 0) {
      return <ItemNotFound message="장바구니 상품이 없습니다." />;
    }

    return (
      <Fragment>
        <ListContainer>
          {items.map((item, index: number) => (
            <CartListItem
              key={item.id}
              index={index}
              item={item}
              onUpdate={onUpdateItem}
              onDelete={onDeleteItem}
            />
          ))}
        </ListContainer>
        {renderTotalInfo()}
      </Fragment>
    );
  };

  const renderTotalInfo = () => {
    let total = orderAmount;
    // let delivery = deliveryAmount;
    // if (orderAmount < freeLimit) {
    //   total += deliveryAmount;
    // } else {
    //   delivery = 0;
    // }

    return (
      <CartTotalInfo
        orderAmount={orderAmount}
        deliveryAmount={0}
        totalAmount={total}
      />
    );
  };

  return (
    <Container>
      <HeaderNavBar leftItem="BACK" label="장바구니" />
      <ContentBody>{renderCartList()}</ContentBody>
      <BottomButton
        label="주문하기"
        onClick={handleOrder}
        state={disableActionButton ? 'DISABLE' : 'DEFAULT'}
      />
    </Container>
  );
}

export default CartPage;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 100%;
`;

const ContentBody = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
`;

const ListContainer = styled.div`
  margin-top: 24px;
  flex: 1;
  padding-inline: 24px;
  box-sizing: border-box;
  overflow: hidden auto;
`;
