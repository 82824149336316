import React, { ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components';
import { nanoid } from 'nanoid';
import Checked from 'assets/icon/checked.svg';
import Colors from 'styles/color-system';

interface Props {
  children?: ReactNode;
  contact?: boolean;
  style?: string;
  value: boolean;
  onChange: (val: boolean) => void;
}

interface StyleProps {
  customStyle?: string;
}

function CheckBox({ children, contact, style, value, onChange }: Props) {
  const [contactID, setContactID] = useState('');

  useEffect(() => {
    if (contact) {
      setContactID(nanoid(10));
    } else {
      setContactID('');
    }
  }, [contact]);

  const CheckboxHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.checked);
  };

  return (
    <Container customStyle={style}>
      <CheckboxElm
        type="checkbox"
        id={contactID}
        checked={value !== undefined ? value : true}
        onChange={CheckboxHandler}
      />
      <label htmlFor={contactID}>{children}</label>
    </Container>
  );
}

export default CheckBox;

CheckBox.defaultProps = {
  contact: true,
};

const Container = styled.div<StyleProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
  ${({ customStyle }) => customStyle};

  &,
  & label {
    cursor: pointer;
    user-select: none;
  }
`;

const CheckboxElm = styled.input`
  all: unset;
  position: relative;
  display: block;
  width: 24px;
  height: 24px;
  border-radius: 6px;
  background: ${Colors.Background[500]};
  box-sizing: border-box;
  overflow: hidden;
  transition: background 150ms ease-in-out;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: ${`url(${Checked})`};
    opacity: 0.2;
    transition: opacity 150ms ease-in-out;
  }

  &:checked {
    background: ${Colors.Primary[500]};
  }

  &:checked::after {
    opacity: 1;
  }
`;
