import styled from 'styled-components';
import HeaderNavBar from 'components/common/HeaderNavBar';
import { ReactComponent as ErrorIcon } from 'assets/icon/error.svg';
import Colors from 'styles/color-system';

interface Props {
  message?: string;
}

const defaultMsg = '페이지를 찾을 수 없습니다 \n잠시 후 다시 이용해주세요';

function ErrorPage({ message = defaultMsg }: Props) {
  return (
    <Container>
      <HeaderNavBar leftItem="BACK" bgColor={Colors.Background[500]} btmLine />
      <Content>
        <ErrorIcon fill="#484850" stroke="#484850" />
        <Message>{message}</Message>
      </Content>
    </Container>
  );
}

export default ErrorPage;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: ${Colors.Background[500]};
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 36px;
  padding-inline: 24px;
  box-sizing: border-box;
`;

const Message = styled.span`
  white-space: pre-line;
  text-align: center;
  color: ${Colors.Gray[600]};
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.02em;
`;
