import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import PATH from 'Path';
import { useNavigate } from 'react-router-dom';
import HeaderNavBar from 'components/common/HeaderNavBar';
import { ReactComponent as Right } from 'assets/icon/chevron-right.svg';
import { ReactComponent as Profile } from 'assets/icon/profile.svg';
import { Context as AuthContext } from 'contexts/AuthContext';
import { UserService } from 'services/UserService';
import { MeResponse } from 'dto/AuthDto';
import useOverlay from 'hook/useOverlay';
import BasicPopup from 'components/overlays/BasicPopup';
import Colors from 'styles/color-system';
import { Utils } from '../../utils/Utils';

const defaultList: Menu[] = [
  {
    label: '굿즈 콜렉션',
    type: 'SIGN',
    path: PATH.Collections,
  },
  {
    label: '아티스트 변경',
    type: 'SIGN',
    path: PATH.ArtistChange,
  },
  {
    label: '주문내역',
    type: 'SIGN',
    path: PATH.Orders,
  },
  // {
  //   label: '비회원 주문내역',
  //   type: 'UNSIGNED',
  //   path: '',
  // },
  {
    label: '공지사항',
    type: 'ALL',
    path: PATH.Notices,
  },
  {
    label: '고객센터',
    type: 'ALL',
    path: PATH.CustomerService,
  },
  {
    label: '로그아웃',
    type: 'SIGN',
    path: PATH.SignIn,
  },
];

type Menu = {
  label: string;
  type: string;
  path: string;
};

interface StyledProps {
  last: boolean;
}

function SettingPage() {
  const navigate = useNavigate();
  const { showHandler, hideHandler } = useOverlay();

  const {
    state: { accessToken },
    signOut,
  } = useContext(AuthContext);
  const [name, setName] = useState<string>('');
  const [menuList, setMenuList] = useState<Menu[]>(defaultList);
  const [isSigned, setIsSigned] = useState<boolean>(false);

  const [inApp, setInApp] = useState<boolean>(false);

  useEffect(() => {
    const inApp = Utils.isInApp();
    setInApp(inApp);

    if (accessToken) {
      UserService.me().then(({ data }) => {
        const result = data as MeResponse;
        setName(result.name);
      });
    }
  }, []);

  useEffect(() => {
    setIsSigned(accessToken !== undefined && accessToken !== '');
  }, [accessToken]);

  const handleMenu = (label: string, path: string) => {
    if (label === '로그아웃') {
      const id = showHandler(
        <BasicPopup
          message="로그아웃 하시겠습니까?"
          onLeftClick={() => hideHandler(id)}
          onRightClick={() => {
            signOut();
            hideHandler(id);
            if (inApp) {
              navigate(path, { replace: true });
            }
          }}
        />,
        'POPUP',
      );
      return;
    }

    navigate(path, {
      state: {
        isBack: true,
      },
    });
  };

  const handleSignIn = () => {
    navigate(PATH.SignIn);
  };

  // const renderMenu = (type: string) => {
  //   (type === 'SIGN' && isSigned) || type === 'ALL';
  // };

  return (
    <Container>
      <HeaderNavBar label="내정보" leftItem="LOGO" rightItem="CART" />
      <UserProfile>
        <UserImage>
          <Profile />
        </UserImage>
        {isSigned ? (
          <UserName>{name} 님</UserName>
        ) : (
          <LoginAction onClick={handleSignIn}>
            로그인을 해주세요 <Right stroke="#fff" />
          </LoginAction>
        )}
      </UserProfile>
      {menuList
        .filter(
          (item) => (item.type === 'SIGN') === isSigned || item.type === 'ALL',
        )
        .map((item, index) => (
          <Menu
            key={item.label}
            onClick={() => handleMenu(item.label, item.path)}
            last={menuList.length === index + 1}
          >
            <Label>{item.label}</Label>
            <Icon>
              <Right stroke={Colors.Gray[600]} />
            </Icon>
          </Menu>
        ))}
    </Container>
  );
}

export default SettingPage;

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

const UserProfile = styled.div`
  display: flex;
  align-items: center;
  gap: 18px;
  padding-inline: 30px;
  padding-block: 21px;
  box-sizing: border-box;
  background: ${Colors.Gray[700]};
`;

const UserImage = styled.div`
  width: 64px;
  height: 64px;
  background: #25262c;
  border-radius: 50%;
`;

const UserName = styled.span`
  color: ${Colors.White[500]};
  font-size: 24px;
  font-weight: 500;
  line-height: normal;
`;

const LoginAction = styled.button`
  display: flex;
  align-items: center;
  color: ${Colors.White[500]};
  font-size: 24px;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
  user-select: none;

  & > svg {
    width: 35px;
    height: 35px;
    margin-bottom: 2px;
    margin-left: -2px;
  }
`;

const Menu = styled.div<StyledProps>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-block: 20px;
  box-sizing: border-box;
  cursor: pointer;
  user-select: none;

  &::after {
    content: '';
    position: absolute;
    left: 12px;
    bottom: 0;
    width: calc(100% - 24px);
    border-bottom: 1px solid ${Colors.Gray[700]};
    border-color: ${({ last }) => last && 'transparent'};
  }
`;

const Label = styled.span`
  color: ${Colors.Gray[100]};
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
  margin-left: 26px;
`;

const Icon = styled.div`
  width: 24px;
  height: 24px;
  margin-right: 23px;
`;
