import React from 'react';
import styled from 'styled-components';
import { Utils } from 'utils/Utils';
import Colors from 'styles/color-system';

interface Props {
  name: string;
  phone: string;
  address0: string;
  address1: string;
  memo: string;
}

function OrderDeliveryInfoCard({
  name,
  phone,
  address0,
  address1,
  memo,
}: Props) {
  return (
    <Container>
      <Title>배송정보</Title>
      <Card>
        <UserInfo>
          <span>{name}</span>
          <Line />
          <span>{Utils.convertPhoneAddedHyphen(phone)}</span>
        </UserInfo>
        <AddressInfo>
          {address0}
          &nbsp;
          {address1}
        </AddressInfo>
        <Memo>
          <MemoLabel>배송요청사항</MemoLabel>
          <MemoContent>
            {memo !== '' ? memo : '요청 사항이 없습니다'}
          </MemoContent>
        </Memo>
      </Card>
    </Container>
  );
}

export default OrderDeliveryInfoCard;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 30px;
`;

const Title = styled.h3`
  color: ${Colors.Gray[200]};
  font-size: 18px;
  font-weight: 700;
  line-height: 1.222em;
  letter-spacing: -0.02em;
`;

const Card = styled.div`
  border-radius: 6px;
  background: ${Colors.Gray[700]};
`;

const UserInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 7px;
  color: ${Colors.Gray[100]};
  font-size: 18px;
  font-weight: 500;
  line-height: normal;
  padding-inline: 18px;
  margin-top: 15px;
  margin-bottom: 11px;
  box-sizing: border-box;
`;

const Line = styled.span`
  width: 1px;
  height: 14px;
  background: ${Colors.Gray[200]};
  opacity: 0.3;
`;

const AddressInfo = styled.div`
  color: ${Colors.Gray[200]};
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  padding-inline: 18px;
  padding-bottom: 16px;
  border-bottom: 1px solid ${Colors.Gray[600]};
  box-sizing: border-box;
`;

const Memo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-block: 18px;
  padding-inline: 18px;
  box-sizing: border-box;
`;

const MemoLabel = styled.span`
  color: ${Colors.Gray[300]};
  font-size: 15px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.02em;
`;

const MemoContent = styled.span`
  color: ${Colors.Gray[100]};
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
`;
