import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { CartItemDto } from 'dto/CartDto';
import { Utils } from 'utils/Utils';
import CheckBox from 'components/common/CheckBox';
import Counter from 'components/common/Counter';
import Colors from 'styles/color-system';

interface Props {
  index: number;
  item: CartItemDto;
  onUpdate: (index: number, item: CartItemDto) => void;
  onDelete: (index: number) => void;
}

interface StyledProps {
  dim: boolean;
}

function CartListItem({ index, item, onUpdate, onDelete }: Props) {
  const [soldOut, setSoldOut] = useState(false);

  const handleCheck = (value: boolean) => {
    if (soldOut) return;
    let tempItem = { ...item };
    tempItem.checked = value;
    onUpdate(index, tempItem);
  };

  useEffect(() => {
    if (item.product.end * 1000 < new Date().getTime()) {
      setSoldOut(true);
    }
  }, []);

  useEffect(() => {
    if (soldOut) {
      let tempItem = { ...item };
      tempItem.checked = false;
      onUpdate(index, tempItem);
    }
  }, [soldOut]);

  return (
    <CartItem key={item.id} dim={soldOut}>
      <TopSection>
        <CheckBox
          value={soldOut ? false : item.checked!}
          onChange={handleCheck}
        />
        <Cancel type="button" onClick={() => onDelete(index)}>
          삭제
        </Cancel>
      </TopSection>
      <MiddleSection>
        <ProductName dim={soldOut}>{item.product.title}</ProductName>
        {item.option ? (
          <OptionName dim={soldOut}>{item.option.title}</OptionName>
        ) : (
          <></>
        )}
      </MiddleSection>
      <BottomSection>
        <Counter
          value={item.count}
          onChange={(value) => {
            let tempItem = { ...item };
            tempItem.count = value;
            onUpdate(index, tempItem);
          }}
        />
        <Cost dim={soldOut}>
          {Utils.convertCurrency(item.product.amount.amount)}원
        </Cost>
      </BottomSection>
    </CartItem>
  );
}

export default CartListItem;

const CartItem = styled.div<StyledProps>`
  position: relative;
  width: 100%;
  height: fit-content;
  min-height: fit-content;
  border-radius: 6px;
  padding: 14px 18px 18px;
  box-sizing: border-box;
  overflow: hidden;
  background: ${Colors.Gray[700]};
  margin-bottom: 10px;

  &::after {
    content: '';
    display: ${({ dim }) => (dim ? 'block' : 'none')};
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${Colors.Gray[700]};
    opacity: 0.6;
  }
`;

const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`;

const Cancel = styled.button`
  display: flex;
  color: #6a6a6a;
  text-align: right;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.02em;
  cursor: pointer;
  user-select: none;
  z-index: 10;
`;

const MiddleSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-bottom: 14px;
  border-bottom: 1px solid ${Colors.Gray[600]};
  box-sizing: border-box;
`;

const ProductName = styled.span<StyledProps>`
  width: 100%;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${Colors.Gray[100]};
  font-size: 18px;
  font-weight: 500;
  line-height: normal;
  opacity: ${({ dim }) => (dim ? '0.3' : '1')};
`;

const OptionName = styled.span<StyledProps>`
  display: block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${Colors.Gray[200]};
  font-size: 15px;
  font-weight: 400;
  line-height: normal;
  opacity: ${({ dim }) => (dim ? '0.3' : '1')};
`;

const BottomSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
`;

const Cost = styled.span<StyledProps>`
  color: ${Colors.White[500]};
  text-align: right;
  font-size: 20px;
  font-weight: 700;
  line-height: normal;
  opacity: ${({ dim }) => (dim ? '0.3' : '1')};
`;
