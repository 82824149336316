import styled, { css } from 'styled-components';
import Colors from 'styles/color-system';

interface Props {
  label: string;
  sort: 'PRIMARY' | 'GRAY';
  state: 'DEFAULT' | 'DISABLE';
  type: 'SUBMIT' | 'BUTTON';
  size: number;
  onClick: () => void;
}

interface StyledProps {
  sort: 'PRIMARY' | 'GRAY';
  size: number;
}

function BottomButton({ label, sort, state, type, size, onClick }: Props) {
  const buttonType = type === 'SUBMIT' ? 'submit' : 'button';
  return (
    <Container
      onClick={onClick}
      sort={sort}
      size={size}
      type={buttonType}
      disabled={state === 'DISABLE'}
    >
      {label}
    </Container>
  );
}

export default BottomButton;

BottomButton.defaultProps = {
  sort: 'PRIMARY',
  state: 'DEFAULT',
  type: 'BUTTON',
  size: 100,
};

const Container = styled.button<StyledProps>`
  position: relative;
  width: ${({ size }) => `${size}%`};
  height: 58px;
  z-index: 100;

  font-size: 24px;
  font-weight: 500;
  line-height: normal;

  cursor: pointer;
  user-select: none;
  transition: background 150ms ease-in-out, color 100ms ease-in-out;

  ${({ sort }) =>
    sort === 'PRIMARY' &&
    css`
      color: ${Colors.White[500]};
      background: ${Colors.Primary[500]};

      &:disabled {
        color: #888594;
        background: #39344c;
      }
    `};

  ${({ sort }) =>
    sort === 'GRAY' &&
    css`
      color: ${Colors.White[500]};
      background: ${Colors.Gray[600]};
    `};
`;
