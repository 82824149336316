export enum ORDER_STATUS {
  PAYMENT_READY = 'PAYMENT_READY', // 결제 대기
  ACCOUNT_READY = 'ACCOUNT_READY', // 입금 대기
  ACCOUNT_EXPIRED = 'ACCOUNT_EXPIRED', // 입금 만료
  PAYMENT_COMPLETE = 'PAYMENT_COMPLETE', // 결제 완료
  DELIVERY_READY = 'DELIVERY_READY', // 배송 준비
  DELIVERY_ING = 'DELIVERY_ING', // 배송 중
  DELIVERY_COMPLETE = 'DELIVERY_COMPLETE', // 배송 완료
  ORDER_COMPLETE = 'ORDER_COMPLETE', // 주문 완료
  CANCEL_REQUEST = 'CANCEL_REQUEST', // 주문 취소 요청
  CANCEL_COMPLETE = 'CANCEL_COMPLETE', // 취소 완료
}

export enum ORDER_STATUS_LABELS {
  PAYMENT_READY = '결제 대기',
  ACCOUNT_READY = '입금 대기',
  ACCOUNT_EXPIRED = '입금 만료',
  PAYMENT_COMPLETE = '결제 완료',
  DELIVERY_READY = '배송 준비',
  DELIVERY_ING = '배송 중',
  DELIVERY_COMPLETE = '배송 완료',
  ORDER_COMPLETE = '주문 완료',
  CANCEL_REQUEST = '취소 요청',
  CANCEL_COMPLETE = '취소 완료',
}
